import { Component, OnInit, ViewChild } from '@angular/core';

import { Table } from 'primeng/table';
import { IClub, ISerie } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { DbService } from '../../../services/db.service';

@Component({
  selector: 'app-series-info',
  templateUrl: './series-info.component.html',
  styleUrls: ['./series-info.component.css']
})
export class SeriesInfoComponent implements OnInit {

  @ViewChild('dt') table: Table;


  constructor(private dbService: DbService, public activeUserService: ActiveuserService, ) { }

  public clubs: IClub[];
  public broadCastClubs: any;
  public series: ISerie[];
  public broadCastSeries: any;


  ngOnInit(): void {
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(
      data => this.clubs = data)
    this.broadCastSeries = this.dbService.broadCastSeries.subscribe(
      data => {
        if (this.activeUserService.activeUserRole() != 'SAdmin') {
          this.series = data.filter(data => data.clubModelId == this.activeUserService.user.clubModelId);
        }
        else
          this.series = data;

        //data.forEach(serie => {
        //  var tempSerie: ITempSerie = {
        //    id: serie.seriesId,
        //    serieName: serie.serieName,
        //    clubModelId: serie.clubModelId,
        //    clubModel : serie.clubModel,
        //  }
        //  this.tempSeries.push(tempSerie)
        //})
      })

    this.dbService.getClubs();
    this.dbService.getSeries();
  }

  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
    this.broadCastSeries.unsubscribe();
  }

  onRowEditInit() {

  };

  onAddSerie() {
    var newSerie: ISerie = {
      id: 0,
      serieName: "",
      clubModelId: 0,
      eventsToCount:0,
      clubModel: { clubModelId: 0, name: "", address: "", pictureUrl: "", picture: null, rfidReaders: null, laps: null, autoStartActive:false },
      eventModel: null,
    }
    this.series.push(newSerie);


    //var newTempSerie: ITempSerie = {
    //  id: 0,
    //  serieName: "",
    //  clubModelId: 0,
    //  clubModel: {clubModelId: 0, name:"", address:"", picture:null, pictureUrl:"", rfidReaders:null, laps:null}
    //}
    //this.tempSeries.push(newTempSerie)
    this.table.initRowEdit(newSerie)
  }

  onRowEditSave(serie: ISerie) {

    serie.clubModelId = serie.clubModel.clubModelId;

    var addSerie: ISerie = {
      id: serie.id,
      serieName: serie.serieName,
      clubModelId: serie.clubModelId,
      eventsToCount: serie.eventsToCount,
      clubModel: null,
      eventModel: null,
    }

    if (addSerie.id == 0)
      this.dbService.addSerie(addSerie);
    else
      this.dbService.updateSerie(addSerie);

  };

  onRowEditCancel() {

  };

  onDeleteClubRFID(serie: ISerie) {
    this.dbService.removeSerie(serie)
  };

}
