import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';



@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css']
})



export class InfoCardComponent implements OnInit {

  @Input() messageString: string;
  @Input() clubModelId;
  @Input() date;
  @Input() svemoId;
  @Input() messageId;


  @Output() onDelete = new EventEmitter<number>();

  public picture: any;

  constructor(public dataRoom: DataRoomService,
    public activeUserService: ActiveuserService,) { }

  ngOnInit(): void {
    
  }

  onDeleteMessage(messageId: number) {
   // console.log(messageId)
   // this.dbService.removeMessage(messageId)
    this.onDelete.emit(messageId);
  }
}
