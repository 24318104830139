<mat-card fxLayoutalign="start center" style="margin-top:0.5rem; padding:0">
  <!--HEADER with dropdowns-->
  <div style="height:3rem" fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
    <h3 style="padding-left:2rem; font-family:Roboto; font-weight:500">Välj tävling:</h3>
    <mat-form-field appearance="fill">
      <mat-label>Välj klubb:</mat-label>
      <mat-select [(value)]="selectedClub"
                  [compareWith]="comparer"
                  style="padding:0;margin:0"
                  (selectionChange)="onClubChange($event)">
        <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="selectedClub" appearance="fill">
      <mat-label>Välj event:</mat-label>
      <mat-select [(value)]="selectedEvent"
                  (selectionChange)="onEventChange($event)">

        <mat-option *ngFor="let event of allEvents" [value]="event">{{event.eventName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--MAT TAB REGION-->
  <mat-tab-group style="background-color:whitesmoke; margin-top:1rem">
    <!--MAT TAB ALLA KLASSER-->
    <mat-tab label="Alla klasser">
      <p-table [value]="displayMembers" dataKey="id" sortMode="multiple"
               [scrollable]="true" scrollHeight="500px">
        <!--HEADER-->
        <ng-template pTemplate="header">
          <div fxLayoutAlign="start center" class="mw_main_table_header">
            <th fxHide.lt-sm style="width: 1.5rem"></th>
            <th fxFlex="2.5rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
            <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
            <th fxFlex="5rem" fxLayoutAlign="start center" pSortableColumn="className">Klass<p-sortIcon field="className"></p-sortIcon></th>
            <th fxFlex="3rem" fxLayoutAlign="center center">Status</th>
            <th fxFlex="5rem" *ngFor="let header of headers" style="font-size:0.8rem; font-weight:500" fxLayoutAlign="center center">
              <span fxLayoutAlign="center center">{{header.header}}</span>
            </th>
            <th fxFlex></th>
            <th fxFlex="4rem" fxLayoutAlign="center center" style="font-size:0.9rem" pSortableColumn="nbrLaps">Varv<p-sortIcon field="nbrLaps"></p-sortIcon></th>
            <th fxFlex="4rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="totalTime">Tid<p-sortIcon field="totalTime"></p-sortIcon></th>
          </div>
        </ng-template>
        <!--BODY-->
        <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
          <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
            <td fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <button class="toggleBtn" fxLayout fxLayoutAlign="center center"
                      [pRowToggler]="displayMember">
                <mat-icon *ngIf="!expanded">keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
              </button>
            </td>
            <td fxFlex="2.5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center"><b>{{displayMember.startNbr}}</b></span>
            </td>
            <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
            </td>
            <td fxFlex="5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center">{{displayMember.className}}</span>
            </td>
            <td fxFlex="3rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
              <mat-icon style="padding: 0rem; margin:0px; font-size:0.8rem" *ngIf="displayMember.confirmedTimeReadModel" fxLayoutAlign="center center"
                        [ngStyle]="{'color':getStatusColor(displayMember)}">circle</mat-icon>
            </td>
            <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
              <div fxFlexLayout="row">
                <span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{displayLapName(time.lapID)}} </span>
                <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{convertToMiniTime(time.lapTime)}} </span>
              </div>
            </td>
            <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
            <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
              <span fxLayoutAlign="start center" style="font-size:0.7rem">{{displayMember.nbrLaps}}</span>
            </td>
            <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="start center">
              <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{displayMember.totalTime}} </span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-member>
          <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
            <!--<p-table #dt fxFlex="60" [value]="member.lapTimeModel" dataKey="timeReadID" editMode="row"
                     [scrollable]="true" scrollHeight="300px">
              <ng-template pTemplate="header">
                <div fxLayout="column" fxFlex="70" class="mw_sub_table_header">
                  <div fxLayoutAlign="start center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                    <span style="margin-left:2rem"><u><b>Varv tider</b></u></span>
                  </div>
                  <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem; font-size:0.7rem">
                    <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                    <th fxFlex="6rem" fxLayoutAlign="center center">Tid</th>
                    <th fxFlex="8rem" fxLayoutAlign="center center">Namn</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                    <th fxFlex></th>
                  </div>
                </div>
              </ng-template>

              <ng-template pTemplate="body" let-lapTimeModel let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="lapTimeModel" fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:2rem">
                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{lapTimeModel.timeReadID}}</span>
                  </td>
                  <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="lapTimeModel.lapTime" style="font-size:0.8rem; width:5rem; height:1.2rem">
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span fxLayoutAlign="center center" style="font-size:0.8rem"> {{convertToMiniTime(lapTimeModel.lapTime)}} </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td fxFlex="8rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <select [(ngModel)]="lapTimeModel.lapID" style="width:6rem; height:1.2rem; font-size:0.8rem;">
                          <option *ngFor="let lap of selectedEventLaps" value="{{lap.lapId}}">{{lap.lapName}}</option>
                        </select>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span fxLayoutAlign="center center" style="font-size:0.8rem">{{displayLapName(lapTimeModel.lapID)}} </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="number" [(ngModel)]="lapTimeModel.lapNbr" style="font-size:0.8rem; width:2rem; height:1.2rem; align-content:center">
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span style="font-size:0.8rem">{{lapTimeModel.lapNbr}}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem">
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                    <button *ngIf="!editing" class="mw-editBtn-icon_sm" pInitEditableRow (click)="onRowEditInit(lapTimeModel)" matTooltip="Ändra varv">
                      <mat-icon>edit_outline</mat-icon>
                    </button>
                    <button *ngIf="!editing" class="mw-cancelBtn-icon_sm" pInitEditableRow (click)="onRowEditDelete(lapTimeModel, member)" matTooltip="Ta bort varv">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button *ngIf="editing" class="mw-doneBtn-icon_sm" pSaveEditableRow (click)="onRowEditSave(lapTimeModel, member)" matTooltip="Genomför ändring">
                      <mat-icon>done</mat-icon>
                    </button>
                    <button *ngIf="editing" class="mw-cancelBtn-icon_sm" pCancelEditableRow (click)="onRowEditCancel(member)" matTooltip="Ångra">
                      <mat-icon>undo</mat-icon>
                    </button>
                  </td>

                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>-->
                  <!--<td>
                <button (click)="onConsole(lapTimeModel)">Console</button>
              </td>-->
                <!--</tr>
              </ng-template>
            </p-table>-->
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
            <!--ConfirmedTimeReads TABLE-->
            <p-table fxFlex="60" [value]="member.confirmedTimeReadModel" dataKey="SvemoId"
                     [scrollable]="true" scrollHeight="200px">
              <ng-template pTemplate="header">
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                  <span><u><b>Tid stämplar</b></u></span>
                </div>
                <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                  <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                  <th fxFlex="5rem" fxLayoutAlign="center center">Tid</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Läsare</th>
                  <th fxFlex="3rem" fxLayoutAlign="center center">Ant</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Status</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                  <th fxFlex></th>
                </div>
              </ng-template>
              <ng-template pTemplate="body" let-confirmedTimeReadModel let-expanded="expanded">
                <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem"
                    [style.color]="confirmedTimeReadModel.correctRead ? 'black' : 'red' ">
                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.timeReadID}}</span>
                  </td>
                  <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.time | date:'HH:mm:ss'}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.readerId}}</span>
                  </td>
                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.antennaId}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.status}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.ladIdx}}</span>
                  </td>
                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                </tr>
              </ng-template>
            </p-table>
            <p-table fxFlex="40" [value]="member.intermediateTimeModel" dataKey="SvemoId"
                     [scrollable]="true" scrollHeight="200px">
              <ng-template pTemplate="header">
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem;font-size:1.1rem">
                  <span><u><b>Mellantider</b></u></span>
                </div>
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                  <th fxFlex="60" fxLayoutAlign="center center">Mellantid</th>
                  <th fxFlex="40" fxLayoutAlign="center center">Tid</th>
                  <th fxFlex="2.5rem" fxLayoutAlign="center center">ID1</th>
                  <th fxFlex="2.5rem" fxLayoutAlign="center center">ID2</th>
                  <th fxFlex></th>
                </div>
              </ng-template>
              <ng-template pTemplate="body" let-intermediateTimeModel let-expanded="expanded">
                <tr fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.7rem">
                  <td fxFlex="60" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{displayLapName(intermediateTimeModel.lapID)}}</span>
                  </td>
                  <td fxFlex="40" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{convertToMiniTime(intermediateTimeModel.lapTime)}}</span>
                  </td>
                  <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{intermediateTimeModel.id1}}</span>
                  </td>
                  <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{intermediateTimeModel.id2}}</span>
                  </td>
                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>
      </p-table>


    </mat-tab>
    <mat-tab *ngFor="let class of allClasses; let i = index;"
             label="{{class}}">
      <p-table [value]="dataRoom.filterDisplayMembers(displayMembers , class)" dataKey="id" sortMode="multiple"
               [scrollable]="true" scrollHeight="500px">
        <!--HEADER-->
        <ng-template pTemplate="header">
          <div fxLayoutAlign="start center" class="mw_main_table_header">
            <th fxHide.lt-sm style="width: 1.5rem"></th>
            <th fxFlex="2.5rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
            <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
            <th fxFlex="5rem" fxLayoutAlign="start center">Klass</th>
            <th fxFlex="3rem" fxLayoutAlign="center center">Status</th>
            <th fxFlex="5rem" *ngFor="let header of headers" style="font-size:0.8rem; font-weight:500" fxLayoutAlign="center center">
              <span fxLayoutAlign="center center">{{header.header}}</span>
            </th>
            <th fxFlex></th>
            <th fxFlex="4rem" fxLayoutAlign="center center" style="font-size:0.9rem" pSortableColumn="nbrLaps">Varv<p-sortIcon field="nbrLaps"></p-sortIcon></th>
            <th fxFlex="4rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="totalTime">Tid<p-sortIcon field="totalTime"></p-sortIcon></th>
          </div>
        </ng-template>
        <!--BODY-->
        <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
          <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
            <td fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <button class="toggleBtn" fxLayout fxLayoutAlign="center center"
                      [pRowToggler]="displayMember">
                <mat-icon *ngIf="!expanded">keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
              </button>
            </td>
            <td fxFlex="2.5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center"><b>{{displayMember.startNbr}}</b></span>
            </td>
            <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
            </td>
            <td fxFlex="5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              <span fxLayoutAlign="start center">{{displayMember.className}}</span>
            </td>
            <td fxFlex="3rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
              <mat-icon style="padding: 0rem; margin:0px; font-size:0.8rem" *ngIf="displayMember.confirmedTimeReadModel" fxLayoutAlign="center center"
                        [ngStyle]="{'color':getStatusColor(displayMember)}">circle</mat-icon>
            </td>
            <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
              <div fxFlexLayout="row">
                <span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{displayLapName(time.lapID)}} </span>
                <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{convertToMiniTime(time.lapTime)}} </span>
              </div>
            </td>
            <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
            <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
              <span fxLayoutAlign="start center" style="font-size:0.7rem">{{displayMember.nbrLaps}}</span>
            </td>
            <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="start center">
              <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{displayMember.totalTime}} </span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-member>
          <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
            <!--<p-table #dt fxFlex="60" [value]="member.lapTimeModel" dataKey="timeReadID" editMode="row"
                 [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <div fxLayout="column" fxFlex="70" class="mw_sub_table_header">
              <div fxLayoutAlign="start center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                <span style="margin-left:2rem"><u><b>Varv tider</b></u></span>
              </div>
              <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem; font-size:0.7rem">
                <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                <th fxFlex="6rem" fxLayoutAlign="center center">Tid</th>
                <th fxFlex="8rem" fxLayoutAlign="center center">Namn</th>
                <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                <th fxFlex></th>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="body" let-lapTimeModel let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="lapTimeModel" fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:2rem">
              <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{lapTimeModel.timeReadID}}</span>
              </td>
              <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="lapTimeModel.lapTime" style="font-size:0.8rem; width:5rem; height:1.2rem">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span fxLayoutAlign="center center" style="font-size:0.8rem"> {{convertToMiniTime(lapTimeModel.lapTime)}} </span>
                  </ng-template>
                </p-cellEditor>
              </td>

              <td fxFlex="8rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <select [(ngModel)]="lapTimeModel.lapID" style="width:6rem; height:1.2rem; font-size:0.8rem;">
                      <option *ngFor="let lap of selectedEventLaps" value="{{lap.lapId}}">{{lap.lapName}}</option>
                    </select>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span fxLayoutAlign="center center" style="font-size:0.8rem">{{displayLapName(lapTimeModel.lapID)}} </span>
                  </ng-template>
                </p-cellEditor>
              </td>

              <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="number" [(ngModel)]="lapTimeModel.lapNbr" style="font-size:0.8rem; width:2rem; height:1.2rem; align-content:center">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span style="font-size:0.8rem">{{lapTimeModel.lapNbr}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>

              <td fxFlex style="padding: 0px; margin:0px; height:1.5rem">
              </td>
              <td fxFlex="4rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <button *ngIf="!editing" class="mw-editBtn-icon_sm" pInitEditableRow (click)="onRowEditInit(lapTimeModel)" matTooltip="Ändra varv">
                  <mat-icon>edit_outline</mat-icon>
                </button>
                <button *ngIf="!editing" class="mw-cancelBtn-icon_sm" pInitEditableRow (click)="onRowEditDelete(lapTimeModel, member)" matTooltip="Ta bort varv">
                  <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="editing" class="mw-doneBtn-icon_sm" pSaveEditableRow (click)="onRowEditSave(lapTimeModel, member)" matTooltip="Genomför ändring">
                  <mat-icon>done</mat-icon>
                </button>
                <button *ngIf="editing" class="mw-cancelBtn-icon_sm" pCancelEditableRow (click)="onRowEditCancel(member)" matTooltip="Ångra">
                  <mat-icon>undo</mat-icon>
                </button>
              </td>

              <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>-->
            <!--<td>
          <button (click)="onConsole(lapTimeModel)">Console</button>
        </td>-->
            <!--</tr>
          </ng-template>
        </p-table>-->
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
            <!--ConfirmedTimeReads TABLE-->
            <p-table fxFlex="60" [value]="member.confirmedTimeReadModel" dataKey="SvemoId"
                     [scrollable]="true" scrollHeight="200px">
              <ng-template pTemplate="header">
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                  <span><u><b>Tid stämplar</b></u></span>
                </div>
                <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                  <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                  <th fxFlex="5rem" fxLayoutAlign="center center">Tid</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Läsare</th>
                  <th fxFlex="3rem" fxLayoutAlign="center center">Ant</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Status</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                  <th fxFlex></th>
                </div>
              </ng-template>
              <ng-template pTemplate="body" let-confirmedTimeReadModel let-expanded="expanded">
                <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem"
                    [style.color]="confirmedTimeReadModel.correctRead ? 'black' : 'red' ">
                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.timeReadID}}</span>
                  </td>
                  <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.time | date:'HH:mm:ss'}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.readerId}}</span>
                  </td>
                  <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.antennaId}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.status}}</span>
                  </td>
                  <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{confirmedTimeReadModel.ladIdx}}</span>
                  </td>
                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                </tr>
              </ng-template>
            </p-table>
            <p-table fxFlex="40" [value]="member.intermediateTimeModel" dataKey="SvemoId"
                     [scrollable]="true" scrollHeight="200px">
              <ng-template pTemplate="header">
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem;font-size:1.1rem">
                  <span><u><b>Mellantider</b></u></span>
                </div>
                <div fxLayoutAlign="center center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                  <th fxFlex="60" fxLayoutAlign="center center">Mellantid</th>
                  <th fxFlex="40" fxLayoutAlign="center center">Tid</th>
                  <th fxFlex="2.5rem" fxLayoutAlign="center center">ID1</th>
                  <th fxFlex="2.5rem" fxLayoutAlign="center center">ID2</th>
                  <th fxFlex></th>
                </div>
              </ng-template>
              <ng-template pTemplate="body" let-intermediateTimeModel let-expanded="expanded">
                <tr fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.7rem">
                  <td fxFlex="60" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{displayLapName(intermediateTimeModel.lapID)}}</span>
                  </td>
                  <td fxFlex="40" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{convertToMiniTime(intermediateTimeModel.lapTime)}}</span>
                  </td>
                  <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{intermediateTimeModel.id1}}</span>
                  </td>
                  <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                    <span style="font-size:0.8rem">{{intermediateTimeModel.id2}}</span>
                  </td>
                  <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>
      </p-table>
    </mat-tab>

  </mat-tab-group>
</mat-card>
