<p-table #dt [value]="mcBrands" dataKey="id" editMode="row">
  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem">
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <div class="name-header-sm test">
          Tillverkare
        </div>
      </div>
      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mw-addBtn-icon" (click)="onAddBikeBrand()" fxLayoutAlign="start start" matTooltip="Lägg till motorcykeltillverkare">
          <mat-icon class="mw-addIcon-icon">add</mat-icon>
        </button>
      </div>

    </div>

  </ng-template>

  <ng-template pTemplate="header">
    <tr fxFlexLayout="row" fxLayoutAlign="start center">
      <th fxFlex="20%" pSortableColumn="bikeBrand">Märke <p-sortIcon field="bikeBrand"></p-sortIcon></th>
      <th fxFlex="20%" pSortableColumn="bikeBrand">Logo <p-sortIcon field="bikeBrand"></p-sortIcon></th>
      <th fxFlex="40%" pSortableColumn="bikeBrand">Url <p-sortIcon field="bikeBrand"></p-sortIcon></th>
      <th fxFlex fxLayoutAlign="center center" >Ändra/Radera</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-mcBrand let-editing="editing" let-ri="rowIndex">
    <tr fxLayout="row" style="height:3rem" [pEditableRow]="mcBrand">
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="mcBrand.bikeBrand">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{mcBrand.bikeBrand}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <ngx-mat-file-input [accept]="accept" placeholder="Välj en fil" [(ngModel)]="selectedPic">
                <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            <img class="img" width="40" height="40" [src]='dataRoom.showImage(mcBrand.picture)' style="border-radius:10%;" />
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="40%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="mcBrand.companyUrl">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{mcBrand.companyUrl}}
          </ng-template>
        </p-cellEditor>
      </td>


      <td fxFlex fxLayoutAlign="center center">
        <div fxFlex fxLayoutAlign="center center">
          <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow (click)="onRowEditInit()" matTooltip="Redigera tillverkar info">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="!editing" class="mw-deleteBtn-icon" (click)="onDeletemcBrand(mcBrand)" matTooltip="Ta bort tillverkare">
            <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-okBtn-icon" pInitEditableRow pSaveEditableRow (click)="onRowEditSave(mcBrand)" matTooltip="Genomför">
            <mat-icon class="mw-okIcon-icon">done</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-deleteBtn-icon" pCancelEditableRow (click)="onRowEditCancel()" matTooltip="Ångra">
            <mat-icon class="mw-deleteIcon-icon">undo</mat-icon>
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
