<mat-card fxLayout="column" fxLayoutGap="2rem" style="margin-top:1rem; padding:1rem">

  <p-table #dt [value]="clubs" dataKey="clubModelId"
           [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Totalt {totalRecords} event i databasen" [rowsPerPageOptions]="[10,25,50]"
           sortMode="multiple"
           [scrollable]="true" scrollHeight="500px"
           [responsive]="true"
           [filterDelay]="0"
           editMode="row">
    <!--[globalFilterFields]="['svemoId','firstName','lastName']"-->

    <ng-template pTemplate="caption">
      <div fxLayout="row wrap" fxLayoutGap="1rem">
        <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
          <div class="name-header-sm">
            Status Läsare
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-club let-ri="rowIndex" let-expanded="expanded">
      <tr fxFlexLayout="row">
        <td fxFlex="11rem" fxLayout fxFlexLayout="column" fxLayoutAlign="center center">
          <mat-card fxLayout="column">
            <span style="margin:0.2rem" fxFlexLayout="row" fxLayoutAlign="center center"><b>{{club.name}}</b></span>
            <img fxFlexLayout="row" class="img" width="100" height="100" [src]='dataRoom.showClubPic(club.clubModelId)' style="border-radius:20%;" />
          </mat-card>
        </td>
        <td>
          <p-table [value]="club.rfidReaders">
            <ng-template pTemplate="caption">
              <div fxLayout="row wrap">
                Läsare
              </div>
            </ng-template>
            <ng-template pTemplate="body" let-reader let-ri="rowIndex">
              <tr fxFlexLayout="row" style="padding: 0px; margin:0px; height:2rem">
                <td fxFlex="9rem" style="padding: 0px; margin:0px; height:2rem">
                  <span style="height:1rem">{{reader.readerName}}</span>
                </td>
                <td style="padding: 0px; margin:0px; height:2rem">
                  <mat-icon [ngStyle]="{'color':getTheColor2(reader.readerName)}">circle</mat-icon>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    </ng-template>

</p-table>
</mat-card>
