<div>
  <h1 mat-dialog-title style="margin-left:1rem">{{data.title}}</h1>
  <div mat-dialog-content style="margin-left:0.5rem; margin-right:0.5rem">
    {{data.message}}
  </div>
  <div fxFlexLayout="row-wrap" fxLayoutAlign="center center" style="margin-left:0.5rem; margin-right:0.5rem">
    <button class="mr-2 PrimaryBtn" style="margin:10px" color="primary" mat-raised-button [mat-dialog-close]="true">Bekräfta</button>
    <button class="mr-2 PrimaryBtn" style="margin:10px" color="primary" mat-raised-button [mat-dialog-close]="false">Ångra</button>
  </div>
</div>
