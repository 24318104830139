<mat-nav-list>
  <a  fxLayout fxLayoutAlign="center center" style="background-color: dimgrey;">
    <h1 style=" margin-right:15px">{{activeUserService.user.firstName}}</h1>
    <img class="img"
         style="border-radius:50%; margin-right:10px"
         width="40"
         height="40"
         [src]='activeUserService.displayProfilePicture()'>
  </a>
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>

  <a *ngIf="activeUserService.activeUserRole() == 'Admin' || activeUserService.activeUserRole() == 'SAdmin'" mat-list-item [matMenuTriggerFor]="belowMenu"><mat-icon>how_to_reg</mat-icon><span class="nav-caption">Administrera</span></a>
  <mat-menu #belowMenu="matMenu" yPosition="above" xPosition="before" class="CustomMenu">
    <span class="headerStyle">Administrera DB</span>
    <button mat-menu-item routerLink="/pages/admin/members" (click)="onSidenavClose()">Hantera medlemmar</button>
    <button mat-menu-item routerLink="/pages/admin/dbInfo" (click)="onSidenavClose()">Lägg till DB info</button>
    <button mat-menu-item routerLink="/pages/admin/events" (click)="onSidenavClose()">Hantera Events</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Administrera träning</span>
    <button mat-menu-item routerLink="/pages/admin/startTraining" (click)="onSidenavClose()">Genomför träning</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Administrera tävling</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/prepareCompetition">Förbered tävling</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/runCompetition">Genomför tävling</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Kontrollera status</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/unitStatus">Status enheter</button>
  </mat-menu>

  <a mat-list-item [matMenuTriggerFor]="belowMenu2"><mat-icon>timer</mat-icon><span class="nav-caption">Tider</span></a>
  <mat-menu #belowMenu2="matMenu" yPosition="above" xPosition="before" class="CustomMenu">
    <span class="headerStyle">Träning</span>
    <button mat-menu-item class="CustomMenuItem" routerLink="/pages/results/liveTraining" (click)="onSidenavClose()">Live!</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/resultsTraining">Historik</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Tävling</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/liveCompetition">Live!</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/resultsCompetition">Historik</button>
  </mat-menu>

  <a mat-list-item [matMenuTriggerFor]="belowMenu3"><mat-icon>login</mat-icon> <span class="nav-caption">Länkar</span></a>
  <mat-menu #belowMenu3="matMenu" yPosition="above" xPosition="before" class="CustomMenu">
    <span class="headerStyle">Klubb länkar</span>
    <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se');">Wäxjö MS</button>
    <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se/mc');">Wäxjö MS Enduro</button>
    <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.oldWeb.wmstime.se');">Gamla tidtagningen</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Enduro länkar</span>
    <button mat-menu-item onclick="window.open('http://www.svemo.se/Sporter/Enduro/');">Svemo</button>
    <button mat-menu-item onclick="window.open('http://www.svemo.se/Service/SvemoTA/');">Svemo TA</button>
    <span class="headerStyle">Övrigt</span>
    <button mat-menu-item>Item 1</button>
  </mat-menu>



  <a *ngIf="activeUserService.user.firstName=='Gäst'" mat-list-item routerLink="/auth/register" fxHide.xs (click)="onSidenavClose()">
    <mat-icon>register</mat-icon><span class="nav-caption">Registrera</span>
  </a>
  <a *ngIf="activeUserService.user.firstName=='Gäst'" mat-list-item routerLink="/auth/login" (click)="onSidenavClose()">
    <mat-icon>login</mat-icon><span class="nav-caption">Logga in</span>
  </a>
  <a *ngIf="activeUserService.user.firstName!='Gäst'" mat-list-item (click)="onLogout()">
    <mat-icon>logout</mat-icon><span class="nav-caption">Logga ut</span>
  </a>

  <a [href]="mailto('support@wmstime.se', 'ISSUE:')" mat-list-item matTooltip="Skicka feedback">
    <mat-icon>mail_outline</mat-icon><span class="nav-caption">Skicka feedback</span>
  </a>


  <a (click)="about()" mat-list-item matTooltip="Skicka feedback">
    <mat-icon>info</mat-icon><span class="nav-caption">About</span>
  </a>

</mat-nav-list>
