<div fxLayoutAlign="start start" fxLayout="column" style="padding:1rem">

  <div fxLayout="row" style="width:100%">
    <div fxLayoutAlign="start start" fxFlex="15rem">
      <h3 *ngIf="!data.editReader" style="font-family:Roboto; font-weight:500;"> Lägg till läsare: </h3>
      <h3 *ngIf="data.editReader" style="font-family:Roboto; font-weight:500;"> Uppdatera läsare: </h3>
    </div>
    <div fxLayoutAlign="end start" fxFlex>
      <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
        <mat-icon class="mw-mat-close-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayoutAlign="start center" fxFlex="100">
    <form [formGroup]="addReaderForm" (ngSubmit)="onAddClick()" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field fxLayoutAlign="start center">
            <input matInput placeholder="Namn:" formControlName="readerName">
            <mat-error *ngIf="addReaderForm.controls['readerName'].hasError('required')">
              Läsarens namn <strong>måste anges</strong>
            </mat-error>
            <mat-error *ngIf="addReaderForm.controls['readerName'].hasError('minlength')">
              Läsarens namn <strong>måste</strong> ha minst 4 bokstäver
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem" style="padding-top:1rem">
    <mat-form-field fxFlex="4rem" *ngFor="let antennaT of antennaTranslate let i = index">
      <mat-label>Ext. {{antennaT.rfiDantenna}}:</mat-label>
      <input matInput type="number" [(ngModel)]="antennaTranslate[i].boxAntenna" />
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem" style="padding-top:1rem">
    <button class="mw-okBtn-icon" (click)="onAddClick()">
      <mat-icon class="mw-okIcon-icon">done</mat-icon>
    </button>
    <button class="mw-deleteBtn-icon" (click)="onCancelClick()">
      <mat-icon class="mw-deleteIcon-icon">undo</mat-icon>
    </button>
  </div>
</div>
