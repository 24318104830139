import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lap-info',
  templateUrl: './lap-info.component.html',
  styleUrls: ['./lap-info.component.css']
})
export class LapInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
