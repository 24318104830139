<div fxLayout="column">
  <mat-form-field fxLayout="row"  style="margin-left:3rem; margin-top:0.5rem" >
    <mat-label>Välj klubb:</mat-label>
    <mat-select [(ngModel)]="selectedClubs" multiple (selectionChange)="onClubChange($event.value)">
      <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <p-table #dt [value]="eventsWithMembers" dataKey="eventId"
           [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Totalt {totalRecords} träningar" [rowsPerPageOptions]="[10,25,50]"
           sortMode="multiple"
           [scrollable]="true" scrollHeight="500px"
           [filterDelay]="0" [globalFilterFields]="['eventId','eventName']"
           styleClass="p-datatable-members">
  <!--HEADER-->
  <ng-template pTemplate="header">
    <tr fxFlexLayout="row-wrap" style="height:4rem">
      <th fxFlex="5rem"></th>
      <th fxFlex="40px"></th>
      <th fxFlex="8rem" pSortableColumn="eventDate">Datum <p-sortIcon field="eventDate"></p-sortIcon></th>
      <th fxFlex="40%" pSortableColumn="eventName">Plats <p-sortIcon field="eventName"></p-sortIcon></th>
    </tr>
  </ng-template>
  <!--BODY-->
  <ng-template pTemplate="body" let-event let-ri="rowIndex" let-expanded="expanded">
    <tr fxFlexLayout="row" style="height:3rem">
      <td fxFlex="5rem" fxLayout fxLayoutAlign="start center">
        <button type="button" style="margin:0px"
                pButton pRipple [pRowToggler]="event"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="expandCommand(event, expanded)">
        </button>
      </td>
      <td fxFlex="40px" fxLayout fxLayoutAlign="center center">
        <img class="img" width="40" height="40" [src]='dataRoom.showClubPic(event.clubModelId)' style="border-radius:20%" />
      </td>
      <td fxFlex="8rem">{{event.eventDate}}</td>
      <td fxFlex>{{event.eventName}}</td>
    </tr>
  </ng-template>

  <!--EXPANSION 1-->
  <ng-template pTemplate="rowexpansion" let-event>
    <div style="background-color:white">
      <p-table #dtexpand [value]="event.members" dataKey="svemoId">
        <!--HEADER-->
        <ng-template pTemplate="body" let-member let-expanded="expanded">
          <div style="margin-bottom:0.25rem; margin-left:2rem">
            <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:3rem">
              <td fxFlex="5rem" fxLayout fxLayoutAlign="start center">

                <button type="button" style="margin:0px"
                        pButton pRipple [pRowToggler]="member"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                        (click)="expandMemberCommand(member, expanded)">
                </button>
              </td>

              <td fxFlex="15rem" style="padding: 0.5rem; margin:0px;" fxLayoutAlign="start center">
                <img class="img" width="50" height="40" [src]='dataRoom.showRacerPicture(member.svemoId)' style="border-radius:20%; margin-right:10px" />
                <span>{{member.firstName + ' ' + member.lastName}}</span>
              </td>
              <td *ngFor="let time of member.lapTimeModel" fxFlex="6rem" style="padding: 0.5rem; margin:0px;">
                <span fxLayoutAlign="center center" style="font-size:0.70rem; font-style:italic">{{displayLapName(time.lapID, event)}}</span>
                <span fxLayoutAlign="center center"> {{dataRoom.convertToMiniTime(time.lapTime)}} </span>
              </td>
              <td fxFlex>
              </td>
            </tr>
          </div>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-member>
          <mat-card class="MatCard-mw" style="border-radius:0.5rem">
            <div fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
              <h4 style="margin:0px; padding:0px">Tillgängliga tider:</h4>
            </div>        
            <p-table [value]="member.timeReadModel">
                <ng-template pTemplate="header">
                  <div fxLayoutAlign="start center" class="mw_main_table_header">
                    <th fxFlex="6rem" fxLayoutAlign="center center">Läsare</th>
                    <th fxFlex="5rem" fxLayoutAlign="center center">Antenn</th>
                    <th fxFlex="8rem" fxLayoutAlign="center center">Tidpunkt</th>
                  </div>
                </ng-template>

                <ng-template pTemplate="body" let-timeReadModel>
                  <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1,7rem">
                    <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{timeReadModel.readerId}}</span>
                    </td>
                    <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{timeReadModel.antennaId}}</span>
                    </td>
                    <td fxFlex="8rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{timeReadModel.time | date: 'HH:mm:ss'}}</span>
                    </td>
                  </tr>
                </ng-template>
            </p-table>
          </mat-card>
        </ng-template>
      </p-table>
    </div>
