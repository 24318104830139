import { Component, OnInit } from '@angular/core';
import { getType } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { IClub, IConfirmedTimeRead, IEvent, IIntermediateTimeModel, IMember, ISerie, IStartType } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.css']
})
export class DummyComponent implements OnInit {

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private signalR: SignalRService,
    private activeUserService: ActiveuserService,) { }

  public series: ISerie[] = []
  private broadCastSeries: any;

  public time = new Date();

  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClub: IClub;
  public allEvents: IEvent[] = [];
  public selectedEvent: IEvent;
  private broadCastallEvents: any;
  private startTypes: IStartType[] = [];

  public members: IMember[] = [];
  public lTimes: IIntermediateTimeModel[] = [];

  ngOnInit(): void {
    
    this.broadCastSeries = this.dbService.broadCastSeries.subscribe(data => {
      this.series = data;
    })
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })  
    this.broadCastallEvents = this.dbService.broadCastEvents.subscribe(data => {
      this.allEvents = data;
      if (this.selectedClub) {
        this.allEvents = data.filter(event => event.clubModelId && event.eventType.type == 'Tävling' && event.hasStarted < 3)
        if (this.selectedEvent) {
          this.selectedEvent.hasStarted = this.allEvents.find(data => data.eventId == this.selectedEvent.eventId).hasStarted;
        }
      }
      this.dbService.getMembersTimesEventDirect(this.allEvents[0]).subscribe(data => {
        if (data) {
          //Create headers for all laps
          console.log(data)
          this.members = data;

        }
      })
    })
    this.dbService.getStartTypes().subscribe(data => this.startTypes = data);


    this.signalR.getEventList();
    this.signalR.changedPiConnectionList();
  }
  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
    this.broadCastSeries.unsubscribe();

    //SIGNALR SERVICES
    this.signalR.stoplistenForEventData();
  }


  public time2: Date = new Date();

  toConsole() {
    this.time2 = new Date();

  }

  showTimeFromDate(): Date { 
    return this.time2;
  }
}
