<mat-toolbar class="navBarColor">
  <div fxHide.gt-xs>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon class="navBarColor">menu</mat-icon>
    </button>
  </div>

  <div fxFlex="20" fxLayout fxLayoutAlign="start">
    <div>
      <a fxHide.xs class="navbar-left" routerLink="/home" matTooltip="Gå till startsida" fxLayoutAlign="start center" style="margin:0px; padding:0;">
        <img height="60" src="/assets/img/test1.svg" />
      </a>
    </div>
    <!--<div fxLayout fxLayoutAlign="center center" class="club-text">-->
      <!--<a routerLink="/home" matTooltip="Gå till startsida">Wäxjö MS</a>-->
      <!--<a routerLink="/home" matTooltip="Gå till startsida">TrackTime</a>
    </div>-->
  </div>

  <div fxFlex fxLayout fxLayoutAlign="center">

    <div fxLayout fxHide.xs fxLayoutAlign="center center">
      <button class="mw-menu-btn" *ngIf="activeUserService.activeUserRole() == 'Admin' || activeUserService.activeUserRole() == 'SAdmin'" mat-button [matMenuTriggerFor]="belowMenu" matTooltip="Visa funktioner för admin">Admin</button>
      <mat-menu #belowMenu="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Administrera DB</span>
        <button mat-menu-item routerLink="/pages/admin/members" matTooltip="Gå till medlemslista">Hantera medlemmar </button>
        <button mat-menu-item routerLink="/pages/admin/dbInfo" matTooltip="Hantera information i databasen">Lägg till DB info</button>
        <button mat-menu-item routerLink="/pages/admin/events" matTooltip="Lägg till/Ta bort event">Hantera Events</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Administrera träning</span>
        <button mat-menu-item routerLink="/pages/admin/startTraining" matTooltip="Starta/Pausa/Stoppa träning">Genomför träning</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Administrera tävling</span>
        <button mat-menu-item routerLink="/pages/admin/prepareCompetition">Förbered tävling</button>
        <button mat-menu-item routerLink="/pages/admin/runCompetition">Genomför tävling</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Kontrollera status</span>
        <button mat-menu-item routerLink="/pages/admin/unitStatus">Status enheter</button>
        <button mat-menu-item routerLink="/components/dummy">Dummy</button>
      </mat-menu>

      <button class="mw-menu-btn" mat-button [matMenuTriggerFor]="belowMenu2" matTooltip="Välj hur du vill se tider"><mat-icon>timer</mat-icon> Tider</button>
      <mat-menu #belowMenu2="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Träning</span>
        <button mat-menu-item class="CustomMenuItem" routerLink="/pages/results/liveTraining">Live!</button>
        <button mat-menu-item routerLink="/pages/results/resultsTraining">Historik</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Tävling</span>
        <button mat-menu-item routerLink="/pages/results/liveCompetition">Live!</button>
        <button mat-menu-item routerLink="/pages/results/resultsCompetition">Historik</button>
      </mat-menu>
      <button fxHide.sm class="mw-menu-btn" mat-button [matMenuTriggerFor]="belowMenuLinks" matTooltip="Nyttiga länkar">Länkar</button>
      <mat-menu #belowMenuLinks="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Klubb länkar</span>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se');">Wäxjö MS</button>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se/mc');">Wäxjö MS Enduro</button>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.oldWeb.wmstime.se');">Gamla tidtagningen</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Enduro länkar</span>
        <button mat-menu-item onclick="window.open('http://www.svemo.se/Sporter/Enduro/');">Svemo</button>
        <button mat-menu-item onclick="window.open('http://www.svemo.se/Service/SvemoTA/');">Svemo TA</button>
        <span class="headerStyle">Övrigt</span>
        <button mat-menu-item>Item 1</button>
      </mat-menu>
    </div>
  </div>
  <div fxFlex="20" fxLayout fxLayoutAlign="end centee">
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">

      <li *ngIf="activeUserService.user.firstName=='Gäst'" fxHide.xs>
        <a fxLayoutAlign="center center" routerLink="/auth/register" fxHide.xs matTooltip="Registrera ny nvändare">Registrera</a>
      </li>
      <li *ngIf="activeUserService.user.firstName=='Gäst'" fxHide.xs>
        <a routerLink="/auth/login" matTooltip="Logga in">Login</a>
      </li>
      <li class="cursor-pointer" *ngIf="activeUserService.user.firstName!='Gäst'">
        <a (click)="onViewMember()" fxLayout fxLayoutAlign="center center" matTooltip="Gå till profil">
          <img class="img"
               style="border-radius:50%; margin-right:10px"
               width="40"
               height="40"
               [src]='activeUserService.displayProfilePicture()'>
          <span fxHide.xs>{{activeUserService.user.firstName}}</span>
        </a>
      </li>
      <li fxHide.xs class="cursor-pointer" *ngIf="activeUserService.user.firstName!='Gäst'" fxLayout fxLayoutAlign="center center">
        <a (click)="onLogout()" matTooltip="Logga ut">Logout</a>
      </li>

      <li class="cursor-pointer">
        <a [href]="mailto('support@wmstime.se', 'ISSUE:')" matTooltip="Skicka feedback">
          <mat-icon>mail_outline</mat-icon>
        </a>
      </li>
      <li class="cursor-pointer">
        <a (click)="about()" fxLayout fxLayoutAlign="center center" matTooltip="Versions info">
          <mat-icon>info</mat-icon>
        </a>
      </li>
    </ul>
  </div>
</mat-toolbar>
