import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileInput } from 'ngx-material-file-input';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { IBikeBrand, IMember } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { ConfigService } from '../../../services/config.service';
import { DataRoomService } from '../../../services/data-room.service';
import { DbService } from '../../../services/db.service';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.css']
})
export class MemberProfileComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private config: ConfigService,
    public activeUser: ActiveuserService,
    private dialog: MatDialog,
    private dbService: DbService,
   // private sanitizer: DomSanitizer,
    public dataRoom: DataRoomService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MemberProfileComponent>) {
  }

  

  // Image files to accept for upload
  public accept = '.jpg, .png'
  public multiple = false;

  public pictureUrl: FileInput;
  private updateUrlMember: IupdateUrlMember;

  public displayPic: any = [];
  public errorMsg: string;

  private broadCastProfilepic: any;

  public bikeBrands: IBikeBrand[] = this.dbService.allBikeBrands;

  public editProfile: boolean = false;

  ngOnInit(): void {
    this.broadCastProfilepic = this.dbService
      .getProfilePic(this.data.member.svemoId)
      .pipe(catchError(error => {
        console.log(error)
        return of([]);
      })
      ).subscribe(data => {
        this.displayPic = data.picture;
      });
  }
  ngOnDestroy(): void {
    this.broadCastProfilepic.unsubscribe();
  }
  onUpdatePicUrl() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.data.member.firstName,
        message: 'Är du säker på att du vill uppdatera din profil?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (!this.pictureUrl)
        this.snackBar.open('Varning!','Du måste välja en fil att ladda upp!',
          {
            duration: this.config.snackBarTiming,
            panelClass: "snackBarErr"
          })
      if (result === true && this.pictureUrl) {

        this.updateUrlMember = {
          svemoId: this.data.member.svemoId,
          PictureUrl: this.pictureUrl.files[0]
        }

        this.dbService.updateMemberProfileUrl(this.updateUrlMember)
        this.dialogRef.close();
      }
    });
  }
  showImage() {
    if (this.displayPic) {
      if (this.displayPic.length != 0) {
        return this.dataRoom.showImage(this.displayPic);
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'
  }

  public changeBike(bikeBrandId: number) {
    console.log(bikeBrandId)
  }

  public onEdit() {
    this.editProfile = !this.editProfile;
  }
  public onUpdate() {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.data.member.firstName,
        message: 'Är du säker på att du vill uppdatera din profil?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      console.log(this.data.member)
      if (result) {
        if (this.pictureUrl) {
          this.updateUrlMember = {
            svemoId: this.data.member.svemoId,
            PictureUrl: this.pictureUrl.files[0]
          }
          this.dbService.updateMemberProfileUrl(this.updateUrlMember)
        }
        var updateMember: IMember = {
          svemoId : this.data.member.svemoId,
          firstName: this.data.member.firstName,
          lastName: this.data.member.lastName,
          isActive: this.data.member.isActive,
          clubMembership: this.data.member.clubMembership,
          clubModel: null,
          clubModelId: this.data.member.clubModelId,
          bikeBrandModel: null,
          bikeBrandModelId: this.data.member.bikeBrandModelId,
          pictureUrl: this.data.member.pictureUrl,
          picture: this.data.member.picture,
          confirmedTimeReadModel: null,
          intermediateTimeModel: null,
          lapTimeModel: null,
          timeReadModel: null,
        }
        console.log(updateMember)
        this.dbService.updateMember(updateMember);
      }
      this.editProfile = !this.editProfile;
    })
    
  }
  public onCancel() {
    this.editProfile = !this.editProfile;
  }
}

export interface IupdateUrlMember {
  svemoId: number;
  PictureUrl: File;
}
