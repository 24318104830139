import { Component } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { ActiveuserService } from './services/activeuser.service';
import { DbService } from './services/db.service';
import { SignalRService } from './services/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TimeTracker';

  constructor(
    public authService: AuthenticationService,
    private activeUserService: ActiveuserService,
    private signalR: SignalRService,
    private dbService:DbService
  ) { }


  ngOnInit(): void {
    //Setting active user, if token exists uses existing if not set as guest!
    var checkToken = localStorage.getItem('token');
    if (checkToken) {
      var payLoad = JSON.parse(window.atob(checkToken.split('.')[1]));
      console.log('Token found setting user accordingly)')
      this.authService.getSvemoID(payLoad.UserId).subscribe(
        (res: any) => {
          this.activeUserService.changeUser(res)
        }
      )
    }
    else {
      console.log('Setting as Guest')
      this.activeUserService.activeAsGuest();
    }

    // Starta signaR connection to Server
    this.signalR.startConnection();
    this.dbService.getClubs();
    this.dbService.getMembers();
    this.dbService.getBikeBrands();
    this.dbService.getEvents();
    this.dbService.getSeries();
    this.dbService.getEventTypes();
  }
}
