import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMember } from '../../models/interfaceModels';
import { DbService } from '../../services/db.service';

export interface CreateMessage {
  user: IMember,
  type:string,
}

export interface DBMessage {
  messageId: number;
  clubModelId: number;
  svemoId: number;
  message: string;
  date: Date;
}

@Component({
  selector: 'app-message-input-dialog',
  templateUrl: './message-input-dialog.component.html',
  styleUrls: ['./message-input-dialog.component.css']
})
export class MessageInputDialogComponent implements OnInit {

  public message: string;

  constructor(public dialogRef: MatDialogRef<MessageInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CreateMessage,
    public dbService:DbService) { }

  ngOnInit(): void {
    
  }

  onAddMessage() {
    var myDate = new Date();
    if (this.data.type == 'clubMessage') {
      var newMessage: DBMessage = {
        messageId: 0,
        clubModelId: +this.data.user.clubModelId,
        svemoId: +this.data.user.svemoId,
        message: this.message,
        date: myDate ,
      }
    }
    else {
      var newMessage: DBMessage = {
        messageId: 0,
        clubModelId: -1,
        svemoId: this.data.user.svemoId,
        message: this.message,
        date: myDate,
      }
    }
    
    this.dbService.addMessage(newMessage).subscribe(data => {
      this.dialogRef.close(true);
    })
    
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
