import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/authentication.service';
import { IMember } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();

  public activeuser: IMember | undefined;
  public userRole: string = "Admin";
  public tokenExists: boolean = false;

  profilePic: any;

  constructor(
    public authService: AuthenticationService,
    public activeUserService: ActiveuserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private config:ConfigService
  ) { }

  ngOnInit(): void {
   
  }
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  onLogout() {
    this.activeUserService.activeAsGuest();
    localStorage.removeItem('token');
    this.router.navigate(['/home']);
    this.sidenavClose.emit();
  }

  mailto(emailAddress: string, emailSubject: any) {
    return "mailto:" + emailAddress + "?subject=" + emailSubject
  }

  about() {
    this.snackBar.open('Time Tracker', ' Version:8.0.0 Date:21-01-08',
      {
        duration: this.config.snackBarTiming,
        panelClass: "snackBarOk"
      })
  }
}
