import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMember } from '../../models/interfaceModels';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  activeuser: IMember | undefined;
  userRole: any;

  constructor(private fb: FormBuilder,
    private authService: AuthenticationService,
    private activeUser: ActiveuserService,
    private route: ActivatedRoute,
    private router: Router,
    private config: ConfigService,
    private snackBar: MatSnackBar
  ) { }


  userLogin = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  })

  hide = true;

  loading = false;
  submitted = false;

  ngOnInit(): void {
    

  }

  onLogin()  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userLogin.invalid) {
      this.snackBar.open('Kunde inte logga in', 'Du måste ange Svemo ID och lösenord',
        {
          duration: this.config.snackBarTiming ,
          panelClass:"snackBarErr"
        })
      this.userLogin.markAllAsTouched();
      return;
    }
     
    this.loading = true;
    this.authService.login(this.userLogin.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this.activeUser.changeUser(this.userLogin.value);
        this.snackBar.open('Lyckad inloggning', 'Välkommen till WMS tidtagningssystem',
          {
            duration: this.config.snackBarTiming,
            panelClass: "snackBarOk"
          })
        this.router.navigateByUrl('/home');
      },
      err => {
        if (err.status == 400)
          this.snackBar.open('Kunde inte logga in', 'Fel användarnamn eller lösenord Identifieringen misslyckades',
            {
              duration: this.config.snackBarTiming,
              panelClass: "snackBarErr"
            })
         
        else {
          console.log(err);
          alert(err.message);
        }
        this.loading = false;
      }

    )
  }

}
