<mat-sidenav-container class="mw-sidenav-container">
  <mat-sidenav #sidenav role="navigation" mode="push">
    <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <app-layout>
      <router-outlet></router-outlet>
    </app-layout>
  </mat-sidenav-content>
</mat-sidenav-container>

