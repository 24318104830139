import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//import {  NgxMatDatetimePickerModule,  NgxMatNativeDateModule,  NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { Papa } from 'ngx-papaparse';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { AuthInterceptor } from './auth/auth.interceptor';

import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material/material-module';
import { ActiveuserService } from './services/activeuser.service';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ToastrModule } from 'ngx-toastr';
import { DbMembersComponent } from './pages/db-members/db-members.component';
import { AboutComponent } from './components/about/about.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddMemberComponent } from './pages/db-members/add-member/add-member.component';
import { MemberProfileComponent } from './pages/db-members/member-profile/member-profile.component';
import { DbInfoComponent } from './pages/db-info/db-info.component';
import { ClubInfoComponent } from './pages/db-info/club-info/club-info.component';
import { LapInfoComponent } from './pages/db-info/lap-info/lap-info.component';
import { McInfoComponent } from './pages/db-info/mc-info/mc-info.component';
import { SeriesInfoComponent } from './pages/db-info/series-info/series-info.component';
import { AddLapComponent } from './pages/db-info/club-info/Dialogs/add-lap/add-lap.component';
import { EditLapComponent } from './pages/db-info/club-info/Dialogs/edit-lap/edit-lap.component';
import { DbEventsComponent } from './pages/db-events/db-events.component';
import { EditEventComponent } from './pages/db-events/edit-event/edit-event.component';
import { AddEventComponent } from './pages/db-events/add-event/add-event.component';
import { RunTrainingComponent } from './pages/run-training/run-training.component';
import { RunCompetitionComponent } from './pages/run-competition/run-competition.component';
import { PrepareCompetitionComponent } from './pages/prepare-competition/prepare-competition.component';
import { LiveTrainingComponent } from './pages/live-training/live-training.component';
import { LiveCompetitionComponent } from './pages/live-competition/live-competition.component';
import { ResultsCompetitionComponent } from './pages/results-competition/results-competition.component';
import { ResultsTrainingComponent } from './pages/results-training/results-training.component';
import { ByTrainingComponent } from './pages/results-training/by-training/by-training.component';
import { ByMemberComponent } from './pages/results-training/by-member/by-member.component';
import { DummyComponent } from './components/dummy/dummy.component';
import { AddParticipantComponent } from './pages/prepare-competition/add-participant/add-participant.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AddReaderComponent } from './pages/db-info/club-info/Dialogs/add-reader/add-reader.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MessageInputDialogComponent } from './components/message-input-dialog/message-input-dialog.component';
import { UnitStatusComponent } from './pages/unit-status/unit-status.component';




 
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavListComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DbMembersComponent,
    AboutComponent,
    ConfirmDialogComponent,
    AddMemberComponent,
    MemberProfileComponent,
    DbInfoComponent,
    ClubInfoComponent,
    LapInfoComponent,
    McInfoComponent,
    SeriesInfoComponent,
    AddLapComponent,
    EditLapComponent,
    DbEventsComponent,
    AddEventComponent,
    EditEventComponent,
    RunTrainingComponent,
    RunCompetitionComponent,
    PrepareCompetitionComponent,
    LiveTrainingComponent,
    LiveCompetitionComponent,
    ResultsCompetitionComponent,
    ResultsTrainingComponent,
    ByTrainingComponent,
    ByMemberComponent,
    DummyComponent,
    AddParticipantComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddReaderComponent,
    InfoCardComponent,
    MessageInputDialogComponent,
    UnitStatusComponent,
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MaterialFileInputModule,

    //NgxCsvParserModule,
    FormsModule,
    ReactiveFormsModule,

    //NgxMatDatetimePickerModule,
    //NgxMatNativeDateModule,
    //NgxMatTimepickerModule,
    NgxMatSelectSearchModule,

    //PRIMENG MODULES
    TableModule,
    MultiSelectModule,
    InputTextModule,
    ButtonModule,
    HttpClientModule,
    ToastModule,
    DropdownModule,
  ],
  providers: [
    ActiveuserService,
    AuthenticationService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
