import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AntennaTranslator, IRFIDReader } from '../../../../../models/interfaceModels';
import { DbService } from '../../../../../services/db.service';
import { AddLapData } from '../add-lap/add-lap.component';

@Component({
  selector: 'app-add-reader',
  templateUrl: './add-reader.component.html',
  styleUrls: ['./add-reader.component.css']
})
export class AddReaderComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddReaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dbService: DbService) { }

  public antennaTranslate: AntennaTranslator[] = this.data.antennaTranslate;

  public addReaderForm = this.fb.group({
    id: [0],
    clubModelId: [this.data.editClub.clubModelId],
    readerName: ["", [Validators.required, Validators.minLength(4)]],
  })

  ngOnInit(): void {
    if (this.data.editReader)
      this.addReaderForm.controls['readerName'].setValue(this.data.editReader.readerName)
  }

  onAddClick() {
    if (this.addReaderForm.valid) {
      //Create new RFID Reader
      if (!this.data.editReader) {
        var newReader: IRFIDReader = {
          rfidReaderModelId: 0,
          clubModelId: this.data.editClub.clubModelId,
          readerName: this.addReaderForm.value.readerName,
          isListening: false,
          isConnected: false,
          version : 1,
          antennaTranslator: this.antennaTranslate
        }
        console.log('Lägger till ny RFID läsare');
        this.dbService.addRFIDReader(newReader);
        this.dialogRef.close();
      }
      else {
        var newReader: IRFIDReader = {
          rfidReaderModelId: this.data.editReader.rfidReaderModelId,
          clubModelId: this.data.editClub.clubModelId,
          readerName: this.addReaderForm.value.readerName,
          isListening: false,
          isConnected: false,
          version : 1,
          antennaTranslator: this.antennaTranslate
        }
        console.log('Uppdaterar befintlig läsare');
        this.dbService.updateRFIDReader(newReader);
        this.dialogRef.close();
      }
    }
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
