import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBikeBrand, IClub, IMember, IupdateUrlMember } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { DbService } from '../../../services/db.service';

export interface AddDialogData {

}

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData,
    private fb: FormBuilder,
    private dbService: DbService,
    private activeUser: ActiveuserService, 
  ) { }

  public member: IMember;
  public bikeBrands: IBikeBrand[] = this.dbService.allBikeBrands;
  public clubs: IClub[] = this.dbService.allClubs;

  public pictureUrl: File;

  public accept = '.jpg, .png'
  public multiple = false;

  private updateUrlMember: IupdateUrlMember;

  ngOnInit(): void {

  }

  addMemberForm = this.fb.group({
    svemoId: [, [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    clubMembership: ['', [Validators.required]],
    bikeBrandModelId: [, [Validators.required]],
    clubModelId: [0, [Validators.required]],
    pictureUrl: [''],
  })

  onNoClick() {
    this.dialogRef.close();
  }

  async onAddClick() {
   if (this.addMemberForm.valid) {
      var picUrl = "";
      if (this.addMemberForm.value.pictureUrl) {
        this.updateUrlMember = {         
          svemoId: this.addMemberForm.value.svemoId,
          PictureUrl: this.addMemberForm.value.pictureUrl.files[0]
        }
        picUrl = "exists";
      }
      else {
        picUrl = "/assets/img/profile.png"
        this.updateUrlMember = {
          svemoId: this.addMemberForm.value.svemoId,
          PictureUrl: null
        }
      }

      this.member = {
        svemoId: this.addMemberForm.value.svemoId,
        isActive: true,
        firstName: this.addMemberForm.value.firstName,
        lastName: this.addMemberForm.value.lastName,
        clubModel: null,
        clubModelId: +this.activeUser.user.clubModelId,
        clubMembership : this.addMemberForm.value.clubMembership,
        bikeBrandModel: null,
        bikeBrandModelId: +this.addMemberForm.value.bikeBrandModelId,
        pictureUrl: picUrl,
        picture: [],
        confirmedTimeReadModel: null,
        intermediateTimeModel: null,
        lapTimeModel: null,
        timeReadModel: null,
      }

      console.log(this.member)
      this.dbService.addMember(this.member, this.updateUrlMember)
      this.dialogRef.close();
    }
    else
      alert('Form is not valid')
  }
}
