import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClub, IEvent, IEventType, ILap, ILapInEvent } from '../../../models/interfaceModels';
import { DbService } from '../../../services/db.service';

export interface AddEvent {
  editEvent: IEvent,
}
export interface LapIn {
  lapId: number;
}

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddEvent,
    private fb: FormBuilder, private dbService: DbService) { }

  public clubs: IClub[];
  public selectedClub: IClub;
  private broadCastClubs: any;

  public eventTypes: IEventType[];
  public selectedEventType: IEventType;
  private broadCastEventTypes: any;

  public Laps: ILap[];
  public LapsinEvent: ILap[];
  public selectedLap: ILap;
  public selectedEventLap: ILap;


  distinctEventTypes() {
    if (this.eventTypes) {
      return this.eventTypes.filter(
        (event, i, arr) => arr.findIndex(t => t.type === event.type) === i);
    }
    else
      return [];
  }
  eventCases(eventType: string) {
    if (eventType)
      return this.eventTypes.filter(eType => eType.type == eventType);
    else
      return [];
  }
  
  addEventForm = this.fb.group({
    id: [0],
    eventDate: ['', [Validators.required]],
    eventName: ['', [Validators.required]],
    clubModelId: [, [Validators.required]],
    eventType: [, [Validators.required]],
    eventTypeId: [, [Validators.required]],
    hasStarted: [false],
    weatherData: [''],
    temperature: [''],
    laps: [[], Validators.required]
  })

  ngOnInit(): void {
    this.LapsinEvent = [];
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      console.log('CLUBS')
      this.clubs = data;
    });
    this.broadCastEventTypes = this.dbService.broadCastEventTypes.subscribe(data => {
      this.eventTypes = data;
      if (this.data.editEvent) {
        console.log('Bla')

        this.addEventForm.controls['eventDate'].setValue(this.data.editEvent.eventDate);
        this.addEventForm.controls['eventName'].setValue(this.data.editEvent.eventName);
        this.clubs.forEach(club => {
          if (club.clubModelId == this.data.editEvent.clubModelId) {
            this.selectedClub = club;
          }
        })
        this.addEventForm.controls['clubModelId'].setValue(this.data.editEvent.clubModelId);
        this.addEventForm.controls['eventType'].setValue(this.data.editEvent.eventType);
        this.addEventForm.controls['eventTypeId'].setValue(this.data.editEvent.eventTypeId);

        var findIdxDistinctEvents = this.distinctEventTypes().findIndex(data => data.type == this.data.editEvent.eventType.type);
        this.selectedEventType = this.distinctEventTypes()[findIdxDistinctEvents];      
        this.data.editEvent.lapinEvent.forEach(data => {
          this.addEventForm.value.laps.push(data.lapId)
        })
      }
    });
    
  };

  //addExistingLapFormGroup(lap: ILapInEvent) {
  //  return lap.lapId
  //}
  //addExistsingLap(lap: ILapInEvent) {
  //  (<number>this.addEventForm.get('laps')).push(this.addExistingLapFormGroup(lap));
  //}

  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
    this.broadCastEventTypes.unsubscribe();
  }
   
  lapInEventCreate(laps: number[], eventId:number): ILapInEvent[] {
    var lapsInEvent: ILapInEvent[];
    lapsInEvent = [];
    laps.forEach(lap => {
      var lapInEvent: ILapInEvent = {
        lapId: lap,
        lapModel: null,
        eventId: eventId,
        eventModel: null,
        lapNbr: 0,
      };
      lapsInEvent.push(lapInEvent);
    })
    return lapsInEvent;
  }

  onClubChange(event) {
    this.clubs.forEach(club => {
      if (club.clubModelId == event.value) { this.selectedClub = club; }
    })
    this.LapsinEvent = [];
  };
  onCaseChange(eventTypeId:any) {
    console.log(eventTypeId)
    var eventTypeIdx = this.eventTypes.findIndex(data => data.id == eventTypeId.value);
    this.addEventForm.controls['eventType'].setValue(this.eventTypes[eventTypeIdx]);
  };


  onClick() {
    console.log(this.LapsinEvent)
  }

  onAddClick() {
    console.log(this.addEventForm.valid)
    console.log(this.addEventForm)
    if (this.addEventForm.valid) {
      this.broadCastClubs.unsubscribe();
      this.broadCastEventTypes.unsubscribe();
      if (!this.data.editEvent) {
        var event: IEvent = {
          eventId: 0,
          eventDate: this.addEventForm.value.eventDate,
          eventName: this.addEventForm.value.eventName,
          club: null,
          clubModelId: +this.addEventForm.value.clubModelId,
          eventType: null,
          eventTypeId: +this.addEventForm.value.eventTypeId,
          hasStarted: 0,
          weatherData: '',
          temperature: '',
          memberInEvent: null,
          memberInCompetition: null,
          timeReadModel: null,
          confirmedTimeReadModel: null,
          intermediateTimeModel: null,
          lapRepetitions: 0,
          lapTimeModel: null,
          lapinEvent: this.lapInEventCreate(this.addEventForm.value.laps, 0),
          seriesModel: null,
          seriesModelId: 0,
          ignoreFirstStartPost: false,
          classesInEvent: null,
          startType: null,
          startTypeId: 0,
        }
        console.log(event)
        this.dbService.addEvent(event)
        this.broadCastClubs.unsubscribe();
        this.broadCastEventTypes.unsubscribe();
        this.dialogRef.close();
      }
      else {
        var event: IEvent = {
          eventId: this.data.editEvent.eventId,
          eventDate: this.addEventForm.value.eventDate,
          eventName: this.addEventForm.value.eventName,
          club: null,
          clubModelId: +this.addEventForm.value.clubModelId,
          eventType: null,
          eventTypeId: +this.addEventForm.value.eventTypeId,
          hasStarted: this.data.editEvent.hasStarted,
          weatherData: this.data.editEvent.weatherData,
          temperature: this.data.editEvent.temperature,
          memberInEvent: null,
          memberInCompetition: null,
          timeReadModel: null,
          confirmedTimeReadModel: null,
          intermediateTimeModel: null,
          lapRepetitions: this.data.editEvent.lapRepetitions,
          lapTimeModel: null,
          lapinEvent: this.lapInEventCreate(this.addEventForm.value.laps, this.data.editEvent.eventId),
          seriesModel: null,
          seriesModelId: this.data.editEvent.seriesModelId,
          ignoreFirstStartPost: this.data.editEvent.ignoreFirstStartPost,
          classesInEvent: null,
          startType: null,
          startTypeId: this.data.editEvent.startTypeId,
        }
        console.log(event)
        this.dbService.updateEvent(event);
        this.dbService.updateLapsInEvent(event.lapinEvent);

        this.dialogRef.close();
      }
      
    }
    else if (this.addEventForm.valid)
      alert('Du måste välja minst ett varv att följa')
    else
      this.addEventForm.markAllAsTouched();
  }

  onNoClick() {
    this.dialogRef.close();
  }
  onDebug() {
    console.log(this.addEventForm.value)
    console.log(this.selectedClub)

  }
}
