<mat-card fxLayoutalign="start center" style="margin-top:0.5rem; padding:0">
  <!--HEADER with dropdowns-->
  <div style="height:4.0rem" fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
    <h3 style="padding-left:2rem; font-family:Roboto; font-weight:500">Välj tävling:</h3>
    <mat-form-field appearance="fill">
      <mat-label>Välj klubb:</mat-label>
      <mat-select [(value)]="selectedClub"
                  [compareWith]="comparer"
                  style="padding:0;margin:0"
                  (selectionChange)="onClubChange($event)">
        <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="selectedClub" appearance="fill">
      <mat-label>Välj serie:</mat-label>
      <mat-select [(value)]="selectedSerie"
                  (selectionChange)="onSerieChange($event)">

        <mat-option *ngFor="let serie of allSeries" [value]="serie">{{serie.serieName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-tab-group style="background-color:whitesmoke; margin-top:1rem">

    <mat-tab *ngFor="let competition of displayCompetition; let i = index;"
             label="{{competition.event.eventName}}">
      <div *ngFor="let class of competition.eventClasses">
        <h2 style="margin-left:1rem">{{class}}</h2>
        <p-table [value]="dataRoom.filterDisplayMembers(competition.displayMembers , class)" dataKey="id" sortMode="multiple"
                 [scrollable]="true" scrollHeight="500px">
          <!--HEADER-->
          <ng-template pTemplate="header">
            <div fxLayoutAlign="start center" class="mw_main_table_header">
              <th fxHide.lt-sm style="width: 1.5rem"></th>
              <th fxFlex="4.5rem" fxLayoutAlign="center center" pSortableColumn="position">Pos.<p-sortIcon field="position"></p-sortIcon></th>
              <th fxFlex="5rem" fxLayoutAlign="center center" pSortableColumn="points">Poäng<p-sortIcon field="points"></p-sortIcon></th>
              <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
              <th fxFlex="5rem" *ngFor="let header of headerArray[i]" style="font-size:0.8rem; font-weight:500" fxLayoutAlign="center center">
                <span fxLayoutAlign="center center">{{competition[i].header.header}}</span>
              </th>
              <th fxFlex></th>
              <th fxFlex="4rem" fxLayoutAlign="center center" style="font-size:0.9rem" pSortableColumn="nbrLaps">Varv<p-sortIcon field="nbrLaps"></p-sortIcon></th>
              <th fxFlex="4rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="totalTime">Tid<p-sortIcon field="totalTime"></p-sortIcon></th>
            </div>
          </ng-template>
          <!--BODY-->
          <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
            <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
              <td fxFlex="1.5rem" fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
              </td>
              <td fxFlex="4.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center"><b>{{displayMember.position}}</b></span>
              </td>
              <td fxFlex="4.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center"><b>{{displayMember.points}}</b></span>
              </td>
              <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
              </td>


              <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
                <div fxFlexLayout="row">
                  <!--<span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{dataRoom.displayLapName(time.lapID)}} </span>-->
                  <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{dataRoom.convertToMiniTime(time.lapTime)}} </span>
                </div>
              </td>
              <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
              <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
                <span fxLayoutAlign="start center" style="font-size:0.7rem">{{displayMember.nbrLaps}}</span>
              </td>
              <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="start center">
                <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{displayMember.totalTime}} </span>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-member>
            <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">

            </div>
            <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
              <!--ConfirmedTimeReads TABLE-->
              <p-table fxFlex="60" [value]="member.confirmedTimeReadModel" dataKey="SvemoId"
                       [scrollable]="true" scrollHeight="200px">
                <ng-template pTemplate="header">
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                    <span><u><b>Tid stämplar</b></u></span>
                  </div>
                  <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                    <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                    <th fxFlex="5rem" fxLayoutAlign="center center">Tid</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Läsare</th>
                    <th fxFlex="3rem" fxLayoutAlign="center center">Ant</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Status</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                    <th fxFlex></th>
                  </div>
                </ng-template>
                <ng-template pTemplate="body" let-confirmedTimeReadModel let-expanded="expanded">
                  <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem"
                      [style.color]="confirmedTimeReadModel.correctRead ? 'black' : 'red' ">
                    <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.timeReadID}}</span>
                    </td>
                    <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.time | date:'HH:mm:ss'}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.readerId}}</span>
                    </td>
                    <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.antennaId}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.status}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.ladIdx}}</span>
                    </td>
                    <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                  </tr>
                </ng-template>
              </p-table>
              <p-table fxFlex="40" [value]="member.intermediateTimeModel" dataKey="SvemoId"
                       [scrollable]="true" scrollHeight="200px">
                <ng-template pTemplate="header">
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem;font-size:1.1rem">
                    <span><u><b>Mellantider</b></u></span>
                  </div>
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                    <th fxFlex="60" fxLayoutAlign="center center">Mellantid</th>
                    <th fxFlex="40" fxLayoutAlign="center center">Tid</th>
                    <th fxFlex="2.5rem" fxLayoutAlign="center center">ID1</th>
                    <th fxFlex="2.5rem" fxLayoutAlign="center center">ID2</th>
                    <th fxFlex></th>
                  </div>
                </ng-template>
                <ng-template pTemplate="body" let-intermediateTimeModel let-expanded="expanded">
                  <tr fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.7rem">
                    <td fxFlex="60" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <!--<span style="font-size:0.8rem">{{displayLapName(intermediateTimeModel.lapID)}}</span>-->
                    </td>
                    <td fxFlex="40" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{dataRoom.convertToMiniTime(intermediateTimeModel.lapTime)}}</span>
                    </td>
                    <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{intermediateTimeModel.id1}}</span>
                    </td>
                    <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{intermediateTimeModel.id2}}</span>
                    </td>
                    <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </ng-template>
        </p-table>
      </div>

    </mat-tab>
    
    <mat-tab *ngIf="allEvents.length > 0" label="Totalt" >
      <div *ngFor="let class of allClasses" >
        <h2 style="margin-left:1rem">{{class}}</h2>
        <p-table *ngIf="(displayCompetition)"
                 [value]="createTotalSerie(displayCompetition, class)" dataKey="id" sortMode="multiple"
                 [scrollable]="true" scrollHeight="500px">

          <ng-template pTemplate="header">
            <div fxLayoutAlign="start center" class="mw_main_table_header">
              <th style="margin-left:0.5rem" fxFlex="12rem" fxLayoutAlign="center center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
              <th *ngFor="let event of allEvents" fxFlex="4rem" fxLayoutAlign="center center">{{event.eventName}}</th>
              <th fxFlex="4rem" fxLayoutAlign="center center">Totalt</th>
            </div>
          </ng-template>

          <ng-template pTemplate="body" let-displayMember>
            <tr fxFlexLayout="row" fxLayoutAlign="start center" fxLayoutGap="0" style="padding: 0px; margin-left:0.5rem; height:2rem">
              <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
              </td>
              <td *ngFor="let info of displayMember.infoArray" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
                <div fxFlexLayout="row">
                  <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{info.points}} </span>
                </div>
              </td>

              <td fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
                <span fxLayoutAlign="center center" style="font-size:0.7rem"><b>{{displayMember.totalPoints}}</b></span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </mat-tab>


  </mat-tab-group>


    <!--<button class="mw-deleteBtn-icon" (click)="console()">
      <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
    </button>-->

</mat-card>
