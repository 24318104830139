<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="60%" fxFlex.lt-md="70%" fxFlex.lt-sm="100%" class="MatCard">
      <mat-card-header>
        <mat-card-title>
          Registrera ny användare:
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxFlexLayout="row">
        <div fxLayout="row" fxLayout.lt-sm="column">
          




            <div fxFlex="50" fxFlexLayout="column">
              <form [formGroup]="userLogin">
                <div class="row">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="Svemo ID:" formControlName="username">
                    <mat-icon matSuffix matTooltip="Använd ditt SvemoID, funkar bara om du är medlem i Wäxjö MS">info_outline</mat-icon>
                    <mat-error *ngIf="userLogin.controls['username'].hasError('required')">
                      Svemo ID <strong>måste anges</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <br />
                <div class="row">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="E-mail:" formControlName="email">
                    <mat-icon matSuffix matTooltip="Detta e-mail används bara för att återställa lösenordet och kommer inte visas!">info_outline</mat-icon>
                    <mat-error *ngIf="userLogin.controls['email'].hasError('required')">
                      E-mail <strong>måste anges</strong>
                    </mat-error>
                    <mat-error *ngIf="userLogin.controls['email'].hasError('email')">
                      Ingen giltig E-mail
                    </mat-error>
                  </mat-form-field>
                </div>
                <br />
                <div class="row">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="userLogin.controls['password'].hasError('required')">
                      Lösenord <strong>måste anges</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
        </div>

            <div fxFlex="50" fxFlexLayout="row" style="border:ridge; border-radius:0.5rem; padding:0.5rem">
              <h3 style="margin:0rem; padding:0rem">Villkor:</h3>
              <span fxLayout="row-wrap">
                Den e-mail som du använder vid registrering kommer inte visas
                eller spridas till någon annan part. Den kommer endast användas i syfte att kunna återskapa
                ett nytt lösenord vid behov.
              </span>
              <br />
              <span fxLayout="row-wrap">
                Om du vill att din data tas bort från systemet skickar du ett mail genom att klicka på kuvertet
                uppe till höger och skriver syfte och ditt ID-nummer!
              </span>
              <mat-checkbox [(ngModel)]="acceptedUserTerms">Acceptera användar villkoren</mat-checkbox>
            </div>
        </div>
      </mat-card-content>

      <mat-card-actions fxLayoutAlign="center center">
        <button class="mr-2 PrimaryBtn" mat-raised-button (click)="onSubmit()">Registrera</button>
        <button class="mr-2 PrimaryBtn" mat-raised-button routerLink="/home">Ångra</button>
      </mat-card-actions>
  </mat-card>

</div>
