import { Injectable, EventEmitter } from '@angular/core';

import * as signalR from "@aspnet/signalr";

import { ConfigService } from './config.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';


import { ActiveuserService } from './activeuser.service';
import { IClient, IPi, IEvent, IRFIDReader, ITimeRead, ISendTime, IIntermediateTimeModel, IMemberInCompetition } from '../models/interfaceModels';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(private config: ConfigService, private dbService: DbService, ) { }

  public isConnected = false;
  //public client: IClient = { connectionId: '', signalRInterest : '', userName : ''  }
  public configUrl: string = this.config.configUrlSignalR;


  private hubConnection!: signalR.HubConnection;

  private changedClients = new BehaviorSubject<IClient[]>([]);
  broadCastClients = this.changedClients.asObservable();


  // CHANGE IN PI
  private changedPis = new ReplaySubject<IPi[]>();
  broadCastPis = this.changedPis.asObservable();
  // Listens for connected readers
  public changedPiConnectionList() {
    this.hubConnection.on('changedPiConnections', (piList: IPi[]) => {
      console.log('New Pis added from SignalR')
      console.log(piList)
      piList.forEach(pi => {
        pi.availableReaders.forEach(reader => {
          console.log(reader.readerName + ' active: ' + reader.isListening + ' at ' + pi.clubName)
        })
        
      })
      this.changedPis.next(piList);
    });
  }

  private changedEvents = new ReplaySubject<IEvent[]>(1);
  broadCastEvents = this.changedEvents.asObservable();

  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.configUrl)
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.isConnected = true;
        console.log('Connection started');
      })
      .catch(err => console.log('Error while starting connection: ' + err))
  }


  //Identifiera användare mot servern
  public sendIdentify(client: IClient) {
    this.hubConnection.invoke('IdentifyConnectionClient', client);
  }

  // Asks for connected readers
  public getPiConnectionList() {
    this.hubConnection.invoke('sendPiListToClient', (piList: IPi[]) => {
      this.changedPis.next(piList);
    });
  }

  public startEvent(event: IEvent) {
    this.hubConnection.invoke('StartEvent', event);
  }
  public startReaders(readers: IRFIDReader[]) {
    console.log(readers)
    this.hubConnection.invoke('startReaders', readers);
  }

  public getEventList(): void {
    this.hubConnection.on('updateEventList', () => {
      this.dbService.getEvents();
    })
  }

  //Gets all connected Clients
  public getClientConnectionList() : void {
    this.hubConnection.on('changedClientConnections', (clients: IClient[]) => {
      this.changedClients.next(clients);
    })
  }



  //Listen for updates in timereads
  public changedTimeReads(event: IEvent): void { 
    this.hubConnection.on('changedTimeReads', (timeReads: any) => {
      console.log(timeReads)
        //this.dbService.getTimeReadsEvent(event);
    })
  }
  public changedConfirmedTimeReads(event: IEvent): void {
   
    this.hubConnection.on('changedConfirmedTimeReads', (timeReads: any) => {
      console.log(timeReads)
      //this.dbService.getMembersTimesEvent(event);
    })
  }


  // NEW SignalR
  // keeps track of active event
  public activeEvent: IEvent | undefined;


  //Broadcasts new event data
  private newEventData = new ReplaySubject<ISendTime>();
  broadCastnewEventData = this.newEventData.asObservable();
  public listenForEventData(event: IEvent): void {
    console.log("Starting signalR service -- listenForEventData --")

    this.hubConnection.on('newEventData', (eventData: ISendTime) => {
      if (event.eventId == eventData.timeRead.eventId || event.eventId == eventData.newMember.eventId) {
        console.log("Data recieved from signalR -- listenForEventData")
        console.log(eventData)
        if (eventData.intermediateTime) {
          var test = this.stringifyTimes(eventData.intermediateTime)
          eventData.intermediateTime.lapTime = test;
        }
        if (eventData.lapTime) {
          var test = this.stringifyTimes(eventData.lapTime)
          eventData.lapTime.lapTime = test;
        }
        this.newEventData.next(eventData);
      }
    })
  }
  public stoplistenForEventData(): void {
    console.log("Quiting signalR service -- listenForEventData --")
    this.hubConnection.off('newEventData');
  }

  //BroadCastTagCheck
  private newEventCheckData = new ReplaySubject<IMemberInCompetition>();
  broadCastnewEventCheckData = this.newEventCheckData.asObservable();
  public listenForNewEventCheckData(event: IEvent): void {
    console.log("Starting signalR service -- listenFor newEventCheckData --")
    this.hubConnection.on('newEventCheckData', (eventData: any) => {
      console.log(eventData)
      if (event.eventId == eventData.eventId) {
        console.log("Data recieved from signalR -- listenFor newEventCheckData")
        this.newEventCheckData.next(eventData);
      }
    })
  }
  public stoplistenForNewEventCheckData(): void {
    console.log("Quiting signalR service -- listenForEventData --")
    this.hubConnection.off('newEventCheckData');
  }





  public stringifyTimes(timeRecord: any): any {

    var Hour = ('00000' + timeRecord.lapTime.hours).slice(-2);
    var Minute = ('00000' + timeRecord.lapTime.minutes).slice(-2);
    var Second = ('00000' + timeRecord.lapTime.seconds).slice(-2);
    var Millisecond = ('0000000' + timeRecord.lapTime.milliseconds).slice(-7);
   
    return Hour+':' + Minute + ':' + Second +':' + Millisecond
  }
}
