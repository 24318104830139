import { Component, OnInit } from '@angular/core';
import { IClub, IEvent, IMember } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';
import { DisplayMember } from '../run-competition/run-competition.component';

@Component({
  selector: 'app-live-competition',
  templateUrl: './live-competition.component.html',
  styleUrls: ['./live-competition.component.css']
})
export class LiveCompetitionComponent implements OnInit {

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private signalR: SignalRService,
    private activeUserService: ActiveuserService,) { }

  //Keep track of active user
  public activeUser: IMember;
  private broadCastActiveUser: any;

  //Event data
  public allEvents: IEvent[] = [];
  private broadCastallEvents: any;
  public selectedEvent: IEvent;
  public allClasses: string[] = [];
  public dummyallClasses: { name: string, idx: number }[] = [];

  public displayMembers: DisplayMember[] = [];
  public nbrParticipants: number = 0;
  public maxLaps: number = 0;

  //Keep track of clubs
  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClub: IClub;

  //SignalR services
  private signalRBroadCast: any;

  public headers: [{ header: string, field: string }];
  public statusColors = [{ status: "Start", color: "green" }, { status: "Mål", color: "yellow" },
  { status: "Mellantid", color: "green" }, { status: "Unknown", color: "red" }]

  ngOnInit(): void {
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      this.activeUser = data
      this.selectedClub = this.activeUser.clubModel
      this.dbService.getEventsByClub(data.clubModel.clubModelId);
    })
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })
    this.broadCastallEvents = this.dbService.broadCastEvents.subscribe(data => {
      if (this.selectedClub) {
        this.allEvents = data.filter(event => event.clubModelId && event.eventType.type == 'Tävling' && event.hasStarted < 3)
        if (this.selectedEvent) {
          this.selectedEvent.hasStarted = this.allEvents.find(data => data.eventId == this.selectedEvent.eventId).hasStarted;
        }
      }
    })

    //SignalR services
    this.signalR.getEventList();
  }

  ngOnDestroy(): void {
    //DB services
    this.broadCastActiveUser.unsubscribe();
    this.broadCastClubs.unsubscribe();
    this.broadCastallEvents.unsubscribe();
    // SignalR services
    this.signalR.stoplistenForEventData();
    if (this.signalRBroadCast)
      this.signalRBroadCast.unsubscribe();
  }

  onClubChange(event) {
    this.signalR.stoplistenForEventData();


    this.dbService.getActivePis();
    this.dbService.getEventsByClub(this.selectedClub.clubModelId);
  }

  onEventChange(event) {

    this.signalR.stoplistenForEventData();
    this.dbService.getRegisteredParticipantsInRace(this.selectedEvent.eventId).subscribe(data => {
      this.nbrParticipants = data.length;
      this.selectedEvent.memberInCompetition = data;
      this.displayMembers = this.dataRoom.createDisplayMembers(data);
      this.dbService.getMembersTimesEventDirect(event.value).subscribe(data => {
        if (data) {
          //console.log(data)
          //Create headers for all laps
          data.forEach(member => {
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).confirmedTimeReadModel = member.confirmedTimeReadModel;
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).intermediateTimeModel = member.intermediateTimeModel;
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).lapTimeModel = member.lapTimeModel;
            member = this.dataRoom.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId))
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).nbrLaps = member.lapTimeModel.length;
            // If this member has more laps then allready added to header add new headers
            if (member.lapTimeModel.length > this.maxLaps) {
              var addedLaps = member.lapTimeModel.length;
              console.log(this.selectedEvent.lapinEvent[0].lapModel.lapName)
              if (this.selectedEvent.eventType.case != 'Heat') {
                while (this.maxLaps < member.lapTimeModel.length) {
                  if (!this.headers)
                    this.headers = [{ header: this.selectedEvent.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + this.maxLaps + ']' }];
                  else
                    this.headers.push({ header: this.selectedEvent.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + this.maxLaps + ']' });
                  this.maxLaps++;
                }
              }
              else {
                while (this.maxLaps < member.lapTimeModel.length) {
                  if (!this.headers)
                    this.headers = [{ header: 'Heat 1', field: 'lapTimeModel[' + this.maxLaps + ']' }];
                  else
                    this.headers.push({ header: 'Heat ' + this.maxLaps, field: 'lapTimeModel[' + this.maxLaps + ']' });
                  this.maxLaps++;
                }
              }
            }
          })
          this.allClasses = this.dataRoom.uniqueClasses(this.displayMembers);
          this.dummyallClasses = [];
          this.allClasses.forEach(data => {
            this.dummyallClasses.push({ name: data, idx: 0 })
          })
        }
      });
      this.dbService.getClassModelsByEvent(event.value.eventId).subscribe(data => {
        this.selectedEvent.classesInEvent = data;
      })
      //this.eventsInSerie = this.allEvents.filter(data => data.seriesModelId == this.selectedEvent.seriesModelId);
    })

    this.signalRBroadCast = this.signalR.broadCastnewEventData.subscribe(eventData => {
      console.log('New data from event')
      if (eventData.timeRead) {
        console.log('Add TimeRead: ' + eventData.timeRead.timeReadID)
        this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId)
          .confirmedTimeReadModel.push(eventData.timeRead)

        if (this.selectedEvent.eventType.case != 'Heat' && eventData.timeRead.status == "Mål"
          && eventData.timeRead.ladIdx
          == this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className) *
          this.selectedEvent.lapinEvent.length) {

          this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).lapTimeModel.length;
          this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId).isFinished = true;
          console.log('Mål')
        }
      }

      if (eventData.intermediateTime) {
        console.log('Add intermediate: ' + eventData.intermediateTime.timeReadID)
        this.displayMembers.find(member => member.svemoId == eventData.intermediateTime.svemoId)
          .intermediateTimeModel.push(eventData.intermediateTime)
      }

      if (eventData.lapTime) {
        this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId)
          .lapTimeModel.push(eventData.lapTime)
        this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).lapTimeModel.length;
        //this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId))
        this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).totalTime =
          this.dataRoom.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId)).totalTime;

        console.log('Add laptime: ' + eventData.lapTime.timeReadID)

        //Check if this is the final lap in final Heat
        if (this.selectedEvent.eventType.case == 'Heat') {
          var nbrLaps = this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className);
          var nbrHeats = this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className);
          console.log('NbrLapsToRun:' + nbrLaps)
          console.log('NbrHeatsToRun:' + nbrHeats)
          console.log('NbrLapsRecorded:' + this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId).lapTimeModel.length)
          if (this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId).lapTimeModel.length == nbrLaps * nbrHeats) {
            this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId).isFinished = true;
          }
        }
      }
    })

    this.signalR.listenForEventData(this.selectedEvent)
  }



  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.name === o2.name : o2 === o2;
  }
  getStatusColor(displayMember: DisplayMember) {
    if (displayMember.confirmedTimeReadModel.length > 0) {
      if (displayMember.isFinished)
        return "black"
      else {
        var lastRead = displayMember.confirmedTimeReadModel[displayMember.confirmedTimeReadModel.length - 1].status;
        return this.statusColors.filter(item => item.status == lastRead)[0].color
      }
    }
    else
      return 'blue'
  }
  displayLapName(LapId: number) {
    if (LapId)
      return this.selectedEvent.lapinEvent.find(lap => lap.lapId == LapId).lapModel.lapName
    else
      return 'Okännt'
  }
  convertToMiniTime(timeString: string): string {
    var noMilliSec = timeString.split(".").slice(0.1);
    var noHour = noMilliSec[0].split(":");
    return noHour[1] + ":" + noHour[2];
  }
}
