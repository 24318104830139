import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateMessage, DBMessage, MessageInputDialogComponent } from '../components/message-input-dialog/message-input-dialog.component';
import { IClub } from '../models/interfaceModels';
import { ActiveuserService } from '../services/activeuser.service';
import { ConfigService } from '../services/config.service';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private dialog: MatDialog,
    public activeUserService: ActiveuserService,
    public dbService: DbService,
    private http: HttpClient,
    private config: ConfigService  ) { }

  configUrl = this.config.configUrl;

  public messagesClub: DBMessage[];
  public messagesTrackTime: DBMessage[];
  public clubs: IClub[];
  public broadCastClubs: any;

  ngOnInit(): void {
    this.broadCastClubs = this.dbService.broadCastClubsPlus.subscribe(data => {
      this.clubs = data;

      this.dbService.getMessagesByClub(-1).subscribe(data => {
        this.messagesTrackTime = data.reverse();
        this.dbService.getMessagesByClub(this.activeUserService.user.clubModelId).subscribe(data => {
          this.messagesClub = data.reverse();
        })
      })
    })

    console.log(this.activeUserService.activeUserRole())
  }

  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
  }

  onAddClubNews() {
    var createMessage: CreateMessage = {
      user: this.activeUserService.user,
      type: 'clubMessage',
    }

    const dialogRef = this.dialog.open(MessageInputDialogComponent, {

      position: { top: '1rem' },
      width: '25rem',
      maxHeight: '60%',
      panelClass: 'custom-dialog-container',
      data: {
        user: this.activeUserService.user,
        type: 'clubMessage',  },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        this.dbService.getMessagesByClub(this.activeUserService.user.clubModelId).subscribe(data => {
          this.messagesClub = data.reverse();
        })
      }
      console.log('The dialog was closed');
    });
  }


  onAddSoftwareNews() {
    var createMessage: CreateMessage = {
      user: this.activeUserService.user,
      type: 'trackTimeMessage',
    }

    const dialogRef = this.dialog.open(MessageInputDialogComponent, {

      position: { top: '1rem' },
      width: '25rem',
      maxHeight: '60%',
      panelClass: 'custom-dialog-container',
      data: {
        user: this.activeUserService.user,
        type: 'trackTimeMessage', },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.getMessagesByClub(-1).subscribe(data => {
          this.messagesTrackTime = data.reverse();
        })
      }
      console.log('The dialog was closed');
    });
  }
  onDeleteMessage(messagelId: number) {
    this.dbService.removeMessage(messagelId).subscribe(data => {
      this.dbService.getMessagesByClub(-1).subscribe(data => {
        this.messagesTrackTime = data.reverse();
      })
      this.dbService.getMessagesByClub(this.activeUserService.user.clubModelId).subscribe(data => {

        this.messagesClub = data.reverse();
      })
    })
  }
}
