<body fxLayout="column">
  <!--HEADER-->
  <div fxLayout="row" style="margin:0px;padding:0px">
    <div fxFlex="75%" fxLayoutAlign="start center">
      <h2 style="margin-left:1rem">{{data.member.firstName}} {{data.member.lastName}}</h2>
      <button *ngIf="(  (activeUser.activeUserRole() == 'Admin' && activeUser.user.clubModelId == data.member.clubModelId)
             || activeUser.activeUserRole() == 'SAdmin' || activeUser.user.svemoId == data.member.svemoId ) && !editProfile"
              class="mw-editBtn-icon" (click)="onEdit()"
              style="margin-left:2rem" matTooltip="Uppdatera profil">
        <mat-icon>edit</mat-icon>
      </button>

      <button *ngIf="editProfile" class="mw-okBtn-icon"  (click)="onUpdate()"
              style="margin-left:2rem" matTooltip="Spara uppdatering">
        <mat-icon class="mw-okIcon-icon">done</mat-icon>
      </button>
      <button *ngIf="editProfile" class="mw-deleteBtn-icon" (click)="onCancel()"
              matTooltip="Ångra uppdatering">
        <mat-icon class="mw-deleteIcon-icon">undo</mat-icon>
      </button>

    </div>

    <div fxFlex fxLayoutAlign="end center">
      <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
        <mat-icon class="mw-mat-close-icon">close</mat-icon>
      </button>
    </div>

  </div>

  <!--<div fxLayout="row" fxLayout.xs="column" style="padding:5px" fxLayoutGap="10px" fxLayoutAlign="space-between">-->
  <div fxLayout="row" fxLayout.lt-sm="column">
    <div fxFlex="50%" fxLayoutAlign="center center" style="padding:0.5rem">
      <img style="max-height:450px; max-width:100%; border-radius:2%;" [src]='showImage()' />
    </div>


    <mat-tab-group fxFlex="50%" fxLayout="column" class="MatCard-mw" style="padding-left:0.5rem">
      <mat-tab label="Info">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
          <img class="mw_img" width="50" height="40" [src]='dataRoom.showClubPic(data.member.clubModelId)' style="border-radius:20%; margin-right:10px" />
          <span fxLayout="row" fxLayoutAlign="start center">
            <h3>{{dataRoom.showClubName(data.member.clubModelId)}}</h3>
            <span *ngIf="!editProfile">({{data.member.clubMembership}})</span>
            <mat-form-field style="padding-top:0.5rem; margin-left:0.5rem" *ngIf="editProfile" fxFlex="9rem" appearance="fill">
              <mat-label style=" height:1rem">Klubb tillörighet:</mat-label>
              <input style=" height:1rem" matInput type="text" [(ngModel)]="data.member.clubMembership">
            </mat-form-field>
          </span>
        </div>
        <div *ngIf="!editProfile" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
          <img class="img" width="50" height="40" [src]='dataRoom.showBikeBrandImage(data.member.bikeBrandModelId)' style="border-radius:20%; margin-right:10px" />
          <span><h4>{{dataRoom.showBikeBrand(data.member.bikeBrandModelId)}}</h4></span>
        </div>

        <div *ngIf="editProfile" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
          <img class="img" width="50" height="40" [src]='dataRoom.showBikeBrandImage(data.member.bikeBrandModelId)' style="border-radius:20%; margin-right:10px" />
          <mat-form-field style=" margin-left:0.5rem" fxFlex="8rem" appearance="fill" class="ml-2">
            <mat-label>Välj tillverkare:</mat-label>
            <mat-select [(value)]="data.member.bikeBrandModelId" (selectionChange)="changeBike(data.member.bikeBrandModelId)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let bikeBrand of bikeBrands" value="{{bikeBrand.id}}">{{bikeBrand.bikeBrand}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="(activeUser.activeUserRole() == 'Admin' && activeUser.user.clubModelId == data.member.clubModelId)
             || activeUser.activeUserRole() == 'SAdmin'"
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem"
             style="padding:0rem; margin-top:0rem; margin-bottom:0.5rem; margin-left:2rem">
          <mat-checkbox *ngIf="editProfile" [(ngModel)]="data.member.isActive" style="height:1rem"></mat-checkbox>
          <h4 *ngIf="editProfile">Aktiv</h4>
        </div>
        <div *ngIf="editProfile" fxLayout="row">
          <div *ngIf="data.member.svemoId == activeUser.user.svemoId
            || (activeUser.activeUserRole() == 'Admin' && activeUser.user.clubModelId == data.member.clubModelId)
            || activeUser.activeUserRole() == 'SAdmin'"
               fxLayout="row" fxLayoutGap="10px">
            <mat-form-field style="width:10rem">
              <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" [(ngModel)]="pictureUrl">
                <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
              </ngx-mat-file-input>
              <mat-hint> Uppdatera profilbild </mat-hint>
            </mat-form-field>
          </div>
          <!--<button color="primary" mat-raised-button
              style="height:2.5rem; width:8rem; margin:0.5rem" (click)="onUpdatePicUrl()">
        Uppdatera <mat-icon>published_with_changes</mat-icon>
      </button>-->
        </div>
      </mat-tab>
      <mat-tab label="Statistik">

      </mat-tab>
    </mat-tab-group>


  </div>
</body>
