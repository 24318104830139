export class User {

  constructor(
    public id: string,
    public username: string,
    public email: string,
    public password: string,
    public firstName: string,
    public lastName: string,
    public role: string,
    public token: string,

  ) { }
}

