import { Component, OnInit } from '@angular/core';
import { IClub, IMember } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-unit-status',
  templateUrl: './unit-status.component.html',
  styleUrls: ['./unit-status.component.css']
})
export class UnitStatusComponent implements OnInit {

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private signalR: SignalRService,
    private activeUserService: ActiveuserService,) { }

  public clubs: IClub[];
  public currentUser: IMember;
  public selectedClub: IClub;
  private broadCastPisDB: any;
  private broadCastClubs: any;
  private broadCastActiveUser: any;
  private broadCastPisSignalR: any;

  public activePis: any[];

  ngOnInit(): void {
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
      console.log(this.clubs)
    })
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      this.currentUser = data
      this.selectedClub = this.currentUser.clubModel
      this.dbService.getEventsByClub(data.clubModel.clubModelId);

    this.broadCastPisDB = this.dbService.broadCastPis.subscribe(data => {
      this.activePis = data;
      console.log('Active Pis  from DB');
      console.log(data)
      })
    })

    //SIGNALR SERVICES
    this.broadCastPisSignalR = this.signalR.broadCastPis.subscribe(data => {
      this.activePis = data;
      console.log('Active Pis  from SignalR');
      console.log(data)
      //this.signalR.broadCastEvents.subscribe(data => {
      //  if (this.selectedClub)
      //    this.allEvents = data.filter(event => event.clubModelId == this.selectedClub.clubModelId);
      //})
    })

    this.dbService.getActivePis();
    this.signalR.changedPiConnectionList();
  }
  getTheColor2(readerName) {
    //Check if reader exists if so check if it's active
    var status = 0
    if (this.activePis) {
      this.activePis.forEach(Pi => {
        Pi.availableReaders.forEach(reader => {
          if (reader.readerName == readerName) {
            if (!reader.isConnected)
              status = 3;
            else if (reader.isListening)
              status = 2;
            else
              status = 1;
          }
        })
      })
    }
    else
      return 'pink'
    if (status == 0)
      return 'red';
    if (status == 1)
      return 'yellow';
    if (status == 2)
      return 'green';
    if (status == 3)
      return 'orange'
    else
      return 'pink';
  }
}
