import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from '../../services/config.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private activeUser: ActiveuserService,
    private snackBar: MatSnackBar,
    private config: ConfigService
  ) { }

  loading = false;
  submitted = false;

  userLogin = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  })

  public acceptedUserTerms: boolean = false;

  hide = true;
   
  ngOnInit(): void {
  }


  onSubmit() {
    this.submitted = true;  
    // stop here if form is invalid
    if (this.acceptedUserTerms == false) {
      this.snackBar.open('Registreringen misslyckades: ', 'Du måste acceptera användar villkoren!',
        {
          duration: this.config.snackBarTiming,
          panelClass: "snackBarErr"
        })
      return;
    }

    if (this.userLogin.invalid) {
      this.snackBar.open('Registreringen misslyckades: ', 'Du måste ange Svemo ID, E-mail och lösenord och acceptera användarvillkoren',
        {
          duration: this.config.snackBarTiming,
          panelClass: "snackBarErr"
        })
      return;
    }

    this.loading = true;
    this.authService.register(this.userLogin.value).subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.snackBar.open('Lyckad registrering: ', 'Välkommen till WMS tidtagningssystem',
            {
              duration: this.config.snackBarTiming,
              panelClass: "snackBarOk"
            })
          this.authService.login(this.userLogin.value).subscribe(
            (res: any) => {
              localStorage.setItem('token', res.token);


              this.activeUser.changeUser(this.userLogin.value);
              this.userLogin.reset();
              this.router.navigateByUrl('/home');
            },
            err => {
              if (err.status == 400) {
                console.log(err);
                this.snackBar.open('Registreringen misslyckades: ', 'Det angivna Svemo ID finns inte med i databasen',
                  {
                    duration: this.config.snackBarTiming,
                    panelClass: "snackBarErr"
                  })
              }
              else {
                console.log(err);
                this.snackBar.open('Registreringen misslyckades: ', 'Det angivna Svemo ID finns inte med i databasen',
                  {
                    duration: this.config.snackBarTiming,
                    panelClass: "snackBarErr"
                  })
              }
              this.loading = false;
            })


          this.router.navigate(['/home']);

        } else {
          res.errors.forEach((element: { code: any; description: any; }) => {
            switch (element.code) {
              case 'DuplicateUserName':
                this.snackBar.open('Registreringen misslyckades: ', 'Finns redan en registrering med detta användarnamn',
                  {
                    duration: this.config.snackBarTiming,
                    panelClass: "snackBarErr"
                  })
                this.loading = false;
                break;
              case 'SvemoDoesNotExist':
                this.snackBar.open('Registreringen misslyckades: ', 'Detta Svemo ID finns inte registrerat som medlem',
                  {
                    duration: this.config.snackBarTiming,
                    panelClass: "snackBarErr"
                  })
                console.log('Detta Svemo ID finns inte registrerat som medlem', ' Registreringen misslyckades');
                this.loading = false;
                break;
              default:
                this.loading = false;
                this.snackBar.open('Registreringen misslyckades: ', 'Något gick fel',
                  {
                    duration: this.config.snackBarTiming,
                    panelClass: "snackBarErr"
                  })
                console.log(element.description)
                break;
            }
          })
        }
      },
      err => {
        this.snackBar.open('Registreringen misslyckades: ', 'Något gick fel',
          {
            duration: this.config.snackBarTiming,
            panelClass: "snackBarErr"
          })
        console.log(err.message)
        this.loading = false;
      },
    );
  }
}
