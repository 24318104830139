<div fxLayout="column" fxLayoutGap="0.5rem">
  <div fxLayout="row wrap" fxLayoutGap="2rem" style="background-color:orange">
    <div fxLayoutAlign="flex-start" fxFlex>
      <h3 style="margin-left:2rem; font-family:Roboto; font-weight:500"> Skapa meddelande: </h3>
    </div>
    <div fxLayoutAlign="end" fxFlex="10" fxLayoutGap="1rem">
      <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
        <mat-icon class="mw-mat-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div style="padding:1rem">


    <mat-form-field style="width:100%">
      <mat-label>Meddelande</mat-label>
      <textarea matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="10"
                [(ngModel)]="message"></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="2rem" style="margin:0.5rem">
    <button class="mw-okBtn-icon" (click)="onAddMessage()" matTooltip="Lägg till meddelande">
      <mat-icon class="mw-okIcon-icon">done</mat-icon>
    </button>
    <button class="mw-deleteBtn-icon" (click)="onCancel()" matTooltip="Ångra">
      <mat-icon class="mw-deleteIcon-icon">undo</mat-icon>
    </button>
  </div>
  </div>
