import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AuthenticationService } from '../../auth/authentication.service';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { IClub, IMember } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { AddMemberComponent } from './add-member/add-member.component';
import { MemberProfileComponent } from './member-profile/member-profile.component';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-db-members',
  templateUrl: './db-members.component.html',
  //styleUrls: ['./db-members.component.css']
  styleUrls: ['./db-members.component.scss']
})
export class DbMembersComponent implements OnInit {

  @ViewChild('dt') table!: Table;

  public members: IMember[] | undefined;

  public selectedClubs?= new FormControl();
  public testClubs: IClub[] = [];

  public broadCastMembers: any;
  public broadCastActiveUser: any;

  constructor(
    public dbService: DbService,
    //public authService: AuthenticationService,
    public activeUserService: ActiveuserService,
    private router: Router,
    public dialog: MatDialog,
    public dataRoom:DataRoomService,
  ) {

  }

  ngOnInit(): void {
    this.broadCastMembers = this.dbService.broadCastMembers.subscribe(data => {
      this.members = data;     
    })
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      var broadcastClubs = this.dbService.broadCastClubs.subscribe(clubs => {
        var userClub = clubs.find(club => club.clubModelId == data.clubModelId)
        if (userClub) {
          this.testClubs.push(userClub)
        }
      })
      broadcastClubs.unsubscribe();
    })
  }

  ngAfterViewInit(): void {
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(user => {
      //console.log(user)
      if (user.clubModelId != 0) {
        var filterClub: number[] = [];
        filterClub.push(user.clubModelId)
        this.table.filter(filterClub, 'clubModelId', 'in')
      }     
    })
  }

  ngOnDestroy(): void {
    this.broadCastActiveUser.unsubscribe();
    this.broadCastMembers.unsubscribe(); 
  }

  onClubChange(event: { value: any[]; }) {
    var filterClubIds: any[] = [];
    event.value.forEach(data => filterClubIds.push(data.clubModelId))
    this.table.filter(filterClubIds, 'clubModelId', 'in')
  }
  onMemberDelete(member: IMember) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Ta bort medlem från databasen',
        message: 'Är du säker på att du vill ta bort ' + member.firstName + ' ' +member.lastName + '?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.removeMember(member);
      }
    })
  }
  onAddMember() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onViewMember(selected_member) {
    const dialogRef = this.dialog.open(MemberProfileComponent, {
      position: { top: '1rem' },
      data: { member: selected_member},
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  filterTableSvemoId(Event: any) {
    if (null === Event)
      throw Error('Value is null')
    else
      this.table.filter(Event.target.value, 'svemoId', 'startsWith');
  }
  filterTableGlobal(Event: any) {
    if (null === Event)
      throw Error('Value is null')
    else
      this.table.filterGlobal(Event.target.value, 'contains')
  }
  filterTableFirstName(Event: any) {
    if (null === Event)
      throw Error('Value is null')
    else
      this.table.filter(Event.target.value, 'firstName', 'contains');
  }

  onFileChange($event: any) {
    console.log($event);
    const target: DataTransfer = <DataTransfer>($event.target);
    const reader: FileReader = new FileReader();

    reader.onload = (evt: any) => {
      var newMembers: any[] = [];
      var newIMembers: IMember[] = [];
      var distinctNewMembers: any[] = [];
      const binaryString: string = evt.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' })
      const workSheetName: string = workBook.SheetNames[0];
      const workSheet: XLSX.WorkSheet = workBook.Sheets[workSheetName];
      newMembers = (XLSX.utils.sheet_to_json(workSheet, { header: 1 }))

      //Find out what columns i want
      var svemoID_col: number = newMembers[0].findIndex(data => data == 'IDSvemoTA');
      var firstName_col: number = newMembers[0].findIndex(data => data == 'Förnamn');
      var lastName_col: number = newMembers[0].findIndex(data => data == 'Efternamn');
      var club_col: number = newMembers[0].findIndex(data => data == 'Klubb');
      var count = 0;
      var countExists = 0;
      var countNotExists = 0;

      newMembers.forEach(newMember => {
        if (count != 0) {
          var existsDB = this.dbService.allMembers.findIndex(data => data.svemoId == +newMember[svemoID_col])          
          if (existsDB == -1) {
            var newIMember: IMember = {
              svemoId: newMember[svemoID_col],
              isActive: false,
              firstName: newMember[firstName_col],
              lastName: newMember[lastName_col],
              clubModel: null,
              clubModelId: +this.activeUserService.user.clubModelId,
              clubMembership: newMember[club_col],
              bikeBrandModel: null,
              bikeBrandModelId: +7,
              pictureUrl: null,
              picture: [],
              confirmedTimeReadModel: null,
              intermediateTimeModel: null,
              lapTimeModel: null,
              timeReadModel: null,
            };
            newIMembers.push(newIMember);
            
            countNotExists++;
          }     
        }
        count++;
      })

      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Lägg till medlemmar',
          message: 'Är du säker på att du vill lägga till ' + countNotExists + ' medlemmar till databasen?'
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result) {
          newIMembers.forEach(data => {
            this.dbService.addMember(data, null);
          })
          this.dbService.getMembers();
        }
      })
     // distinctNewMembers = this.uniqueMembers(newIMembers);
    }
    reader.readAsBinaryString($event.target.files[0]);
  }

  public uniqueMembers(fileIMembers: any[]) {
    var members: IMember[] = [];
    var counter = 0;
    fileIMembers.forEach(member => {
      if (counter != 0) {
        var exists = members.find(mem => mem.svemoId == member.svemoId)
        console.log(exists)
        var existsDB = this.dbService.allMembers.find(mem => mem.svemoId == member.svemoId)
        console.log(existsDB)
        if (!exists && !existsDB) {
          members.push(member)
        }
      }
      counter++;
    })
    console.log(counter);
    return members;
  }

}
