import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IEvent, IMember, IMemberInCompetition } from '../../../models/interfaceModels';
import { DbService } from '../../../services/db.service';

@Component({
  selector: 'app-add-participant',
  templateUrl: './add-participant.component.html',
  styleUrls: ['./add-participant.component.css']
})
export class AddParticipantComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dbService: DbService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddParticipantComponent>) { }

  protected members: IMember[] = this.dbService.allMembers;
  protected event: IEvent = this.data.event;

  public selectedMember: IMember;

  //public membersCtrl: FormControl = new FormControl();
  public membersFilterCtrl: FormControl = new FormControl();

  public filteredMembers: ReplaySubject<IMember[]> = new ReplaySubject<IMember[]>(1);

  public allClasses: string[] = this.uniqueClasses();
  //public selectedClass: string;
  public newClass: string;

  public startNbr: number;

  addParticipant = this.fb.group({
    member: [, [Validators.required]],
    startNbr: [, [Validators.required]],
    className: [, [Validators.required]],
  })

  protected _onDestroy = new Subject<void>();

  @ViewChild('selectMember', { static: true }) singleSelect: MatSelect;

  ngOnInit(): void {
    //this.membersCtrl.setValue(this.members[0]);

    this.filteredMembers.next(this.members.slice());

    this.membersFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMembers();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredMembers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: IMember, b: IMember) => a && b && a.svemoId === b.svemoId;
      });
  }

  protected filterMembers() {
    if (!this.members) {
      return;
    }
    // get the search keyword
    let search = this.membersFilterCtrl.value;
    if (!search) {
      this.filteredMembers.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredMembers.next(
      this.members.filter(member => member.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

  protected uniqueClasses() {
    var classes: string[] = [];  
    this.data.participants.forEach(member => {
      var exists = classes.find(cla => cla == member.className)
      if (!exists) {
        classes.push(member.className)
      }
    })
    console.log(classes);
    return classes;
  }

  public addNewClass() {
    this.allClasses.push(this.newClass);
    var mem = this.addParticipant.value.member;
    var strNbr = this.addParticipant.value.startNbr;
    this.addParticipant.setValue({ member: mem, className: this.allClasses[this.allClasses.length - 1], startNbr: strNbr });
  }
  onNoClick() {
    this.dialogRef.close(null);
  }
  onAddClick() {
    if (this.addParticipant.valid) {
      var addParticipant: IMemberInCompetition = {
        id: 0,
        eventId: this.data.event.eventId,
        svemoId: this.addParticipant.value.member.svemoId,
        className: this.addParticipant.value.className,
        startNbr: this.addParticipant.value.startNbr,
        isChecked: false,
        isPresent: false,
        isFinished: false,
        totalTime: '00:00:00.000000',
        position: 0,
        points: 0,
      }
      this.dialogRef.close(addParticipant);
    }
    else
      alert('Du har inte fyllt i formuläret korrekt')
  }

  bla() {
    //console.log(this.membersCtrl.value)
    //console.log(this.allClasses)
    //console.log(this.data.participants)
    console.log(this.addParticipant)
  }
}
