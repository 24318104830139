import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClub, ILap, ILapSetUp, IRFIDReader } from '../../../../../models/interfaceModels';
import { DbService } from '../../../../../services/db.service';

export interface AddLapData {
  editClub: IClub;
  lap: ILap;
}

@Component({
  selector: 'app-add-lap',
  templateUrl: './add-lap.component.html',
  styleUrls: ['./add-lap.component.css']
})
export class AddLapComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddLapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddLapData,
    private fb: FormBuilder,
    private dbService: DbService) { }


  private broadCastRFID: any;

  public lap: ILap;
  addLapForm = this.fb.group({
    lapid: [0],
    lapName: ["", [Validators.required, Validators.minLength(4)]],
    type: ["", [Validators.required]],
    colorCode: [""],
    club: [this.data.editClub.clubModelId],
    lapSetUps: this.fb.array([])
  })

  selectedLap: ILap;
  RFIDReaders: IRFIDReader[];

  ngOnInit(): void {
    this.broadCastRFID = this.dbService.getRFIDReadersByClub(this.data.editClub.clubModelId).subscribe(
      data => {
        this.RFIDReaders = data;
      })

    if (this.data.lap) {
      this.addLapForm.controls['lapid'].setValue(this.data.lap.lapId);
      this.addLapForm.controls['lapName'].setValue(this.data.lap.lapName);
      this.addLapForm.controls['type'].setValue(this.data.lap.type);
      this.addLapForm.controls['colorCode'].setValue(this.data.lap.colorCode);
      this.addLapForm.controls['club'].setValue(this.data.lap.clubModelId);
      this.data.lap.lapSetUps.forEach(data => {
        this.addExistsingTimePost(data);
      })
    }
    console.log(this.addLapForm.value)
  }

  ngOnDestroy(): void {
    this.broadCastRFID.unsubscribe();
  }

  addNewLapSetUpFormGroup(): FormGroup {
    return this.fb.group({
      id: [0, []],
      index: [0, [Validators.required]],
      name: ['', [Validators.required]],
      postName:[''],
      readerId: ['', [Validators.required]],
      antennaId: [0, [Validators.required]],
      lapModelLapId: [0],
    });
  }
  addExistingLapSetUpFormGroup(lapSetUp:ILapSetUp): FormGroup {
    return this.fb.group({
      id: [lapSetUp.id, []],
      index: [lapSetUp.index, [Validators.required]],
      name: [lapSetUp.name, [Validators.required]],
      postName: [lapSetUp.postName],
      readerId: [lapSetUp.readerId, [Validators.required]],
      antennaId: [lapSetUp.antennaId, [Validators.required]],
      lapModelLapId: [lapSetUp.lapModelLapId],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  };

  onAddClick(): void {

    if (this.addLapForm.valid) {
      if (!this.data.lap) {
        this.lap = {
          lapId: this.addLapForm.value.lapId,
          lapName: this.addLapForm.value.lapName,
          type: this.addLapForm.value.type,
          colorCode: this.addLapForm.value.colorCode,
          clubModelId: this.data.editClub.clubModelId,
          lapSetUps: this.addLapForm.value.lapSetUps,
          autoStart:false,
        }

        //this.dbService.updateClub(this.data.club)
        this.dbService.addLap(this.lap);
        this.dialogRef.close();
      }
      else {
        this.lap = {
          lapId: this.data.lap.lapId,
          lapName: this.addLapForm.value.lapName,
          type: this.addLapForm.value.type,
          colorCode: this.addLapForm.value.colorCode,
          clubModelId: this.data.editClub.clubModelId,
          lapSetUps: this.addLapForm.value.lapSetUps,
          autoStart: this.data.lap.autoStart,
        }
        console.log('AGAIN')
        console.log(this.lap)
        this.dbService.updateLap(this.lap)
        this.dialogRef.close();
      }
      
    }
    else {
      alert('Form is not valid');
    }
      

  };

  get getLapSetUps() {
    return <FormArray>this.addLapForm.get('lapSetUps');
  };

  addTimePost(): void {
    (<FormArray>this.addLapForm.get('lapSetUps')).push(this.addNewLapSetUpFormGroup());
  }
  addExistsingTimePost(lapSetUp: ILapSetUp) {
    (<FormArray>this.addLapForm.get('lapSetUps')).push(this.addExistingLapSetUpFormGroup(lapSetUp));
  }

  onRemoveTimePost(removeIdx: number) {
    (<FormArray>this.addLapForm.get('lapSetUps')).removeAt(removeIdx);
    console.log(removeIdx);
  }

  onDebug() {
    console.log(this.addLapForm.value)
  }
}
