import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DummyComponent } from './components/dummy/dummy.component';
import { HomeComponent } from './home/home.component';
import { DbEventsComponent } from './pages/db-events/db-events.component';
import { DbInfoComponent } from './pages/db-info/db-info.component';
import { DbMembersComponent } from './pages/db-members/db-members.component';
import { LiveCompetitionComponent } from './pages/live-competition/live-competition.component';
import { LiveTrainingComponent } from './pages/live-training/live-training.component';
import { PrepareCompetitionComponent } from './pages/prepare-competition/prepare-competition.component';
import { ResultsCompetitionComponent } from './pages/results-competition/results-competition.component';
import { ResultsTrainingComponent } from './pages/results-training/results-training.component';
import { RunCompetitionComponent } from './pages/run-competition/run-competition.component';
import { RunTrainingComponent } from './pages/run-training/run-training.component';
import { UnitStatusComponent } from './pages/unit-status/unit-status.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: 'components',
    children: [
      {path:'dummy', component:DummyComponent}
    ],
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'auth',
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent },
      { path: 'resetPassword', component: ResetPasswordComponent }
    ],
  },
  {
    path: 'pages',
    children: [
      {
        path: 'admin',
        children: [
          { path: 'members', component: DbMembersComponent },
          { path: 'events', component: DbEventsComponent },
          { path: 'dbInfo', component: DbInfoComponent },
          { path: 'startTraining', component: RunTrainingComponent },
          { path: 'prepareCompetition', component: PrepareCompetitionComponent },
          { path: 'runCompetition', component: RunCompetitionComponent },
          { path: 'unitStatus', component : UnitStatusComponent},
          //{ path: 'clientStatus', component: ClientStatusComponent }
        ]
      },
      {
        path: 'results',
        children: [
          { path: 'liveTraining', component: LiveTrainingComponent },
          { path: 'resultsTraining', component: ResultsTrainingComponent },
          { path: 'liveCompetition', component: LiveCompetitionComponent },
          { path: 'resultsCompetition', component: ResultsCompetitionComponent }
        ]
      },
      //{ path: 'dummy', component: DummyComponent }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
