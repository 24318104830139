<p-table #dt [value]="series" dataKey="id" editMode="row">
  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem">
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <div class="name-header-sm">
          Serier
        </div>
      </div>

      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
          <button class="mw-addBtn-icon" (click)="onAddSerie()"  matTooltip="Lägg till serie"
                  fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template pTemplate="header">
    <tr fxFlexLayout="row" fxLayoutAlign="start center">
      <th fxFlex="20%" pSortableColumn="serieName">Serie <p-sortIcon field="serieName"></p-sortIcon></th>
      <th fxFlex="20%" matTooltip="Antal deltävlingar som räknas in i serien!" >Räknas</th>
      <th fxFlex="20%" pSortableColumn="serieName">Klubb <p-sortIcon field="serieName"></p-sortIcon></th>
      <th fxFlex fxLayoutAlign="start center">Ändra/Radera</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-serie let-editing="editing" let-ri="rowIndex">
    <tr fxLayout="row" style="height:3rem" [pEditableRow]="serie">
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="serie.serieName">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{serie.serieName}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="number" [(ngModel)]="serie.eventsToCount">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{serie.eventsToCount}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field class="ml-2">
              <mat-label>Välj klubb:</mat-label>
              <mat-select [(ngModel)]="serie.clubModel">
                <!--formControlName="bikeBrand"-->
                <mat-option>None</mat-option>
                <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{serie.clubModel.name}}
          </ng-template>
        </p-cellEditor>
      </td>

      <td fxFlex fxLayoutAlign="start center">
        <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow (click)="onRowEditInit()"
                matTooltip="Redigera serien">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="!editing" class="mw-deleteBtn-icon" (click)="onDeleteClubRFID(serie)"
                matTooltip="Ta bort serien, all data kommer suddas ut inklusive deltävlingarna">
          <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
        </button>
        <button *ngIf="editing" class="mw-okBtn-icon" pInitEditableRow pSaveEditableRow (click)="onRowEditSave(serie)"
                matTooltip="Genomför">
          <mat-icon class="mw-okIcon-icon">done</mat-icon>
        </button>
        <button *ngIf="editing" class="mw-deleteBtn-icon" pCancelEditableRow (click)="onRowEditCancel()">
          <mat-icon class="mw-deleteIcon-icon" matTooltip="Ångra">undo</mat-icon>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
