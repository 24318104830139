import { Time } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IClub, IConfirmedTimeRead, IEvent, IIntermediateTimeModel, ILap, ILapSetUp, ILapTimeModel, IMember, IMemberInCompetition, IPoint, ISerie, IStartType, ITimeRead } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from '../../services/config.service';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-run-competition',
  templateUrl: './run-competition.component.html',
  styleUrls: ['./run-competition.component.css']
})
export class RunCompetitionComponent implements OnInit {

  @ViewChild('dt') table: Table;
  @ViewChild('dtComputePoints') tableComputePoints: Table;
  

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private signalR: SignalRService,
    private activeUserService: ActiveuserService,
    private snackBar: MatSnackBar,
    private config: ConfigService,
    public dialog: MatDialog,) { }

  //Keep track of readers
  public activePis: any[];
  private broadCastPisDB: any;
  private broadCastPisSignalR: any;
  public readersInEvent: string[] = [];

  //Keep track of clubs
  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClub: IClub;

  //Keep track of active user
  public activeUser: IMember;
  private broadCastActiveUser: any;
   
  //Keep track of event
  public allEvents: IEvent[] = [];
  private broadCastallEvents: any;
  public selectedEvent: IEvent;
  public selectedEventLaps: ILap[] = [];
  public eventsInSerie: IEvent[] = [];
  public displayMembers: DisplayMember[] = [];
  private signalRBroadCast: any;
  public allClasses: string[] = [];
  public dummyallClasses: { name: string, idx: number }[] = [];
  public selectedClasses: string[] = [];
  public pointTables: IPoint[] = [];


  public series: ISerie[] = []
  private broadCastSeries: any;

  private startTypes: IStartType[] = [];
  public nbrParticipants: number = 0;
  
  public checked: boolean = true;

  //Data to create manual time
  public startNumber: number = 0;
  public time: Date = new Date();
  public selectedAntenna: number = 1;
  public selectedReader: string = 'Ej vald'

  //Display properties
  //STATUSCOLOR FOR RACE
  public colors = [{ hasStarted: 0, color: "red", text:"Ej startat" }, { hasStarted: 1, color: "green", text:"Pågår"},
    { hasStarted: 2, color: "yellow", text: "Paus" }, { hasStarted: 3, color: "black", text: "Avslutat" }]
  //STATUS COLOR FOR RACER
  public statusColors = [{ status: "Start", color: "green" }, { status: "Mål", color: "yellow" },
  { status: "Mellantid", color: "green" }, { status: "Unknown", color: "red" }]
  public threeLatestTimes: IConfirmedTimeRead[] = [];
  public infoType: number = 0;
  public maxLaps: number = 0;
  public headers: [{ header: string, field: string }];

  ngOnInit(): void {
    //DB SERVICES//
    this.threeLatestTimes = [];
    this.broadCastSeries = this.dbService.broadCastSeries.subscribe(data => {
      this.series = data;
    })
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      this.activeUser = data
      this.selectedClub = this.activeUser.clubModel
      this.dbService.getEventsByClub(data.clubModel.clubModelId);

    })
    this.broadCastallEvents = this.dbService.broadCastEvents.subscribe(data => {

      if (this.selectedClub) {
        this.allEvents = data.filter(event => event.clubModelId && event.eventType.type == 'Tävling' && event.hasStarted < 3)
        if (this.selectedEvent) {
          this.selectedEvent.hasStarted = this.allEvents.find(data => data.eventId == this.selectedEvent.eventId).hasStarted;
        }
      }
    })
    this.dbService.getStartTypes().subscribe(data => this.startTypes = data);
    this.broadCastPisDB = this.dbService.broadCastPis.subscribe(data => {
      this.activePis = data;
    })
    this.dbService.getPointTables().subscribe(data => {
      this.pointTables = data;
    })

    //SIGNALR SERVICES
    this.broadCastPisSignalR = this.signalR.broadCastPis.subscribe(data => {
      this.activePis = data;
    })

    this.dbService.getActivePis();
    this.signalR.getEventList();
    this.signalR.changedPiConnectionList();
  }
  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
    this.broadCastActiveUser.unsubscribe();
    this.broadCastSeries.unsubscribe();

    //SIGNALR SERVICES
    this.broadCastPisSignalR.unsubscribe();
    this.broadCastPisDB.unsubscribe();
    if (this.signalRBroadCast)
      this.signalRBroadCast.unsubscribe();

    this.signalR.stoplistenForEventData();
  }

  onClubChange(event) {
    this.signalR.stoplistenForEventData();
    this.threeLatestTimes = [];
    this.selectedClub = event.value;
    this.dbService.getActivePis();
    this.dbService.getEventsByClub(this.selectedClub.clubModelId);
  }

  onEventChange(event) {
    this.selectedEventLaps = [];
    this.selectedEvent.lapinEvent.forEach(data => {
      this.selectedEventLaps.push(data.lapModel);
    })
    if (this.signalRBroadCast)
      this.signalRBroadCast.unsubscribe();
    this.readersInEvent = [];
    this.signalR.stoplistenForEventData();
    this.dbService.getRegisteredParticipantsInRace(this.selectedEvent.eventId).subscribe(data => {
      this.nbrParticipants = data.length;
      this.selectedEvent.memberInCompetition = data;
      this.displayMembers = this.createDisplayMembers(data);
      this.dbService.getMembersTimesEventDirect(event.value).subscribe(data => {
        if (data) {
          //console.log(data)
          //Create headers for all laps
          data.forEach(member => {
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).confirmedTimeReadModel = member.confirmedTimeReadModel;
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).intermediateTimeModel = member.intermediateTimeModel;
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).lapTimeModel = member.lapTimeModel;
            this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId))
            this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId).nbrLaps = member.lapTimeModel.length;
            // If this member has more laps then allready added to header add new headers
            if (member.lapTimeModel.length > this.maxLaps) {
              var addedLaps = member.lapTimeModel.length;
              console.log(this.selectedEvent.lapinEvent[0].lapModel.lapName)
              if (this.selectedEvent.eventType.case != 'Heat') {
                while (this.maxLaps < member.lapTimeModel.length) {
                  if (!this.headers)
                    this.headers = [{ header: this.selectedEvent.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + this.maxLaps + ']' }];
                  else
                    this.headers.push({ header: this.selectedEvent.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + this.maxLaps + ']' });
                  this.maxLaps++;
                }
              }
              else {
                while (this.maxLaps < member.lapTimeModel.length) {
                  if (!this.headers)
                    this.headers = [{ header: 'Heat 1', field: 'lapTimeModel[' + this.maxLaps + ']' }];
                  else
                    this.headers.push({ header: 'Heat ' + this.maxLaps, field: 'lapTimeModel[' + this.maxLaps + ']' });
                  this.maxLaps++;
                }
              }
            }
          })
          this.allClasses = this.uniqueClasses();
          this.dummyallClasses = [];
          this.allClasses.forEach(data => {
            this.dummyallClasses.push({name:data, idx:0})
          })
        }
      });
      this.dbService.getClassModelsByEvent(event.value.eventId).subscribe(data => {
        this.selectedEvent.classesInEvent = data;
      })
      this.eventsInSerie = this.allEvents.filter(data => data.seriesModelId == this.selectedEvent.seriesModelId);
    })
    this.setReadersInEvent();
    this.infoType = 0;
    this.signalRBroadCast = this.signalR.broadCastnewEventData.subscribe(eventData => {
      console.log('New data from event')
      if (eventData.timeRead) {
        console.log('Add TimeRead: ' + eventData.timeRead.timeReadID)
        this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId)
          .confirmedTimeReadModel.push(eventData.timeRead)
        if (this.threeLatestTimes.length < 3)
          this.threeLatestTimes.push(eventData.timeRead)
        else {
          this.threeLatestTimes.shift();
          this.threeLatestTimes.push(eventData.timeRead)
        }
        //var memberIdx:number = this.displayMembers.findIndex(dispMember => dispMember.svemoId == eventData.timeRead.svemoId);
        //this.displayMembers[memberIdx] = this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId))
        if (this.selectedEvent.eventType.case != 'Heat' && eventData.timeRead.status == "Mål"
          && eventData.timeRead.ladIdx
          == this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className) *
          this.selectedEvent.lapinEvent.length) {

         // this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId))
          this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).lapTimeModel.length;
          this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId).isFinished = true;
          console.log('Mål')
        }
      }
      

      if (eventData.intermediateTime) {
        console.log('Add intermediate: ' + eventData.intermediateTime.timeReadID)
        this.displayMembers.find(member => member.svemoId == eventData.intermediateTime.svemoId)
          .intermediateTimeModel.push(eventData.intermediateTime)
        
      }
      if (eventData.lapTime) {
        this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId)
          .lapTimeModel.push(eventData.lapTime)
        this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId).lapTimeModel.length;
        this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == eventData.timeRead.svemoId))
        console.log('Add laptime: ' + eventData.lapTime.timeReadID)

        //Check if this is the final lap in final Heat
        if (this.selectedEvent.eventType.case == 'Heat') {
          var nbrLaps = this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className);
          var nbrHeats = this.dataRoom.getLapsToRun(this.selectedEvent.classesInEvent, this.displayMembers.find(data => data.svemoId == eventData.timeRead.svemoId).className);
          console.log('NbrLapsToRun:' +nbrLaps)
          console.log('NbrHeatsToRun:' +nbrHeats)
          console.log('NbrLapsRecorded:' + this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId).lapTimeModel.length)
          if (this.displayMembers.find(member => member.svemoId == eventData.lapTime.svemoId).lapTimeModel.length == nbrLaps * nbrHeats) {
            this.displayMembers.find(member => member.svemoId == eventData.timeRead.svemoId).isFinished = true;
          }
        }
      }
    })

    this.signalR.listenForEventData(this.selectedEvent)
  }

  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.name === o2.name : o2 === o2;
  }

  startTagCheck() {
    console.log('Starting Tag check!!');
    this.dbService.startTagCheck(this.selectedEvent);
    this.signalR.listenForNewEventCheckData(this.selectedEvent);
    this.signalR.broadCastnewEventCheckData.subscribe(data => {
      var checkMember = this.selectedEvent.memberInCompetition.find(member => member.svemoId == data.svemoId)
      checkMember.isChecked = data.isChecked;
    })
  }
  stopTagCheck() {
    console.log('Stop Tag check!!');
    this.dbService.stopTagCheck(this.selectedEvent);
    this.signalR.stoplistenForNewEventCheckData();
  };
  onStartEvent(event) {
    this.dbService.startEvent(event);
    if ((this.selectedEvent.hasStarted == 2 || this.selectedEvent.hasStarted == 0) &&
      this.startTypeName(this.selectedEvent.startTypeId).includes('knapp')) {
      var test: ITimeRead =  {
        timeReadID: 0,
        time: new Date(),
        readerId: 'Alla',
        antennaId: 0,
        svemoId: this.displayMembers[0].svemoId,
        eventId: this.selectedEvent.eventId,
      }
     // this.dbService.sendStartTime(test);
    }
  }
  onPauseEvent(event) {
    this.dbService.pauseEvent(event);
  }
  onStopEvent(event) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Stoppa Race',
        message: 'Är du säker på att du vill stoppa racet, du kommer inte kunna starta igen?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.stopEvent(event);
      }
    })
    
  }
  onGetTime() {
    this.time = new Date(); 
  }

  startTypeName(startTypeId: number) {
    return this.startTypes.find(data => data.id == startTypeId).startTypeName;
  }
  serieNamn(serieId: number) {
    return this.series.find(data => data.id == serieId).serieName;
  }

  info(infoType: number) {
    this.infoType = infoType;
  }


  getTheColor2(readerName) {
    //Check if reader exists if so check if it's active
    var status = 0

    if (this.activePis) {
      this.activePis.forEach(Pi => {
        Pi.availableReaders.forEach(reader => {
          if (reader.readerName == readerName) {
            if (!reader.isConnected)
              status = 3;
            else if (reader.isListening)
              status = 2;
            else
              status = 1;
          }
        })
      })
    }
    else
      return 'pink'
    if (status == 0)
      return 'red';
    if (status == 1)
      return 'yellow';
    if (status == 2)
      return 'green';
    if (status == 3)
      return 'orange'
    else
      return 'pink';
  }

  getStatusColor(displayMember: DisplayMember) { 
    if (displayMember.confirmedTimeReadModel.length > 0) {
      if (displayMember.isFinished)
        return "black"
      else {
        var lastRead = displayMember.confirmedTimeReadModel[displayMember.confirmedTimeReadModel.length - 1].status;
        return this.statusColors.filter(item => item.status == lastRead)[0].color
      }     
    }
    else
      return 'blue'
  }

  getTheColor(status) {
    return this.colors.filter(item => item.hasStarted === status)[0].color
  }
  getTheColorText(status) {
    return this.colors.filter(item => item.hasStarted === status)[0].text
  }
  displayLapName(LapId: number) {
    if (LapId)
      return this.selectedEvent.lapinEvent.find(lap => lap.lapId == LapId).lapModel.lapName
    else
      return 'Okännt'
  }
  setReadersInEvent() {
    this.selectedEvent.lapinEvent.forEach(data => {
      data.lapModel.lapSetUps.forEach(lapSetUp => {
        if (!this.readersInEvent.includes(lapSetUp.readerId))
          this.readersInEvent.push(lapSetUp.readerId)
      })
    })
    //console.log(this.readersInEvent)
  }
  createDisplayMembers(memberInComp: IMemberInCompetition[]): DisplayMember[]
  {
    var displayMembers: DisplayMember[] = [];

    memberInComp.forEach(member => {
      var displayMember: DisplayMember = {
        svemoId: member.svemoId,
        isActive: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).isActive,
        firstName: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).firstName,
        lastName: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).lastName,
        clubModel: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubModel,
        clubModelId: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubModelId,
        clubMembership: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubMembership,
        bikeBrandModel: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).bikeBrandModel,
        bikeBrandModelId: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).bikeBrandModelId,
        pictureUrl: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).pictureUrl,
        picture: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).picture,
        confirmedTimeReadModel: [],
        intermediateTimeModel: [],
        lapTimeModel: [],
        timeReadModel: [],
        id: member.id,
        eventId: member.eventId,
        className: member.className,
        startNbr: member.startNbr,
        isChecked: member.isChecked,
        isPresent: member.isPresent,
        isFinished: member.isFinished,
        totalTime: member.totalTime,
        position: member.position,
        nbrLaps: 0,
        points: 0,
      }
      displayMembers.push(displayMember);
    })
    return displayMembers
  }
  convertToMiniTime(timeString: string): string {
    var noMilliSec = timeString.split(".").slice(0.1);
    var noHour = noMilliSec[0].split(":");
    return noHour[1] + ":" + noHour[2];
  }

  public updateLap: ILapTimeModel;
  onRowEditInit(oldLap: ILapTimeModel) {
    this.updateLap = oldLap;
  }
  onRowEditDelete(oldLap: ILapTimeModel, member: DisplayMember) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Ta bort varvet från databasen',
        message: 'Är du säker på att du vill ta bort varvet från databasen?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.removeLapTime(oldLap).subscribe(
          (data: ILapTimeModel) => {
            if (data != null) {
              console.log(data);
              var removeIdx = member.lapTimeModel.findIndex(data => data.timeReadID == oldLap.timeReadID);
              member.lapTimeModel.splice(removeIdx, 1);
              this.snackBar.open('Lyckat!', 'Varvet togs bort från databasen', {
                duration: this.config.snackBarTiming,
                panelClass: "snackBarOk"
              });
              this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == data.svemoId))
              this.displayMembers.find(dispMember => dispMember.svemoId == data.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == data.svemoId).lapTimeModel.length;
            }
            else {
              console.log(data)
            }
          })
      }
    })

   
  }
  onRowEditSave(oldLap: ILapTimeModel, member: DisplayMember) {
    this.addingLap = false;
    if (oldLap.timeReadID == 0) {
      this.dbService.addLapTime(oldLap).subscribe(
        (data: ILapTimeModel) => {
          if (data != null) {
            console.log(data)
            oldLap = this.updateLap;
            member.lapTimeModel.find(data => data.timeReadID == 0).timeReadID = data.timeReadID;
            this.snackBar.open('Lyckat!', 'Varvet uppdaterat i databasen', {
              duration: this.config.snackBarTiming,
              panelClass: "snackBarOk"
            });
          }
          else {
            this.snackBar.open('Misslyckat!', 'Något gick fel, kunde inte uppdatera varvet', {
              duration: this.config.snackBarTiming,
              panelClass: "snackBarErr"
            })
          }
        })
    }
    else {
      this.dbService.updateLapTime(oldLap).subscribe(
        (data: ILapTimeModel) => {
          if (data != null) {
            console.log(data)
            oldLap = this.updateLap;
            this.snackBar.open('Lyckat!', 'Varvet uppdaterat i databasen', {
              duration: this.config.snackBarTiming,
              panelClass: "snackBarOk"
            });
          }
          else {
            //this.messageService.add({ severity: 'error', summary: 'Misslyckat', detail: 'Något gick fel! Varvet uppdaaterades inte' });
          }
        })
    }
    this.totalTime(this.displayMembers.find(dispMember => dispMember.svemoId == member.svemoId))
  }
  onRowEditCancel(dMember: DisplayMember) {
    console.log(this.addingLap)
    if (this.addingLap)
      dMember.lapTimeModel.pop();
    this.addingLap = false;
  }

  public addingLap: Boolean = false;
  onAddLap(dMember: DisplayMember) {
    console.log(dMember)
    this.addingLap = true;
    var newLap: ILapTimeModel = {
      timeReadID: 0,
      lapTime: '00:00:00.000000',
      svemoId: dMember.svemoId,
      eventId: dMember.eventId,
      memberModel: null,
      eventModel: null,
      lapId: 0,
      lapModel: null,
      id1: 0,
      id2: 0,
      lapNbr: 0,
    }
    console.log(newLap)
    dMember.lapTimeModel.push(newLap);
    this.displayMembers.find(dispMember => dispMember.svemoId == dMember.svemoId).nbrLaps = this.displayMembers.find(dispMember => dispMember.svemoId == dMember.svemoId).lapTimeModel.length;
    this.table.initRowEdit(newLap);
  }

  public dummyLap: ILap;
  onConsole(lapTime: IIntermediateTimeModel) {
    console.log(lapTime)
    console.log(this.selectedEvent)

    ////this.messageService.add({ severity: 'success', summary: 'Lyckat', detail: 'Varvet är uppdaterat i databasen' });
    //this.snackBar.open('everything is broken', 'Major Error', {
    //  duration: this.config.snackBarTiming,
    //  panelClass: "snackBarOk"
    //});
  }
  onConsole2(member: DisplayMember) {
    console.log(member.lapTimeModel[0].lapTime)
    console.log(new Date(member.lapTimeModel[0].lapTime))
    console.log(new Date(member.confirmedTimeReadModel[0].time))
    ////this.messageService.add({ severity: 'success', summary: 'Lyckat', detail: 'Varvet är uppdaterat i databasen' });
    //this.snackBar.open('everything is broken', 'Major Error', {
    //  duration: this.config.snackBarTiming,
    //  panelClass: "snackBarOk"
    //});
  }

  totalTime(member: DisplayMember) {
    var hours: number = 0;
    var minutes: number = 0;
    var seconds: number = 0;
    
    member.lapTimeModel.forEach((data) => {
      console.log('bla')
      hours = hours + +data.lapTime.substring(0, 2);
      minutes = minutes + +data.lapTime.substring(3, 5);
      seconds = seconds + +data.lapTime.substring(6, 8);
    })
    minutes = minutes + Math.floor(seconds / 60);
    hours = hours + Math.floor(minutes / 60);
    var finalSeconds = seconds % 60;
    var finalMinutes = minutes % 60;
    var finalHours = hours;
    member.totalTime = finalHours.toString().padStart(2, "0") + ':' + finalMinutes.toString().padStart(2, "0") + ':' + finalSeconds.toString().padStart(2, "0");
    return member;  
  }

  onStartClass(className: string) {
    var classMember = this.displayMembers.find(data => data.className == className)
    console.log(classMember)
    var test: ITimeRead = {
      timeReadID: 0,
      time: new Date(),
      readerId: 'Alla',
      antennaId: 0,
      svemoId: classMember.svemoId,
      eventId: this.selectedEvent.eventId,
    }
    this.dbService.sendStartTimeClass(test);
    console.log("Start class: " + className)
  }
  onPauseClass(className: string) {
    var classMember = this.displayMembers.find(data => data.className == className)
    console.log(classMember)
    var test: ITimeRead = {
      timeReadID: 0,
      time: new Date(),
      readerId: 'Alla',
      antennaId: 0,
      svemoId: classMember.svemoId,
      eventId: this.selectedEvent.eventId,
    }
    this.dbService.sendPauseClass(test);
    console.log("Pause class: " + className);
  }


  //Beräkna tider

  public selectedClasses: string[] = []; 

  computePosition() {
    console.log(this.selectedEvent)
    this.allClasses.forEach(eventClass => {
      
      var i: number = 1;
      this.displayMembers.filter(data => data.className == eventClass).sort((a, b) => {
        return b.nbrLaps - a.nbrLaps || this.timeToSeconds(a.totalTime) - this.timeToSeconds(b.totalTime);
      }).forEach(member => {
        if (this.timeToSeconds(member.totalTime) != 0) {
          member.position = i;
          i++;
          member.points = this.pointTables.find(
            data => data.pointName === this.getPointTableName(member.className)
              && data.position === member.position).points;
        }
        else
          member.position = null;
      })
    })
    //var i:number = 1;
    //this.displayMembers.filter(data => data.className == "Motion").sort((a, b) => {
    //  return b.nbrLaps - a.nbrLaps || this.timeToSeconds(a.totalTime) - this.timeToSeconds(b.totalTime);
    //}).forEach(member => {
    //  member.position = i;
    //  i++;
    //})
  }

  timeToSeconds(stringTime: string): number {
    if (stringTime) {
      var hours: number = 0;
      var minutes: number = 0;
      var seconds: number = 0;

      hours = +stringTime.substring(0, 2);
      minutes = +stringTime.substring(3, 5);
      seconds = +stringTime.substring(6, 8);

      return +(hours * 3600 + minutes * 60 + seconds);
    }
    else
      return +0;
    
  }
   

  protected uniqueClasses() {
    var classes: string[] = [];
    this.displayMembers.forEach(member => {
      var exists = classes.find(cla => cla == member.className)
      if (!exists) {
        classes.push(member.className)
      }
    })
    console.log(classes);
    return classes;
  }

  public getPointTableName(className: string) {
    var pointId = this.selectedEvent.classesInEvent.find(data => data.className === className).pointId; 
    return this.pointTables.find(data => data.pointId === pointId).pointName;
  }

  exportPdf() {
    const doc = new jsPDF();

    this.allClasses.forEach(className => {
      var rows = [];
      doc.text(className, 14, 10);

      
      var cols = ['Pos', 'Nr.', 'Namn', 'Varv']
      this.headers.forEach(header => {
        cols.push(header.header);
      })

      cols.push('Total Tid')
      cols.push('Poäng')

      this.displayMembers.filter(data => data.className == className).sort((a, b) => {
        if (a.position === null || a.position === 0)
          return 1;
        return a.position - b.position;
        }).forEach(member => {
          var row = [member.position, member.startNbr, member.firstName+' '+ member.lastName, member.nbrLaps]
          var idx = 0;
          this.headers.forEach(header => {
            if (member.lapTimeModel[idx]) {
              row.push(member.lapTimeModel[idx].lapTime.substring(3, 8));             
            }
            else {
              row.push('---:---');
            }
            idx++;
          })
          row.push(member.totalTime.substring(0, 8))
          row.push(member.points);
        rows.push(row);
      })
      autoTable(doc, {
         head: [cols],
        body: rows
      })

      doc.addPage();

    })
    doc.save('table.pdf')
  }

  writeToDB() {
    
    this.displayMembers.forEach(displayMember => {
      var memberInCompetition: IMemberInCompetition = this.createMemberInCompetition(displayMember);
      this.dbService.putMemberInCompetition(memberInCompetition);
    })
    
    //  var memberInCompetition: IMemberInCompetition = this.displayMembers[0];
    //  this.dbService.putMemberInCompetition(memberInCompetition);

  }

  public createMemberInCompetition(displayMember: DisplayMember) {
    var memberInCompetition: IMemberInCompetition = {
      id : displayMember.id,
      eventId: displayMember.eventId,
      svemoId :displayMember.svemoId,
      className : displayMember.className,
      startNbr : displayMember.startNbr,
      isChecked : displayMember.isChecked,
      isPresent : displayMember.isPresent,
      isFinished : displayMember.isFinished,
      totalTime : displayMember.totalTime,
      position: displayMember.position,
      points: displayMember.points,
    }
    if (memberInCompetition.points == null)
      memberInCompetition.points = 0;
    if (memberInCompetition.position == null)
      memberInCompetition.position = 0;

    return memberInCompetition;
  }
}


export interface DisplayMember extends IMemberInCompetition, IMember {
  nbrLaps: number;

}

