import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { User } from '../models/user';
import { ConfigService } from '../services/config.service';
import { IMember, ResetPasswordDto } from '../models/interfaceModels';

 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private tempUser: User | undefined;

  constructor(private fb: FormBuilder, private http: HttpClient, private config: ConfigService) { }

  private configUrl = this.config.configUrl;
  private resetUrl = this.config.resetUrl;

  register(user: User) {
    console.log(user)
    return this.http.post(this.configUrl +'Authentication/Register', user);
  }

  login(user: User) {
    console.log(user)
    return this.http.post(this.configUrl +'Authentication/Login', user);
  }

  //getUserProfile(user: User) {
  getUserProfile(user: IMember) {
    return this.http.post(this.configUrl +'Authentication/GetUserProfileDB', user);
  }
   
  getSvemoID(userID: string) {
    this.tempUser = new User('', userID, '', '', '', '', '', '')
    //alert(userID);
    return this.http.post(this.configUrl +'Authentication/GetSvemoID', this.tempUser);
  }

    

  forgotPassword(email: string) {
    console.log(email);
    var tempUser = new forgotPassWordUser('', email,'','','',this.resetUrl,'')
    return this.http.post(this.configUrl + 'Authentication/ForgotPassword', tempUser);
  }

  public resetPassword = (body: ResetPasswordDto) => {
    return this.http.post(this.configUrl + 'Authentication/ResetPassword', body);
  }

  //roleMatch(allowedRoles: any[]): Boolean {
  //  var isMatch = false;
  ////  if (localStorage.getItem('token') != null) {
  //    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
  //    var userRole = payLoad.role;
  //    allowedRoles.forEach(element => {
  //      if (userRole == element) {
  //        isMatch = true;
  //        return false;
  //      }
  //    }); 
  //  }
  //  return isMatch;
  //}
}

export class forgotPassWordUser {
  constructor(
  public username: string,
  public email: string,
  public password: string,
  public firstName: string,
  public lastName: string,
  public role: string,
  public token: string,
  ) { }
}
