<div fxLayout="column" style="padding:1rem; margin:0rem">
  <div fxLayout="row" style="width:100%">


    <div fxLayoutAlign="start center" fxFlex="90">
      <h3 fxLayout="start center" mat-dialog-title>Skapa nytt varv på {{data.editClub.name}} </h3>
    </div>
    <div fxLayoutAlign="end start" fxFlex>
      <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
        <mat-icon class="mw-mat-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
    <div fxLayout="start start" fxFlex="100">
      <form [formGroup]="addLapForm" (ngSubmit)="onAddClick()">
        <div fxLayout="column" fxFlex="100">

          <div fxLayout="row" fxFlex="100" fxLayout="start center">
            <h4 style="font-family: Roboto, Helvetica Neue, sans-serif;">Varv info:</h4>
          </div>

          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="1rem">
            <mat-form-field>
              <input matInput placeholder="Namn:" formControlName="lapName">
              <mat-error *ngIf="addLapForm.controls['lapName'].hasError('required')">
                Varv namn <strong>måste anges</strong>
              </mat-error>
              <mat-error *ngIf="addLapForm.controls['lapName'].hasError('minlength')">
                Varv namn <strong>måste</strong> ha minst 4 bokstäver
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Välj typ</mat-label>
              <mat-select formControlName="type">
                <mat-option>None</mat-option>
                <mat-option value="Träning">Träning</mat-option>
                <mat-option value="Tävling">Tävling</mat-option>
              </mat-select>
              <mat-error *ngIf="addLapForm.controls['type'].hasError('required')">
                Varv typ <strong>måste </strong>anges
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="100">

            <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <h4>Tid-stationer:</h4>
              <button type="button" class="mw-addBtn-icon" (click)="addTimePost()" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
            </div>

            <div formArrayName="lapSetUps" *ngFor="let lapSetUp of getLapSetUps.controls; let i = index">
              <div [formGroupName]="i">
                <div class="form-group">
                  <div fxLayout="row" fxLayoutGap="1rem" fxLayout.lt-sm="column">

                    <mat-form-field class="ml-4">
                      <mat-label>Välj position:</mat-label>
                      <mat-select formControlName="name">
                        <mat-option>None</mat-option>
                        <mat-option value="Start">Start</mat-option>
                        <mat-option value="Mellantid">Mellantid</mat-option>
                        <mat-option value="Mål">Mål</mat-option>
                      </mat-select>
                      <mat-error *ngIf="addLapForm.controls['type'].hasError('required')">
                        Position <strong>måste </strong>anges
                      </mat-error>

                    </mat-form-field>
                    <mat-form-field class="ml-4">
                      <mat-label>Välj läsare:</mat-label>
                      <mat-select formControlName="readerId">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let reader of RFIDReaders" value="{{reader.readerName}}">
                          {{reader.readerName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="ml-4">
                      <input matInput placeholder="Antenn:" type="number" formControlName="antennaId">
                      <!--<mat-error *ngIf="addLapForm.lapSetUps.controls['readerId'].hasError('required')">
                        Varv typ <strong>måste </strong>anges
                      </mat-error>-->
                    </mat-form-field>

                    <button style="width:3rem" class="mw-deleteBtn-icon" (click)="onRemoveTimePost(i)">
                      <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onAddClick()">Ok</button>
      <button mat-button (click)="onNoClick()">Ångra</button>
      <button mat-button (click)="onDebug()">sadasd</button>
    </div>

  </div>
