import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
//import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';

import { IClass, IEvent, IEventType, IMember, IMemberInCompetition, IPoint, ISerie, IStartType } from '../../models/interfaceModels';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { Papa } from 'ngx-papaparse';
import { AddParticipantComponent } from './add-participant/add-participant.component';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-prepare-competition',
  templateUrl: './prepare-competition.component.html',
  styleUrls: ['./prepare-competition.component.css']
})

export class PrepareCompetitionComponent implements OnInit {


  @ViewChild('dt') table: Table;
  //public grid: GridComponent;
  //@ViewChild('participantGridNotRegistered')
  //public notRegisteredgrid: GridComponent;

  //public registeredparticipants: IMember[];
  public registeredparticipantsInCompetition: IMemberInCompetition[] = [];
  public notregisteredparticipants: IMember[] = [];

  public events: IEvent[] = [];
  private broadCastEvents: any;
  public selectedEvent: IEvent;

  public series: ISerie[] = [];
  private broadCastSeries: any;
  public selectedSerie: ISerie;

  public availableClasses: IClass[] = [];

  public members: IMember[] = [];
  private broadCastMembers: any;

  public pointTables: IPoint[] = [];
  public selectedPointTable: IPoint;

  public heatPointTables: IPoint[] = [];
  public selectedHeatPointTable: IPoint;
  public ignoreFirstStartPost: Boolean = false;

  public startTypes: IStartType[] = [];
  public selectedStartType: IStartType;

  public eventTypes: IEventType[] = [];
  private broadCastEventTypes: any;

  private addNewParameters: boolean = true;

  constructor(private dbService: DbService,
    private fb: FormBuilder,
    public dataRoom: DataRoomService,
    private papa: Papa,
    public dialog: MatDialog,
    //private csvParser:NgxCsvParser,
  ) { }

  ngOnInit(): void {
    this.broadCastEvents = this.dbService.broadCastEvents.subscribe(data => {
      this.events = data.filter(data => data.eventType.type == "Tävling");
    });
    this.broadCastSeries = this.dbService.broadCastSeries.subscribe(data => {
      if (this.selectedEvent) {
        this.series = data.filter(data => data.clubModelId == this.selectedEvent.clubModelId)
        if (this.selectedEvent.seriesModelId > 0)
          this.selectedSerie = this.series.find(data => data.id == this.selectedEvent.seriesModelId);
      }
    })
    this.broadCastMembers = this.dbService.broadCastMembers.subscribe(data => {
      this.members = data;
    });
    this.broadCastEventTypes = this.dbService.broadCastEventTypes.subscribe(data => {
      this.eventTypes = data.filter(eventType => eventType.type == 'Tävling');
    })
    this.dbService.getEventTypes();
    this.availableClasses = [];
  }

  ngOnDestroy(): void {
    this.broadCastEvents.unsubscribe();
    this.broadCastSeries.unsubscribe();
    this.broadCastMembers.unsubscribe();
    this.broadCastEventTypes.unsubscribe();
  }

  selectEvent($event: any) {
    this.dbService.getSeries();
    // TODO!
    // Here we should get pointtables by Club!
    this.dbService.getPointTables().subscribe(data => {
      this.pointTables = data.filter(
        (pTable, i, arr) => arr.findIndex(t => t.pointName === pTable.pointName) === i)
    })
    this.dbService.getHeatPointTables().subscribe(data => {
      this.heatPointTables = data.filter(
        (pTable, i, arr) => arr.findIndex(t => t.pointName === pTable.pointName) === i)
    })
    this.dbService.getStartTypes().subscribe(data => {
      this.startTypes = data;
      if (this.selectedEvent.startTypeId > 0) {
        this.selectedStartType = this.startTypes.find(serie => serie.id == this.selectedEvent.startTypeId); 
      }
        
    })

    this.dbService.getRegisteredParticipantsInRace(this.selectedEvent.eventId).subscribe(data => {
      this.registeredparticipantsInCompetition = data;
      if (data.length > 0) {    
        this.dbService.getClassModelsByEvent(this.selectedEvent.eventId).subscribe(data2 => {
          this.availableClasses = data2
        })
      }
    })
  }

  onFileInput($event: any) {
    this.registeredparticipantsInCompetition = [];
    this.notregisteredparticipants = [];
    this.availableClasses = [];
    const fileList = $event.srcElement.files;
    this.parseCsvFile(fileList[0]);
  }

  //getParticipants() {

  //}

  parseCsvFile(file) {
    this.papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: 'greedy',
      worker: true,
      encoding: 'Cp1252',
      //chunk: this.papaParseChunk,
      complete: (result) => {
        //console.log('Parsed: ', result.data)
        result.data.forEach(racer => {
          if (this.members.findIndex(data => data.svemoId == racer.IDSvemoTA) != -1) {
            var newMemberInCompetiton: IMemberInCompetition = {
              id: 0,
              svemoId: racer.IDSvemoTA,
              className: racer.Class.split(" ")[0],
              startNbr: racer.No,
              eventId: this.selectedEvent.eventId,
              isChecked: false,
              isPresent: false,
              isFinished: false,
              totalTime: '00:00:00.000000',
              position: 0,
              points: 0,
            }
            this.registeredparticipantsInCompetition.push(newMemberInCompetiton)
          }
          else {
            var missingMember: IMember = {
              svemoId: racer.IDSvemoTA,
              isActive: true,
              firstName: racer.FirstName,
              lastName: racer.LastName,
              clubModel: null,
              clubModelId: 1,
              clubMembership: racer.Club,
              bikeBrandModel: null,
              bikeBrandModelId: 1,
              pictureUrl: "",
              picture:null,
              confirmedTimeReadModel: null,
              intermediateTimeModel: null,
              lapTimeModel: null,
              timeReadModel: null,
            }
            this.notregisteredparticipants.push(missingMember)
            var newMemberInCompetiton: IMemberInCompetition = {
              id: 0,
              svemoId: racer.IDSvemoTA,
              className: racer.Class.split(" ")[0],
              startNbr: racer.No,
              isChecked: false,
              isPresent: false,
              isFinished: false,
              eventId: this.selectedEvent.eventId,
              totalTime: '00:00:00.000000',
              position: 0,
              points: 0,
            }
            this.registeredparticipantsInCompetition.push(newMemberInCompetiton)
          }
        })
        this.retrievClasses(this.registeredparticipantsInCompetition)
      }
    });
  }

  addToMemberDB() {
    this.notregisteredparticipants.forEach(addMember => {
      this.dbService.addMember(addMember, {svemoId:addMember.svemoId, PictureUrl:null});
    })
    this.notregisteredparticipants = [];
    this.registeredparticipantsInCompetition = this.registeredparticipantsInCompetition; 
  }

  getFirstName(svemoId: number) {
    if (this.members.find(data => data.svemoId == svemoId))
      return this.members.find(data => data.svemoId == svemoId).firstName
    else
      return "Okänd"
  }
  getLastName(svemoId: number) {
    if (this.members.find(data => data.svemoId == svemoId))
      return this.members.find(data => data.svemoId == svemoId).lastName
    else
      return ""
  }
  getEventType(Event: IEvent) {
    return this.eventTypes.find(data => data.id == Event.eventTypeId).case
  }

  retrievClasses(participants: IMemberInCompetition[]) {
    this.availableClasses = [];
    var availableClasses = participants
      .map(data => data.className)
      .filter((classInCompetition, index, arr) => arr.indexOf(classInCompetition) == index)
    availableClasses.forEach(data => {
      var newClass: IClass = {
        id: 0,
        eventId: this.selectedEvent.eventId,
        className: data,
        nbrLaps: 0,
        maxTime: 0,
        pointId: 0,
        lapsInHeat: 0,
        heatPointId :0,
      }
      this.availableClasses.push(newClass)
    })
    console.log(this.availableClasses)
  }

  AddParametersToDB() {
    //If this is new added parameters
 
    this.selectedEvent.memberInCompetition = this.registeredparticipantsInCompetition;
    this.selectedEvent.startTypeId = this.selectedStartType.id;
    this.selectedEvent.seriesModelId = this.selectedSerie.id;
    this.selectedEvent.classesInEvent = this.availableClasses;
    this.dbService.updateEvent(this.selectedEvent);
  }

  addParticipant() {
    const dialogRef = this.dialog.open(AddParticipantComponent, {
      data: { event: this.selectedEvent, participants :this.registeredparticipantsInCompetition },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {
        //Check that racer is not in list allready
        if (!this.registeredparticipantsInCompetition.find(data => data.svemoId == result.svemoId)) {
          this.registeredparticipantsInCompetition.push(result)
          this.retrievClasses(this.registeredparticipantsInCompetition);
          this.table.value = this.registeredparticipantsInCompetition;
          alert('Föraren är tillagd i tävlingen')
        }
        else {
          alert('Föraren finns redan registrerad i tävlingen')
        }
      }
      else {
        alert('Ingen förare tillagd')
      }
      console.log('The dialog was closed');
    });
  }

  deleteParticipant(deleteParticipant: IMemberInCompetition) {
    var removeIndex = this.registeredparticipantsInCompetition.findIndex(data =>
      data.svemoId === deleteParticipant.svemoId);
    this.registeredparticipantsInCompetition.splice(removeIndex, 1);
    this.retrievClasses(this.registeredparticipantsInCompetition);
    this.table.value = this.registeredparticipantsInCompetition;  
  }

  displayEventCase(eventTypeId: number) : string {
    return this.eventTypes.find(eventType => eventType.id == eventTypeId).case
  }


  bla() {

  }
}
