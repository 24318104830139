import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveuserService } from '../../services/activeuser.service';
import { ConfigService } from '../../services/config.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private authService: AuthenticationService,
    private activeUser: ActiveuserService,
    private route: ActivatedRoute,
    private router: Router,
    private config: ConfigService,
    private snackBar: MatSnackBar) { }

  userForgotPassword = this.fb.group({
    email:['',[Validators.email]]
  })

  ngOnInit(): void {
  }
  onResetPassword() {
    if (this.userForgotPassword.invalid) {
      this.snackBar.open('Kunde inte skicka länk', 'Du måste ange en korrekt email adress',
        {
          duration: this.config.snackBarTiming,
          panelClass: "snackBarErr"
        })
      this.userForgotPassword.markAllAsTouched();
      return;
    }
    
    this.authService.forgotPassword(this.userForgotPassword.value.email).subscribe(
      (res: any) => {
        console.log(res)
      }
    )
  }

}
