<div fxFlex fxLayout="column" >
  <div fxFlex style="padding-left:1rem">
    <h2>Välj medlem:</h2>
  </div>

  <form [formGroup]="addParticipant" fxLayout="column">
    <!--<mat-form-field style="padding:1rem; width:20rem">
    <mat-select [formControl]="membersCtrl" placeholder="Medlem:" #selectMember>
      <mat-option>
        <ngx-mat-select-search [formControl]="membersFilterCtrl" placeholderLabel="Sök förnamn"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
        <b>{{member.svemoId}}</b>  {{member.firstName}}  {{member.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>-->

    <mat-form-field style="padding:1rem; width:20rem">
      <mat-select formControlName="member" placeholder="Medlem:" #selectMember>
        <mat-option>
          <ngx-mat-select-search [formControl]="membersFilterCtrl" placeholderLabel="Sök förnamn"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
          <b>{{member.svemoId}}</b>  {{member.firstName}}  {{member.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field style="padding:1rem; width:20rem">
      <mat-label>Start nummer:</mat-label>
      <input matInput type="number" formControlName="startNbr">
      <button mat-button *ngIf="startNbr" matSuffix mat-icon-button aria-label="Clear" (click)="startNbr=null">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field style="padding:1rem; width:20rem">
      <mat-select formControlName="className" placeholder="Klass:" #selectClass>
        <mat-option value="Annan klass">
          Annan klass
        </mat-option>

        <mat-option *ngFor="let class of allClasses" [value]="class">
          {{class}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="this.addParticipant.value.className == 'Annan klass' " style="padding:1rem; width:20rem">
      <mat-label>Klass namn:</mat-label>
      <input matInput type="text" [(ngModel)]="newClass" [ngModelOptions]="{standalone: true}">
      <button mat-button *ngIf="newClass" class="mw-cancelBtn-icon_sm" matSuffix mat-icon-button aria-label="Clear" (click)="newClass=null">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-button *ngIf="newClass" class="mw-doneBtn-icon_sm" matSuffix mat-icon-button aria-label="Clear" (click)="addNewClass()">
        <mat-icon>check</mat-icon>
      </button>
    </mat-form-field>
    <div fxLayout="row-wrap" fxLayoutGap="2rem" fxLayoutAlign="center center"
         class="div_mw" style="padding-bottom:10px; margin-bottom:20px">
      <button mat-button color="Accent" mat-raised-button (click)="onAddClick()">Lägg till</button>
      <button mat-button color="Accent" mat-raised-button (click)="onNoClick()">Ångra</button>
    </div>


  </form>

    <button (click)="bla()">Bla</button>


</div>

