import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClub, ILap, ILapSetUp, IRFIDReader } from '../../../../../models/interfaceModels';
import { DbService } from '../../../../../services/db.service';

export interface EditLapData {
  lap: ILap;
  club: IClub;
}

@Component({
  selector: 'app-edit-lap',
  templateUrl: './edit-lap.component.html',
  styleUrls: ['./edit-lap.component.css']
})

export class EditLapComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditLapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditLapData,
    private fb: FormBuilder,
    private dbService: DbService) { }

  public RFIDReaders: IRFIDReader[];
  private broadCastRFID: any;

  updateLapForm = this.fb.group({
    id: [this.data.lap.lapId],
    lapName: [this.data.lap.lapName, [Validators.required, Validators.minLength(4)]],
    type: [this.data.lap.type, [Validators.required]],
    colorCode: [this.data.lap.colorCode],
    clubRefId: [this.data.lap.clubModelId],
    lapSetUps: this.fb.array([  ])
  })

  setupLapSetUps(lapSetUps: ILapSetUp): FormGroup {
    return this.fb.group({
      id: [lapSetUps.id, []],
      index: [lapSetUps.index, [Validators.required]],
      name: [lapSetUps.name, [Validators.required]],
      readerId: [lapSetUps.readerId, [Validators.required]],
      antennaId: [lapSetUps.antennaId, [Validators.required]],
      //lapRefId: [lapSetUps.lapRefId],
    });
  }



  ngOnInit(): void {
    this.dbService.getRFIDReadersByClub(this.data.lap.clubModelId).subscribe(
      data => {
        this.RFIDReaders = data;
      })

    for (let i = 0; i < this.data.lap.lapSetUps.length; i++) {
      (<FormArray>this.updateLapForm.get('lapSetUps')).push(this.setupLapSetUps(this.data.lap.lapSetUps[i]));
    }
  }

  ngOnDestroy(): void {
    
  }


  addNewLapSetUpFormGroup(): FormGroup {
    return this.fb.group({
      lapid: [0, []],
      index: [0, [Validators.required]],
      name: ['', [Validators.required]],
      readerId: ['', [Validators.required]],
      antennaId: [0, [Validators.required]],
      lapRefId: [0],
    });
  }

  get getLapSetUps() {
    return <FormArray>this.updateLapForm.get('lapSetUps');
  };

  addTimePost(): void {
    (<FormArray>this.updateLapForm.get('lapSetUps')).push(this.addNewLapSetUpFormGroup());
  }

  onNoClick(): void {
    this.dialogRef.close();
  };

  onAddClick(): void {

    if (this.updateLapForm.valid) {

      var editlap: ILap = {
        lapId: this.data.lap.lapId,
        lapName: this.updateLapForm.value.lapName,
        type: this.updateLapForm.value.type,
        colorCode: this.updateLapForm.value.colorCode,
        clubModelId: this.data.club.clubModelId,
        lapSetUps: this.updateLapForm.value.lapSetUps,
        autoStart: this.data.lap.autoStart 
      }


      this.dbService.updateLap(editlap)

      this.dialogRef.close();
    }
    else
      alert('Form is not valid')

  };
}
