import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results-training',
  templateUrl: './results-training.component.html',
  styleUrls: ['./results-training.component.css']
})
export class ResultsTrainingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
