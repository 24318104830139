<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%" class="MatCard">
    <mat-card-header>
      <mat-card-title>
        Logga in:
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="userForgotPassword">

        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="e-mail" formControlName="email" required>
            <mat-icon matSuffix ></mat-icon>
            <mat-error *ngIf="userForgotPassword.controls['email'].hasError('email')">
              Måste ange <strong>korrekt </strong>email!
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
      <button class="mr-2 PrimaryBtn" mat-raised-button (click)="onResetPassword()">Skicka länk</button>

    </mat-card-actions>
  </mat-card>

</div>
