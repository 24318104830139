import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileInput } from 'ngx-material-file-input';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { AntennaTranslator, IAutoStart, IClub, ILap, IRFIDReader } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { DataRoomService } from '../../../services/data-room.service';
import { DbService } from '../../../services/db.service';
import { AddLapComponent } from './Dialogs/add-lap/add-lap.component';
import { AddReaderComponent } from './Dialogs/add-reader/add-reader.component';
import { EditLapComponent } from './Dialogs/edit-lap/edit-lap.component';

@Component({
  selector: 'app-club-info',
  templateUrl: './club-info.component.html',
  styleUrls: ['./club-info.component.scss']
})
export class ClubInfoComponent implements OnInit {

  public accept = '.jpg, .png'
  public multiple = false;
  public clubs: IClub[] = this.dbService.allClubs;
  public broadCastClubs: any;

  public autoStarts: IAutoStart[] = [];

  public selectedPic: FileInput;

  public addingClub: boolean = false;
  //public activeuser: IMember;
  //tokenExists;
  //userRole;

  @ViewChild('dt') table: Table;
  @ViewChild('dtRFID') tableRFID: Table;
  @ViewChild('dtLap') tableLap: Table;

  constructor(private dbService: DbService,
    private router: Router,
    public dialog: MatDialog,
    //private sanitizer: DomSanitizer,
    public dataRoom: DataRoomService,
    public activeUserService:ActiveuserService,
  ) { }

  ngOnInit(): void {
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data.filter(club => club.clubModelId > 0);
    }) 
  }

  ngOnDestroy(): void {
    this.broadCastClubs.unsubscribe();
  }

  updateClub(event:any, club: IClub) {
    club.autoStartActive = event.checked;
    this.onRowEditSave(club);
  }

  onAddClub() {
    //console.log(this.clubs)
    this.addingClub = true;
    var newClub: IClub = {
      clubModelId: 0,
      name: "",
      address: "",
      pictureUrl: "",
      picture: null,
      rfidReaders: [],
      laps: [],
      autoStartActive: false,
    }
    this.clubs.push(newClub)
    this.table.initRowEdit(newClub)
  }

  onDeleteClub(deleteClub: IClub) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Ta bort klubb från databasen',
        message: 'Är du säker på att du vill ta bort ' + deleteClub.name + '?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.removeClub(deleteClub);
      }
    })  
  }
  onRowEditInit(club: any) {

  }
  onRowEditSave(club: IClub) {
    // START TO CLEAN UP club Object
    //console.log(club)
    this.addingClub = false;
   // console.log(this.selectedPic)
    var picFile: any;
    if (this.selectedPic) {
      if (this.selectedPic.files[0].name) {
        picFile = this.selectedPic.files[0];
        club.pictureUrl = 'exists';
        club.picture = null;
      }
    }   
    else {
      picFile = null;
      club.pictureUrl = "/assets/img/profile.png"
    }
    if (club.clubModelId == 0)
      this.dbService.addClub(club, picFile);
    else
      this.dbService.updateClub(club, picFile)
  }
  onRowEditCancel(club: IClub, index: number) {
    if (this.addingClub)
      this.clubs.pop();
  }


  onAddReader(editClub: IClub) {

    var antennaTranslate: AntennaTranslator[] = [
      { rfiDantenna: +1, rfidReaderModelId: 0, id: 0, boxAntenna: +1 },
      { rfiDantenna: +2, rfidReaderModelId: 0, id: 0, boxAntenna: +1 },
      { rfiDantenna: +3, rfidReaderModelId: 0, id: 0, boxAntenna: +2 },
      { rfiDantenna: +4, rfidReaderModelId: 0, id: 0, boxAntenna: +2 }
    ]

    const dialogRef = this.dialog.open(AddReaderComponent, {
      position: { top: '1rem' },
      width: 'auto',
      maxHeight:'500px',
      //height: '500px',
      panelClass: 'custom-dialog-container',
      data: { editClub , antennaTranslate }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
    });

    //this.clubs[this.clubs.findIndex(club => club.clubModelId == editClub.clubModelId)].rfidReaders.push(newReader)
    //this.tableRFID.initRowEdit(newReader)
  }
  onEditReader(editClub: IClub, editReader:IRFIDReader) {

    var antennaTranslate: AntennaTranslator[] = editReader.antennaTranslator;

    const dialogRef = this.dialog.open(AddReaderComponent, {
      width: 'auto',
      maxHeight: '500px',
      //height: '500px',
      panelClass: 'custom-dialog-container',
      data: { editClub, editReader: editReader , antennaTranslate }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
    });

    //this.clubs[this.clubs.findIndex(club => club.clubModelId == editClub.clubModelId)].rfidReaders.push(newReader)
    //this.tableRFID.initRowEdit(newReader)
  }

  onRowEditInitRFID() {

  }
  onRowEditSaveRFID(rfidReader: IRFIDReader) {
    if (rfidReader.rfidReaderModelId == 0)
      this.dbService.addRFIDReader(rfidReader)
    else
      this.dbService.updateRFIDReader(rfidReader)
  }
  onRowEditCancelRFID(clubCancel: IClub) {
    this.dbService.getClubs();
  }
  onDeleteClubRFID(rfidReader: IRFIDReader) {
    this.dbService.removeRFIDReader(rfidReader)
  }


  onAddLap(editClub: IClub) {
    const dialogRef = this.dialog.open(AddLapComponent, {
      position: {top: '1rem'},
      width: 'auto',
      maxWidth: '90%',
      maxHeight: '60%',
      panelClass: 'custom-dialog-container',
      data: {editClub}
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
    });
  }

  updateLap(event: any, lap: ILap, club: IClub) {
    lap.autoStart = event.checked;
    this.dbService.updateLap(lap);
  }
  onRowEditInitLap(lap: ILap, editClub: IClub) {
    //const dialogRef = this.dialog.open(EditLapComponent, {
    //  width: '80%',
    //  height: '500px',
    //  panelClass: 'custom-dialog-container',
    //  data: { lap, club }
    //});
    //dialogRef.afterClosed().subscribe(result => {

    //  console.log('The dialog was closed');
    //});
    const dialogRef = this.dialog.open(AddLapComponent, {
      position: { top: '1rem' },
      width: 'auto',
      maxWidth: '90%',
      maxHeight: '60%',
      panelClass: 'custom-dialog-container',
      data: { editClub, lap }
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
    });

  }

  onRowEditCancelLap() {

  }
  onDeleteLap(lap: ILap) {
    this.dbService.removeLap(lap.lapId)
  }
}
