<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%" class="MatCard">
    <mat-card-header>
      <mat-card-title>
        Skapa nytt lösenord::
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate (ngSubmit)="resetPassword(resetPasswordForm.value)">
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Lösenord:" formControlName="password">
            <mat-icon matSuffix matTooltip="Mata in ett nytt lösenord">info_outline</mat-icon>
            <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">
              Lösenord <strong>måste anges</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <br />
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Lösenord:" formControlName="confirm">
            <mat-icon matSuffix matTooltip="Mata in ett nytt lösenord">info_outline</mat-icon>
            <mat-error *ngIf="validateControl('confirm') && hasError('confirm', 'required')">
              Lösenord <strong>måste konfirmeras</strong>
            </mat-error>
            <mat-error *ngIf="hasError('confirm', 'mustMatch')">
              Lösenorden stämmer <strong>inte</strong> överrens
            </mat-error>
          </mat-form-field>
        </div>
        <br />

        <mat-card-actions fxLayoutAlign="center center">
          <button type="submit" class="btn btn-info" [disabled]="!resetPasswordForm.valid">Submit</button>

        </mat-card-actions>

      </form>
    </mat-card-content>
  </mat-card>

</div>
