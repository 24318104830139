import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { IMember, IClub, IBikeBrand } from '../models/interfaceModels';
import { SignalRService } from './signal-r.service';
//import { SignalRService } from './signal-r.service';

 

@Injectable({
  providedIn: 'root'
})
export class ActiveuserService {


  public user: IMember = {
    svemoId: 0,
    firstName: 'Gäst',
    lastName: '',
    isActive: false,
    clubModel: <IClub>{},
    clubModelId: 0,
    clubMembership : 'Okänd',
    bikeBrandModel: <IBikeBrand>{id: 0, bikeBrand: '', pictureUrl: '', companyUrl: '',},
    bikeBrandModelId: 0,
    pictureUrl: '',
    picture: [],
    confirmedTimeReadModel: [],
    intermediateTimeModel: [],
    lapTimeModel: [],
    timeReadModel:[],
   // eventTimeReads: [],
  }

  //private messageSource = new BehaviorSubject<User>(this.user)
  private messageSource = new BehaviorSubject<IMember>(this.user)
  currentUser = this.messageSource.asObservable();

  constructor(private authService: AuthenticationService,
    private signalR: SignalRService,
    private sanitizer: DomSanitizer) { }

  //changeUser(user: User) {
  changeUser(user: IMember) {
    
    this.authService.getUserProfile(user).subscribe(
      (res: any) => {
        console.log('RETRIEVED USER')
        
        this.user = res;
        console.log('Changed user to: ', this.user.firstName)
        this.messageSource.next(this.user)
        if (this.signalR.isConnected) {
          this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
        }

      },
      err => {
        alert('Could not find user')
        this.user = {
          svemoId: 0,
          firstName: 'Gäst',
          lastName: '',
          isActive: false,
          clubModel: <IClub>{},
          clubModelId: 0,
          clubMembership: 'Okänd',
          bikeBrandModel: { id: 0, bikeBrand: '', pictureUrl: '', picture: '', companyUrl: '', },
          bikeBrandModelId: 0,
          pictureUrl: '',
          picture: [],
          confirmedTimeReadModel: [],
          intermediateTimeModel: [],
          lapTimeModel: [],
          timeReadModel: [],
        }
        console.log('Changed user to: ', this.user.firstName)
        this.messageSource.next(this.user) 
        if (this.signalR.isConnected) {
          this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
          }
        
      },
    )
  }

  activeAsGuest() {
    this.user = {
      svemoId: 0,
      firstName: 'Gäst',
      lastName: '',
      isActive: false,
      clubModel: <IClub>{},
      clubModelId: 0,
      clubMembership: 'Okänd',
      bikeBrandModel: { id: 0, bikeBrand: '', pictureUrl: '', picture: '', companyUrl: '', },
      bikeBrandModelId: 0,
      pictureUrl: '',
      picture: [],
      confirmedTimeReadModel: [],
      intermediateTimeModel: [],
      lapTimeModel: [],
      timeReadModel: [],
    }
    if (this.signalR.isConnected) {
      this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
    }
    console.log('Changed user to: ', this.user.firstName)
    this.messageSource.next(this.user)
  }

  displayProfilePicture() {
    if (this.user.picture) {
      let objectURL = 'data:image/jpeg;base64,' + this.user.picture;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return Url;
    }
    else
      return '/assets/img/profile.png'
  }

  activeUserRole(): string {
    var checkToken = localStorage.getItem('token');
    if (checkToken) {  
      var payLoad = JSON.parse(window.atob(checkToken.split('.')[1]));
      return payLoad.role;
    }
    else
      return "Guest"
  }
}
