<mat-card fxFlex="100" class="MatCard-mw">
  <mat-card-title fxLayout="row">
    <div fxFlex="80">
      Välkommen till Wäxjö MS Enduro tidtagning
    </div>
    <div fxFlex="20">
      <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
        <mat-icon class="mw-mat-close-icon">close</mat-icon>
      </button>
    </div>

  </mat-card-title>
  <mat-card-content fxLayoutAlign="center center" style="font-size:larger">
    - På denna sida kan du hitta tider från Wäxjö MS tidtagningsssytem
    <br />
    <br />
    - Om det är första gången du är här och är medlem i Wäxjö MS Enduro får du jättegärna registrera dig på siten. Ladda gärna upp en bild på dig själv!
    <br />
    <br />
    - Om du klickar på kuvertet uppe till höger kan du skicka feedback till adminsitratören och berätta vad du saknar, om något inte fungerar eller ge annan feedback!
    <br />

  </mat-card-content>
</mat-card>

