import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { IClub, IEvent, IEventMember, IMember } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { DataRoomService } from '../../../services/data-room.service';
import { DbService } from '../../../services/db.service';

@Component({
  selector: 'app-by-training',
  templateUrl: './by-training.component.html',
  styleUrls: ['./by-training.component.css']
})
export class ByTrainingComponent implements OnInit {

  @ViewChild('dt') table: Table;

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private activeUserService: ActiveuserService) { }

  public eventsWithMembers: IEventMember[] = [];
  public events: IEvent[];
  public broadCastEvents: any;

  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClubs: IClub[] = [];

  private activeUser: IMember = this.activeUserService.user; 

  maxLapsInEvent: { eventId: number, maxLaps: number }[] = [];

 

  ngOnInit(): void {
    
    this.broadCastEvents = this.dbService.broadCastEvents.subscribe(data => {
      this.eventsWithMembers = [];
      this.events = data;
      data.forEach(event => {
        this.eventsWithMembers.push(this.dataRoom.createEventMembers(event));
      })
    })
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
      this.selectedClubs.push(this.clubs.find(club => club.clubModelId == this.activeUser.clubModelId))
    })    
  }

  ngAfterViewInit() { 
    this.onClubChange(this.selectedClubs)
  }

  ngOnDestroy(): void {
    this.broadCastEvents.unsubscribe();
    this.broadCastClubs.unsubscribe();
  }

  onClubChange(clubs:IClub[]) {
    var filterClubIds = [];
    clubs.forEach(data => filterClubIds.push(data.clubModelId))
    this.table.filter(filterClubIds, 'clubModelId', 'in')
  }

  expandCommand(event: IEventMember, expanded: boolean) {
    if (!expanded && event.members == null) {
      this.dbService.getMembersTimesEventDirect(event).subscribe(data => {
        console.log(data)
        if (data) {
          //Find max laps in Event
          var maxLaps = 0;
          data.forEach(member => {
            if (member.lapTimeModel.length > maxLaps)
              maxLaps = member.lapTimeModel.length;
          })

        }
        event.members = data;
        var newLapCount = { eventId: event.eventId, maxLaps: maxLaps }
        this.maxLapsInEvent.push(newLapCount);

      })
    }
  }

  expandMemberCommand(member: IMember, expanded: boolean) {
    console.log(member)
  }

  maxEventLaps(eventId: number) {
    if (this.maxLapsInEvent.find(data => data.eventId == eventId)) {
      return this.maxLapsInEvent.find(data => data.eventId == eventId).maxLaps
    }
    else
      return 0;
  }

  displayLapName(LapId: number, event:IEventMember) {
    return event.lapinEvent.find(lap => lap.lapId == LapId).lapModel.lapName
  }

  Bla() {
    console.log(this.selectedClubs)
    console.log(this.eventsWithMembers)
  }
}
