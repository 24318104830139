import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/authentication.service';
import { IMember } from '../../models/interfaceModels';
import { MemberProfileComponent } from '../../pages/db-members/member-profile/member-profile.component';
import { ActiveuserService } from '../../services/activeuser.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  public activeuser: IMember | undefined;
  public userRole: string = "Admin";
  public tokenExists: boolean = false;

  profilePic: any;

  constructor(
    public authService: AuthenticationService,
    public activeUserService: ActiveuserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private config:ConfigService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  
  }

  onLogout() {
    this.activeUserService.activeAsGuest();
    localStorage.removeItem('token');
    this.router.navigate(['/home']);
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  showImage(userBlob: any) {
    //let objectURL = 'data:image/jpeg;base64,' + userBlob.image;
    //return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onViewMember() {
    console.log(this.activeuser)
    const dialogRef = this.dialog.open(MemberProfileComponent, {
      position: {top:'1rem'},
      data: { member: this.activeUserService.user },
      panelClass: 'test'    //'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  mailto(emailAddress: string, emailSubject: any) {
    return "mailto:" + emailAddress + "?subject=" + emailSubject
  }

  about() {
    this.snackBar.open('Time Tracker', ' Version:8.0.0 Date:21-03-29' ,
      {
        duration: this.config.snackBarTiming,
        panelClass: "snackBarOk"
      })
  }
} 
