<div fxLayout="column" fxLayoutGap="1rem" style="margin-top:1rem" fxLayoutAlign="center center">
  <div fxFlex style="background-color: rgba(209,176,84, 0.8); border-radius:1rem; padding:0rem">
    <!--<span fxFlexAlign="center"><p>Välkommen till Wäxjö MS Enduros tidtagningssida</p></span>-->
    <span fxFlexAlign="center"><p>Välkommen till TrackTime Enduro tidtagning</p></span>
  </div>
  <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="1rem" fxFlexAlign="center">

    <button class="roundLgBtn" routerLink="/pages/results/liveTraining">Live träning</button>
    <button class="roundLgBtn" routerLink="/pages/results/resultsTraining">Historik träning</button>
    <button class="roundLgBtn" routerLink="/pages/results/liveCompetition">Live tävling</button>
    <button class="roundLgBtn" routerLink="/pages/results/resultsCompetition">Historik tävling</button>

  </div>

  <div fxFlexLayout="row" fxLayoutAlign="start stretch">
    <button *ngIf="activeUserService.activeUserRole() == 'SAdmin' || activeUserService.activeUserRole() == 'Admin'" class="mw-addBtn-icon" style="background-color:aliceblue; color:black" (click)="onAddClubNews()" fxLayoutAlign="start start" matTooltip="Lägg till meddelande">
      <mat-icon class="mw-addIcon-icon">forum</mat-icon>
    </button>

    <button *ngIf="activeUserService.activeUserRole() == 'SAdmin'"
            class="mw-addBtn-icon" style="background-color:aliceblue; color:black"
            (click)="onAddSoftwareNews()" fxLayoutAlign="start start"
            matTooltip="Lägg till meddelande">
      <mat-icon class="mw-addIcon-icon">comment</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0.5rem"
       fxFlex fxFill fxLayoutAlign="space-evenly top">

    <div fxLayout="column" fxLayoutGap="0.5rem" fxFlex="40"
         fxFlex.lt-md="48" fxFlex.lt-sm="90">
      <app-info-card *ngFor="let message of messagesTrackTime"
                     [messageString]="message.message"
                     [clubModelId]="message.clubModelId"
                     [date]="message.date"
                     [svemoId]="message.svemoId"
                     [messageId]="message.messageId"
                     (onDelete)="onDeleteMessage($event)">

      </app-info-card>

    </div>
    <div fxLayout="column" fxLayoutGap="0.5rem"
         fxFlex="40" fxFlex.lt-md="48" fxFlex.lt-sm="90">
      <app-info-card *ngFor="let message of messagesClub"
                     [messageString]="message.message"
                     [clubModelId]="message.clubModelId"
                     [date]="message.date"
                     [svemoId]="message.svemoId"
                     [messageId]="message.messageId"
                     (onDelete)="onDeleteMessage($event)">

      </app-info-card>
    </div>


  </div>
</div>
