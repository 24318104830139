import { Component, OnInit } from '@angular/core';
import { IConfirmedTimeRead, IEvent, IIntermediateTimeModel, IMember } from '../../models/interfaceModels';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-live-training',
  templateUrl: './live-training.component.html',
  styleUrls: ['./live-training.component.css']
})
export class LiveTrainingComponent implements OnInit {

  constructor(private dbService: DbService,
    private signalR: SignalRService,
    public dataRoom:DataRoomService,) { }


  //Retrieved from DB
  // Will only show filtered events based on Träning and that hasStarted = 1
  public eventsInDB: IEvent[] = [];
  private broadCasteventsInDB: any;
  public selectedEvent: IEvent = null;

  public confirmedTimeReadsInEvent: IConfirmedTimeRead[] = [];
  // USED for Option 1
  private broadCastconfirmedTimeReadsInEvent: any;
  public LapTimesInEvent: IIntermediateTimeModel[] = [];

  private signalRBroadCast: any;


  //Filtered inside app
  public membersInEvent: IMember[] = [];
  private broadCastMembersInEvent: any;


  //Styling props
  public statusColors = [{ status: "Start", color: "green" }, { status: "Mål", color: "black" },
    { status: "Mellantid", color: "green" }, { status: "Unknown", color: "red" }]
  public maxLaps: number = 0;
  public headers: [{ header: string , field:string }];
  public nameColumns: [{ header: string, field: string }] = [{ header: 'Name', field:'firstName'}]

  ngOnInit(): void {
    //TODO | Make sure getEvents is called when event status changes at server side!
    this.broadCasteventsInDB = this.dbService.broadCastEvents.subscribe(data => {
      this.eventsInDB = data.filter(event => event.hasStarted == 1 && event.eventType.type == 'Träning');
    });

  }
   
  ngOnDestroy(): void {
    this.broadCasteventsInDB.unsubscribe();

    this.signalR.stoplistenForEventData();
    if (this.signalRBroadCast)
      this.signalRBroadCast.unsubscribe();
  }

  onSelectedEvent(event) {
    //MAKE SURE NO SIGNALR EXISTS THAT IS LISTENING TO EVENT DATA
    this.signalR.stoplistenForEventData();
    //Option 1
    this.dbService.getMembersTimesEventDirect(event.value).subscribe(data => {
      if (data) {
        //Create headers for all laps
        data.forEach(member => {
          // If this member has more laps then allready added to header add new headers
          if (member.lapTimeModel.length > this.maxLaps) {
            var addedLaps = member.lapTimeModel.length;
            while (this.maxLaps < member.lapTimeModel.length) {
              if (!this.headers)
                this.headers = [{ header: 'Lap ' + (this.maxLaps + 1), field: 'lapTimeModel'}];
              else
                this.headers.push({ header: 'Lap ' + (this.maxLaps + 1), field: 'lapTimeModel' });
              this.maxLaps++;
            }
          }
        })
      }
      this.membersInEvent = data;
      console.log('All member times are updated')
      console.log(this.membersInEvent);    
    })
    
 
    this.signalRBroadCast = this.signalR.broadCastnewEventData.subscribe(eventData => {
      console.log('New data from event')
      //console.log(eventData);
      //console.log(this.membersInEvent)

      if (eventData.newMember) {
        if (!this.membersInEvent.find(member => member.svemoId == eventData.newMember.svemoId)) {
          console.log('Add new member')
          this.membersInEvent.push(this.dbService.allMembers.find(member => member.svemoId == eventData.newMember.svemoId))
          var newMember = this.membersInEvent.find(member => member.svemoId == eventData.newMember.svemoId);
          newMember.confirmedTimeReadModel = [];
          newMember.intermediateTimeModel = [];
          newMember.lapTimeModel = [];
        }       
      }
      if (eventData.timeRead) {
        console.log('Add TimeRead: ' + eventData.timeRead.timeReadID)
        console.log(this.membersInEvent.find(member => member.svemoId == eventData.timeRead.svemoId))
        this.membersInEvent.find(member => member.svemoId == eventData.timeRead.svemoId)
          .confirmedTimeReadModel.push(eventData.timeRead)
      }

      if (eventData.intermediateTime) {
        console.log('Add intermediate: ' + eventData.intermediateTime.timeReadID)
        this.membersInEvent.find(member => member.svemoId == eventData.intermediateTime.svemoId)
          .intermediateTimeModel.push(eventData.intermediateTime)
      }
      if (eventData.lapTime) {
        this.membersInEvent.find(member => member.svemoId == eventData.lapTime.svemoId)
          .lapTimeModel.push(eventData.lapTime)
        console.log('Add laptime: ' + eventData.lapTime.timeReadID)
      }
    })

    this.signalR.listenForEventData(this.selectedEvent)
  }


  convertToMiniTime(timeString: string): string {
 
    if (timeString.charAt(8) == ".") {
      var noMilliSec = timeString.split(".").slice(0.1);
      var noHour = noMilliSec[0].split(":");
      var hour = noHour[0];
      var minutes = noHour[1];
      var seconds = noHour[2];
      var milliSecond = noMilliSec[1].charAt(0);
    }
    else {
      //console.log(timeString)
      var splitTime = timeString.split(":").slice();
      var hour = splitTime[0];
      var minutes = splitTime[1];
      var seconds = splitTime[2];
      var milliSecond = splitTime[3].charAt(4)
    }  
    return minutes + ":" + seconds + "." + milliSecond;
  }

  getStatusColor(timeReads: IConfirmedTimeRead[]) {
    if (timeReads.length > 0) {
      var lastRead = timeReads[timeReads.length - 1].status;
      return this.statusColors.filter(item => item.status == lastRead)[0].color
    }
    else
      return 'pink'
  }

  displayLapName(LapId: number) {

    return this.selectedEvent.lapinEvent.find(lap => lap.lapId == LapId).lapModel.lapName
    //return 'bla'
  }

  bla() {
    console.log(this.membersInEvent[0].lapTimeModel)
  }

  onExpandMember(member: IMember) {
    console.log(member)    
  }
}

//TODO | Expand member on live training to se timereads and intermediates!
