<div fxLayout="column" class="infoCard" >
  <div  fxLayout="row" fxLayoutAlign="start top">
    <div fxFlex="8rem" fxLayout="column"
         fxLayoutAlign="start start" fxLayoutGap="0.25rem">
      <img *ngIf="clubModelId == -1" style="width:6rem" src="/assets/img/test1.svg" />
      <img *ngIf="clubModelId != -1" class="img" width="50" height="50"
           [src]='dataRoom.showClubPic(clubModelId)' style="border-radius:20%" />
 
      <span style="font-size:0.8rem">{{date | date}}</span>
      <span style="font-size:0.8rem">{{this.dataRoom.showMemberName(svemoId)}}</span>
    </div>

    <div fxFlex fxLayout="column" >
      <div fxLayout="row" fxLayoutAlign="space-between top">
        <h3 style="margin:0;padding:0">Meddelande:</h3>
        <button *ngIf="activeUserService.activeUserRole() == 'SAdmin' ||
                (activeUserService.user.clubModelId == clubModelId && activeUserService.activeUserRole() == 'Admin')"
                style="margin:0;padding:0;background-color:aliceblue; color:black"
                class="mw-doneBtn-icon_sm"
                (click)="onDeleteMessage(messageId)"
                 fxLayoutAlign="start start" matTooltip="Ta bort meddelande">
          <mat-icon >delete</mat-icon>
        </button>
      </div>
      
      <span>{{messageString}}</span>
    </div>
  </div>


</div>
