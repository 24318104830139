import { Component, OnInit, ViewChild } from '@angular/core';
import { FileInput } from 'ngx-material-file-input';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { IBikeBrand } from '../../../models/interfaceModels';
import { DataRoomService } from '../../../services/data-room.service';
import { DbService } from '../../../services/db.service';
 
@Component({
  selector: 'app-mc-info',
  templateUrl: './mc-info.component.html',
  styleUrls: ['./mc-info.component.scss']
})
export class McInfoComponent implements OnInit {

  @ViewChild('dt') table: Table;

  public mcBrands: IBikeBrand[];
  private broadCastmcBrand: any;

  public accept = '.jpg, .png'
  public multiple = false;

  public selectedPic: FileInput;

  constructor(private dbService: DbService,
    public dataRoom:DataRoomService,) { }

  ngOnInit(): void {

    this.broadCastmcBrand = this.dbService.broadCastBikeBrands.subscribe(data => {
      this.mcBrands = data;
    });

    this.dbService.getBikeBrands();
  }

  onRowEditInit() {

  };

  ngOnDestroy(): void {
    this.broadCastmcBrand.unsubscribe();
  }

  onAddBikeBrand() {
    var newBikeBrand: IBikeBrand = {
      id: 0,
      bikeBrand: "",
      pictureUrl: "",
      picture: null,
      companyUrl: "",
    }
    this.mcBrands.push(newBikeBrand)
    this.table.initRowEdit(newBikeBrand)
  }

  onRowEditSave(bikeBrand: IBikeBrand) {
    // START TO CLEAN UP club Object
    var picFile: any;

    if (this.selectedPic) {
      if (this.selectedPic.files[0].name) {
        picFile = this.selectedPic.files[0];
        bikeBrand.pictureUrl = 'exists';
        bikeBrand.picture = null;
      }
    }
    else {
      picFile = null;
      bikeBrand.pictureUrl = "/assets/img/profile.png"
    }

    if (bikeBrand.id == 0)
      this.dbService.addBikeBrand(bikeBrand, picFile);
    else
      this.dbService.updateBikeBrand(bikeBrand, picFile)
    this.selectedPic = null;
  };

  onRowEditCancel() {

  };
  onDeletemcBrand(bikeBrand: IBikeBrand) {
    this.dbService.removeBikeBrand(bikeBrand)
  };
}
