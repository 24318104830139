<mat-card fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" style="margin-top:1rem; padding:1rem">


  <div fxLayout="column" fxLayoutalign="start center" style="margin:0rem">
    <div fxLayout="row-wrap" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
      <h3 style="margin-left:2rem; font-family:Roboto; font-weight:500">Förbered tävling: </h3>
      <div fxFlex="50">
        <mat-form-field appearance="fill" style="width:10rem; height:2rem; margin-left:1rem">
          <mat-label>Välj Event</mat-label>
          <mat-select [(value)]="selectedEvent" (selectionChange)="selectEvent($event)">
            <mat-option *ngFor="let event of events" [value]="event">
              {{event.eventName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <mat-card-content *ngIf="selectedEvent">

      <mat-tab-group style="background-color:whitesmoke; margin-top:1rem;">
        <!--INFO TAB-->
        <mat-tab label="Info">
          <div fxFlexLayout="row-wrap" fxLayoutGap="1rem" style="    background-color: white; padding-top: 1rem;">
            <mat-form-field appearance="fill" class="ml-2" style="width:10rem">
              <mat-label>Datum:</mat-label>
              <input matInput value="{{selectedEvent.eventDate}}" disabled />
            </mat-form-field>
            <mat-form-field appearance="fill" class="ml-2" style="width:10rem">
              <mat-label>Klubb:</mat-label>
              <input matInput [value]=dataRoom.showClubName(selectedEvent.clubModelId) disabled />
            </mat-form-field>
            <mat-form-field appearance="fill" class="ml-2" style="width:10rem">
              <mat-label>Tävlings typ:</mat-label>
              <input matInput [value]=displayEventCase(selectedEvent.eventTypeId) disabled />
            </mat-form-field>
          </div>
        </mat-tab>

        <!--PARTICIPANT TAG-->
        <mat-tab label="Deltagare">
          <div fxLayout="row-wrap" fxLayout.sm="column" fxLayoutGap="1rem" style="background-color:white; padding-top:1rem; height:650px">
            <div fxFlex>
              <p-table #dt [value]="registeredparticipantsInCompetition" dataKey="id"
                       [scrollable]="true" scrollHeight="400px">
                <ng-template pTemplate="header">
                  <div fxLayout="row" class="mw_sub_table_header">
                    <div fxLayoutAlign="start center" fxFlex.gt-xs="40" fxFlex.lt-sm="25" style="margin-left:1rem">
                      <div class="name-header-sm test">
                        Deltagare:
                      </div>
                    </div>
                    <div fxLayoutAlign="end center" fxFlex style="height:3rem">
                      <button class="mw-addBtn-icon" (click)="fileInput.click()" fxLayoutAlign="start start" matTooltip="Öppna Svemo startlista">
                        <mat-icon class="mw-addIcon-icon">library_add</mat-icon>        
                        <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
                      </button>
                    </div>
                    <div fxLayoutAlign="end center" fxFlex style="height:3rem">
                      <button class="mw-addBtn-icon" (click)="addParticipant()" fxLayoutAlign="start start" matTooltip="Lägg till enskild förare">
                        <mat-icon class="mw-addIcon-icon">add</mat-icon>
                      </button>
                    </div>
                  </div>
                  <tr fxFlexLayout="row" fxLayoutAlign="start center">
                    <th fxFlex="5rem" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
                    <th fxFlex="16rem" >Namn</th>
                    <th fxFlex="7rem" pSortableColumn="className">Klass<p-sortIcon field="className"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registeredparticipantInCompetition>
                  <tr fxLayout="row" style="height:2rem">
                    <td fxFlex="5rem" fxLayout fxLayoutAlign="center center">
                      {{registeredparticipantInCompetition.startNbr}}
                    </td>
                    <td fxFlex="16rem" fxLayout fxLayoutAlign="start center">
                      {{getFirstName(registeredparticipantInCompetition.svemoId)}} {{getLastName(registeredparticipantInCompetition.svemoId)}}
                    </td>
                    <td fxFlex="7rem" fxLayout fxLayoutAlign="start center">
                      {{registeredparticipantInCompetition.className}}
                    </td>
                    <td fxFlex fxLayoutAlign="end center">
                      <button mat-button class="mw-cancelBtn-icon_sm" matSuffix mat-icon-button aria-label="Clear"
                              (click)="deleteParticipant(registeredparticipantInCompetition)" matTooltip="Ta bort deltagare från tävlingen">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
            <div fxFlex>
              <p-table #dt [value]="notregisteredparticipants" dataKey="id"
                       [scrollable]="true" scrollHeight="400px">
                <!--<ng-template pTemplate="caption">
                  <div fxLayout="row wrap" fxLayoutGap="1rem">
                    <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
                      <div class="name-header-sm test">
                        Ej i DB:
                      </div>
                    </div>
                  </div>
                </ng-template>-->
                <ng-template pTemplate="header">
                  <div fxLayout="row" class="mw_sub_table_header">
                    <div fxLayoutAlign="start center" fxFlex.gt-xs="40" fxFlex.lt-sm="25" style="margin-left:1rem">
                      <div class="name-header-sm test">
                        Ej i DB:
                      </div>
                    </div>
                      <div fxLayoutAlign="end center" fxFlex style="height:3rem">
                        <button *ngIf="notregisteredparticipants.length > 0" class="mw-addBtn-icon" (click)="addToMemberDB()" fxLayoutAlign="start start" matTooltip="Uppdatera databasen">
                          <mat-icon class="mw-addIcon-icon">backup</mat-icon>
                        </button>                
                    </div>
                  </div>
                  <tr fxFlexLayout="row" fxLayoutAlign="start center">
                    <th fxFlex="5rem" pSortableColumn="svemoId">#<p-sortIcon field="svemoId"></p-sortIcon></th>
                    <th fxFlex="7rem" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
                    <th fxFlex="7rem" pSortableColumn="lastName">Klass<p-sortIcon field="lastName"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-notregisteredparticipants>
                  <tr fxLayout="row" style="height:2rem">
                    <td fxFlex="5rem" fxLayout fxLayoutAlign="center center">
                      {{notregisteredparticipants.svemoId}}
                    </td>
                    <td fxFlex="7rem" fxLayout fxLayoutAlign="start center">
                      {{notregisteredparticipants.firstName}}
                    </td>
                    <td fxFlex="7rem" fxLayout fxLayoutAlign="start center">
                      {{notregisteredparticipants.lastName}}
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
          </div>
        </mat-tab>

        <!-- PARAMETERS TAG -->
        <mat-tab fxLayout label="Parametrar ({{displayEventCase(selectedEvent.eventTypeId)}} lopp)" *ngIf="registeredparticipantsInCompetition.length > 0">

          <!--IF Continuous or Lap race-->
          <div fxLayout="column"  style="background-color:white; padding-top:1rem; padding-right:1rem;"
               *ngIf="displayEventCase(selectedEvent.eventTypeId) == 'Kontinuerlig' || displayEventCase(selectedEvent.eventTypeId) == 'Varv'">
            <mat-card-header>
              <mat-card-title>Definiera klass info:</mat-card-title>
            </mat-card-header>

            <div fxLayoutGap="1rem" *ngFor="let availableClass of availableClasses; let i = index">
              <mat-form-field fxFlex="8rem" appearance="fill">
                <mat-label>Antal varv: {{availableClasses[i].className}}</mat-label>
                <input matInput type="number" [(ngModel)]="availableClasses[i].nbrLaps">
              </mat-form-field>
              <mat-form-field fxFlex="8rem" appearance="fill">
                <mat-label>Max tid: {{availableClasses[i].className}} (h)</mat-label>
                <input matInput [(ngModel)]="availableClasses[i].maxTime" type="number" />
              </mat-form-field>
              <mat-form-field fxFlex="14rem" appearance="fill">
                <mat-label>Poäng tabell:  {{availableClasses[i].className}} </mat-label>
                <mat-select [(value)]="availableClasses[i].pointId">
                  <mat-option *ngFor="let pointTable of pointTables" [value]="pointTable.pointId">
                    {{pointTable.pointName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!--IF Heat race-->
          <div fxLayoutGap="1rem" fxFlex style="background-color:white; padding-top:1rem;"
               *ngIf="displayEventCase(selectedEvent.eventTypeId) == 'Heat'">
            <mat-card-header>
              <mat-card-title>Definiera klass info:</mat-card-title>
            </mat-card-header>

            <div fxLayout="column" *ngFor="let availableClass of availableClasses; let i = index">
              <span style="font-size:1rem">{{availableClass.className}}:</span>
              <div fxLayout="row" fxLayoutGap="1rem">
                <mat-form-field fxFlex="5rem" appearance="fill">
                  <mat-label>Antal Varv/Heat:</mat-label>
                  <input matInput type="number" [(ngModel)]="availableClasses[i].nbrLaps">
                </mat-form-field>
                <mat-form-field fxFlex="5rem" appearance="fill">
                  <mat-label>Antal Heat:</mat-label>
                  <input matInput [(ngModel)]="availableClasses[i].lapsInHeat" type="number" />
                </mat-form-field>
                <mat-form-field fxFlex="8rem" appearance="fill">
                  <mat-label>Heat poäng:</mat-label>
                  <mat-select [(value)]="availableClasses[i].heatPointId">
                    <mat-option *ngFor="let pointTable of heatPointTables" [value]="pointTable.pointId">
                      {{pointTable.pointName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="8rem" appearance="fill">
                  <mat-label>Poäng tabell:</mat-label>
                  <mat-select [(value)]="availableClasses[i].pointId">
                    <mat-option *ngFor="let pointTable of pointTables" [value]="pointTable.pointId">
                      {{pointTable.pointName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex></div>
            </div>
          </div>
          <!--IF SSP race-->
          <div fxLayoutGap="1rem" style="background-color:white; padding-top:1rem;"
               *ngIf="displayEventCase(selectedEvent.eventTypeId) == 'Sträckor'">
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1rem" style="background-color:white;margin:0;padding:0;">
            <mat-form-field fxFlex="8rem" appearance="fill" style="background-color:white">
              <mat-label>Lägg till i serie</mat-label>
              <mat-select [(ngModel)]="selectedSerie">
                <mat-option *ngFor="let serie of series" [value]="serie">
                  {{serie.serieName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="8rem" appearance="fill" style="    background-color: white">
              <mat-label>Start typ</mat-label>
              <mat-select [(ngModel)]="selectedStartType">
                <mat-option *ngFor="let startType of startTypes" [value]="startType">
                  {{startType.startTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="selectedStartType">
              <mat-checkbox *ngIf="selectedStartType.startTypeName.includes('knapp')" [(ngModel)]="selectedEvent.ignoreFirstStartPost">Ignorera första start kontroll</mat-checkbox>
            </div>
            
          </div>

          <div fxLayout="row-wrap" fxLayout.xs="column" fxLayoutGap="1rem" style="background-color:white; margin-bottom:1rem">
            <button class="mw-btn" type="button" (click)="AddParametersToDB()" mat-raised-button color="primary">
              <span>Spara till DB</span>
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>

 </div>
