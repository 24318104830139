import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { IEvent } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { AddEventComponent } from './add-event/add-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';


@Component({
  selector: 'app-db-events',
  templateUrl: './db-events.component.html',
  styleUrls: ['./db-events.component.css']
})
export class DbEventsComponent implements OnInit {

  public events: IEvent[] = [];
  public broadCastEvents: any;

  constructor(private dbService: DbService,
    public activeUserService: ActiveuserService,
    private dialog: MatDialog,
    public dataRoom: DataRoomService,) { }

  ngOnInit(): void {
    this.broadCastEvents = this.dbService.broadCastEvents.subscribe(
      data => {
        this.events = data
      })
    
  }

  ngOnDestroy(): void {
    this.broadCastEvents.unsubscribe();
  }

  onAddEvent() {
    var editEvent = null;
    const dialogRef = this.dialog.open(AddEventComponent, {
      
      position: { top: '1rem' },
      width: 'auto',
      maxHeight:'60%',
      panelClass: 'custom-dialog-container',
      data: { editEvent },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onEditEvent(editEvent: IEvent) {
    
    const dialogRef = this.dialog.open(AddEventComponent, {
      panelClass: 'custom-dialog-container',
      data: { editEvent },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onDeleteEvent(events: IEvent) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: events.eventName,
        message: 'Är du säker på att du vill ta bort detta event från datbasen?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.removeEvent(events);
      }
    })
  }


}
