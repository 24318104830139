import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-by-member',
  templateUrl: './by-member.component.html',
  styleUrls: ['./by-member.component.css']
})
export class ByMemberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

//TODO | All show training by member
