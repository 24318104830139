import { Component, OnInit } from '@angular/core';
import { IClub, IEvent, IMember } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';

export interface LReaderStatus {
  readerName: string,
  readerStatus: number,
};

@Component({
  selector: 'app-run-training',
  templateUrl: './run-training.component.html',
  styleUrls: ['./run-training.component.css']
})
export class RunTrainingComponent implements OnInit {

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private signalR: SignalRService,
    private activeUserService: ActiveuserService,) { }

  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClub: IClub;

  checked = false;

  //public events: IEvent[] = [];
  public allEvents: IEvent[] = [];
  private broadCastallEvents: any;
  public selectedEvent: IEvent;

  public activePis: any[];
  private broadCastPisDB: any;
  private broadCastPisSignalR: any;
  public currentUser: IMember;
  private broadCastActiveUser: any;



  public readerStatus: LReaderStatus[] = [];

  public colors = [{ hasStarted: 0, color: "red" }, { hasStarted: 1, color: "green" },
  { hasStarted: 2, color: "yellow" }, { hasStarted: 3, color: "black" }]

  ngOnInit(): void {

    //DB SERVICES//
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })

    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      this.currentUser = data
      this.selectedClub = this.currentUser.clubModel
      this.dbService.getEventsByClub(data.clubModel.clubModelId);
    })

    this.broadCastallEvents = this.dbService.broadCastEvents.subscribe(data => {
      if (this.selectedClub)
        this.allEvents = data.filter(event => event.clubModelId && event.eventType.type=='Träning')
    })
    this.broadCastPisDB = this.dbService.broadCastPis.subscribe(data => {
      this.activePis = data;
      console.log(data)
    })

    //SIGNALR SERVICES
    this.broadCastPisSignalR = this.signalR.broadCastPis.subscribe(data => {
      this.activePis = data;
      //this.signalR.broadCastEvents.subscribe(data => {
      //  if (this.selectedClub)
      //    this.allEvents = data.filter(event => event.clubModelId == this.selectedClub.clubModelId);
      //})
    })

    this.dbService.getActivePis();
   // this.dbService.getClubs();

    //TO DO REVIEWE
    this.signalR.getEventList();
    this.signalR.changedPiConnectionList();
  }

  ngOnDestroy(): void {
    this.broadCastPisSignalR.unsubscribe();
    this.broadCastPisDB.unsubscribe();
    this.broadCastallEvents.unsubscribe();
    this.broadCastActiveUser.unsubscribe();
    this.broadCastClubs.unsubscribe();
  }


  onClubChange(event) {

    this.selectedClub = event.value;
    this.dbService.getActivePis();
    this.dbService.getEventsByClub(this.selectedClub.clubModelId);
  }
  getTheColor(status) {
    return this.colors.filter(item => item.hasStarted === status)[0].color
  }

  //Coloring of reader status (
  // RED =>, PI not connected;
  // YELLOW => PI connected but raeder not listening,
  // GREEN => PI connected and reader is listening )

  //To Do Fix loading of this!
  getTheColor2(readerName) {
    //Check if reader exists if so check if it's active
    var status = 0

    if (this.activePis) {
      this.activePis.forEach(Pi => {
        Pi.availableReaders.forEach(reader => {
          if (reader.readerName == readerName) {
            if (!reader.isConnected)
              status = 3;
            else if (reader.isListening)
              status = 2;
            else
              status = 1;          
          }
        })
      })
    }
    else
      return 'pink'
    if (status == 0)
      return 'red';
    if (status == 1)
      return 'yellow';
    if (status == 2)
      return 'green';
    if (status == 3)
      return 'orange'
    else
      return 'pink';
  }

  onExpandRow(event) {

  }

  onStartEvent(event) {
    this.dbService.startEvent(event);
  }
  onPauseEvent(event) {
    this.dbService.pauseEvent(event);
  }
  onStopEvent(event) {
    this.dbService.stopEvent(event);
  }

  events(): IEvent[] {
    if (this.checked)
      return this.allEvents;
    else
      return this.allEvents.filter(data => data.hasStarted < 3)
  }

  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.name === o2.name : o2 === o2;
  }
}

