<div class="mw_div" style="background-color:white">
  <!--scrollHeight="500px"  REMOVED FROM BELOW-->
  <!--<button style="height:2rem; width:4rem" (click)="bla()" fxLayoutAlign="start start">BLA</button>-->
  <p-table #dt [value]="membersInEvent" dataKey="svemoId"
           [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
           sortMode="multiple"
           [scrollable]="true" [columns]="headers"
           [responsive]="true"
           [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
           styleClass="p-datatable-members">

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns">
      </colgroup>
    </ng-template>


    <ng-template pTemplate="caption">
      <div fxLayout="row wrap" fxLayoutGap="1rem">
        <div fxLayoutAlign="start center" fxFlex.gt-xs="30" fxFlex.lt-sm="35">
          <div class="name-header-sm">
            Träning Live!
          </div>
          <!--<button mat-button color="Accent" mat-raised-button (click)="writeConsole()">SignalR</button>-->
        </div>

        <div fxLayoutAlign="start center" fxFlex fxLayoutGap="1rem">
          <mat-form-field>
            <mat-label>Välj träning:</mat-label>
            <mat-select [(value)]="selectedEvent" (selectionChange)="onSelectedEvent($event)">
              <mat-option *ngFor="let event of eventsInDB" [value]="event">
                {{event.eventDate}} - {{event.eventName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-headers let-columns>
      <tr>
        <th fxFlex="3rem"></th>
        <th fxFlex="15rem" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
        <th fxFlex="2rem"></th>
        <!--<th fxFlex="5rem" *ngFor="let header of headers">
          <span fxLayoutAlign="center center">{{header.title}} </span>
        </th>-->
        <th fxFlex="5rem" *ngFor="let col of columns">
          <span fxLayoutAlign="center center">{{col.header}}</span>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-member let-expanded="expanded">
      <div style="margin-bottom:0.25rem">
        <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:3rem">
          <td fxFlex="3rem" style="padding: 0.25rem; margin:0px; ">
            <button style="height:2rem; width:2rem" type="button"
                    pButton pRipple [pRowToggler]="member" (click)="onExpandMember(member)"
                    class="p-button-text p-button-rounded p-button-plain p-button-sm"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td fxFlex="15rem" style="padding: 0.5rem; margin:0px;" fxLayoutAlign="start center">
            <img class="img" width="50" height="40" [src]='dataRoom.showRacerPicture(member.svemoId)' style="border-radius:20%; margin-right:10px" />
            <span>{{member.firstName + ' ' + member.lastName}}</span>
          </td>
          <td fxFlex="2rem" style="padding: 0.5rem; margin:0px;">
            <mat-icon *ngIf="member.confirmedTimeReadModel" fxLayoutAlign="center center" style="font-size:2rem"
                      [ngStyle]="{'color':getStatusColor(member.confirmedTimeReadModel)}">two_wheeler</mat-icon>
          </td>
          <td *ngFor="let time of member.lapTimeModel" fxFlex="5rem" style="padding: 0.5rem; margin:0px;">
            <span fxLayoutAlign="center center" style="font-size:0.7rem; font-style:italic">{{displayLapName(time.lapID)}}</span>
            <span fxLayoutAlign="center center"> {{convertToMiniTime(time.lapTime)}} </span>
          </td>
          <td fxFlex>
          </td>
        </tr>
      </div>
    </ng-template>

    <!--EXPANSION-->
    <!--<ng-template pTemplate="rowexpansion" let-member>
      {{member.firstName}}-->
      <!--<div fxLayout="row-wrap" fxLayout.xs="column" fxLayoutAlign="space-between start">
        <mat-card fxFlex="60" class="MatCard-mw">
          <mat-card-title fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
            <h6 style="margin:0px; padding:0px">Mellantider</h6>

          </mat-card-title>
          <mat-card-content>
            <p-table [value]="member.intermediateTimeModel"
                     [scrollable]="true" scrollHeight="300px"
                     responsive="false">

              <ng-template pTemplate="header">
                <tr *ngIf="userRole == 'Admin'">
                  <th fxFlex="40" pSortableColumn="intermediateTime.lapID">Varv <p-sortIcon field="intermediateTime.lapID"></p-sortIcon></th>
                  <th fxFlex="30" pSortableColumn="intermediateTime.lapTime">Tid <p-sortIcon field="intermediateTime.lapTime"></p-sortIcon></th>
                  <th fxFlex="15" pSortableColumn="intermediateTime.id1">Start <p-sortIcon field="intermediateTime.id1"></p-sortIcon></th>
                  <th fxFlex="15" pSortableColumn="intermediateTime.id2">Slut<p-sortIcon field="intermediateTime.id2"></p-sortIcon></th>
                </tr>
                <tr *ngIf="userRole != 'Admin'">
                  <th fxFlex="50" pSortableColumn="intermediateTime.lapID">Varv <p-sortIcon field="intermediateTime.lapID"></p-sortIcon></th>
                  <th fxFlex="50" pSortableColumn="intermediateTime.lapTime">Tid <p-sortIcon field="intermediateTime.lapTime"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-intermediateTime>
                <tr *ngIf="userRole == 'Admin'">
                  <td fxFlex="40">{{getLapName(intermediateTime.lapID)}}</td>
                  <td fxFlex="30">{{intermediateTime.lapTime.split(".").slice(0,1)}}</td>
                  <td fxFlex="15">{{intermediateTime.id1}}</td>
                  <td fxFlex="15">{{intermediateTime.id2}}</td>
                </tr>
                <tr *ngIf="userRole != 'Admin'">
                  <td fxFlex="50">{{intermediateTime.lapID}}</td>
                  <td fxFlex="50">{{intermediateTime.lapTime.split(".").slice(0,1)}}</td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="userRole == 'Admin'" fxFlex class="MatCard-mw">
          <mat-card-title fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
            <h6 style="margin:0px; padding:0px">Tidstaggar</h6>

          </mat-card-title>
          <mat-card-content>
            <p-table [value]="member.confirmedTimeReadModel"
                     [scrollable]="true" scrollHeight="300px">

              <ng-template pTemplate="header">
                <tr>

                  <th fxFlex="20" pSortableColumn="confirmedTimeReadModel.timeReadID">Read ID <p-sortIcon field="confirmedTimeReadModel.timeReadID"></p-sortIcon></th>
                  <th fxFlex="20" pSortableColumn="confirmedTimeReadModel.readerId">Läsare <p-sortIcon field="confirmedTimeReadModel.readerId"></p-sortIcon></th>
                  <th fxFlex="20" pSortableColumn="confirmedTimeReadModel.antennaId">Antenn <p-sortIcon field="confirmedTimeReadModel.antennaId"></p-sortIcon></th>
                  <th fxFlex="20" pSortableColumn="confirmedTimeReadModel.status">Status <p-sortIcon field="confirmedTimeReadModel.status"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-confirmedTimeReadModel>
                <tr>
                  <td fxFlex="20">{{confirmedTimeReadModel.timeReadID}}</td>
                  <td fxFlex="20">{{confirmedTimeReadModel.readerId}}</td>
                  <td fxFlex="20">{{confirmedTimeReadModel.antennaId}}</td>
                  <td fxFlex="20">{{confirmedTimeReadModel.status}}</td>
                </tr>
              </ng-template>
            </p-table>
          </mat-card-content>
        </mat-card>

      </div>-->
    <!--</ng-template>-->

  </p-table>

</div>
