<div class="div_table">
  <p-table #dt [value]="dbService.allMembers"
           [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
           sortMode="multiple"
           [scrollable]="true" scrollHeight="400px"
           [responsive]="true"
           [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
           styleClass="p-datatable-members p-datatable-striped">

    <!--CAPTION-->
    <ng-template pTemplate="caption">
      <!--LARGE SCREEN-->
      <div fxLayout="row wrap" fxLayoutGap="1rem">
        <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
          <div class="name-header-sm">
            Medlemmar
          </div>
        </div>
        <div fxLayoutAlign="end center" fxFlex style="height:3rem">
          <button class="mw-addBtn-icon" (click)="fileInput.click()" fxLayoutAlign="start start" matTooltip="Öppna Svemo startlista">
            <mat-icon class="mw-addIcon-icon">library_add</mat-icon>
            <input #fileInput type="file" accept=".xls" (change)="onFileChange($event)" style="display:none;" />
          </button>
        </div>
        <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
          <button class="mw-addBtn-icon"
                  (click)="onAddMember()" fxLayoutAlign="start start"
                  matTooltip="Lägg till medlem">
            <mat-icon class="mw-addIcon-icon">add</mat-icon>
          </button>
        </div>


        <!--SMALL SCREEN-->
        <div fxLayoutAlign="flex-start" fxLayoutAlign.xs="flex-center" fxFlex fxLayoutGap="5px" fxHide.gt-sm>
          <mat-form-field>
            <mat-label>Välj klubb:</mat-label>
            <mat-select [(ngModel)]="testClubs" multiple (selectionChange)="onClubChange($event)">
              <mat-select-trigger>
                <!--{{testClubs ? testClubs[0].name : ''}}-->
                <span *ngIf="testClubs.length > 0">
                  {{testClubs[0].name}}
                </span>
                <span *ngIf="testClubs.length > 1" class="additional-selection">
                  (+{{testClubs.length - 1}} {{testClubs.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let club of dbService.allClubs" [value]="club">{{club.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
     

    <!--HEADER-->
    <ng-template pTemplate="header">
      <!--Only on large screen-->
      <tr fxHide fxShow.gt-sm fxFlexLayout="row" style="height:4rem">
        <th fxFlex="6rem">
          <mat-form-field style="width:5rem">
            <input matInput placeholder="Svemo ID:"
                   matTooltip="Sök på Svemo ID"
                   (input)="filterTableSvemoId($event)">
          </mat-form-field>
        </th>
        <th fxFlex="15rem">
          <mat-form-field class="full-width">
            <input matInput placeholder="Sök på förnamn"
                   matTooltip="Sök på förnamnet"
                   (input)="filterTableFirstName($event)">
          </mat-form-field>
        </th>
        <th>
          <mat-form-field>
            <mat-label>Välj klubb:</mat-label>
            <mat-select [(ngModel)]="testClubs" multiple
                        matTooltip="Välj den klubb som har REGISTRERAT föraren"
                        (selectionChange)="onClubChange($event)">
              <mat-select-trigger>
                <!--{{testClubs ? testClubs[0].name : ''}}-->
                <span *ngIf="testClubs.length > 0">
                  {{testClubs[0].name}}
                </span>
                <span *ngIf="testClubs.length > 1" class="additional-selection">
                  (+{{testClubs.length - 1}} {{testClubs.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let club of dbService.allClubs" [value]="club">{{club.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <th fxFlex></th>

      </tr>
      <tr fxFlexLayout="row">
        <th fxFlex="6rem" matTooltip="Sortera på Svemo ID" pSortableColumn="svemoId">ID <p-sortIcon field="svemoId"></p-sortIcon></th>
        <th fxFlex="15rem" matTooltip="Sortera på förnamn" pSortableColumn="firstName"> Namn <p-sortIcon field="firstName"></p-sortIcon></th>
        <th fxFlex="10rem" matTooltip="Sortera på klubbtillhörighet" pSortableColumn="clubMembership"> Klubb <p-sortIcon field="clubMembership"></p-sortIcon></th>
        <th fxFlex="5rem" fxLayoutAlign="center center" > Aktiv </th>
        <th fxFlex="6rem" matTooltip="Sortera på motorcyckel" pSortableColumn="bikeBrandModelId"> MC <p-sortIcon field="bikeBrandModelId"></p-sortIcon></th>
        <th fxFlex>  </th>
      </tr>
    </ng-template>

    <!--BODY-->
    <ng-template pTemplate="body" let-member>

      <!--LARGE SCREEN-->
      <tr fxHide.lt-sm fxFlexLayout="row" style="height:3rem">
        <td fxFlex="6rem" fxLayoutAlign="start center">
          <span class="p-column-title"> Svemo ID </span>
          {{member.svemoId}}
        </td>
        <td fxFlex="15rem" fxLayout fxLayoutAlign="start center">
          <img matTooltip="Gå till profil" class="mw_img" width="50" height="40"
               [src]='dataRoom.showImage(member.picture)' style="border-radius:20%; margin-right:10px"
               (click)="onViewMember(member)" />
          <span class="image-text">{{member.firstName}} {{member.lastName}}</span>
        </td>
        <td fxFlex="10rem" fxLayoutAlign="start center">
          <span class="p-column-title"> Klubb: </span>
          <!--{{dataRoom.showClubName(member.clubModelId)}}-->
          {{member.clubMembership}}
        </td>
        <td fxFlex="5rem" fxLayoutAlign="center center">
          <mat-checkbox [checked]="member.isActive" [disabled]="true"></mat-checkbox>
        </td>
        <td fxFlex="6rem" fxLayoutAlign="start center">
          <span class="p-column-title"> Motorcykel: </span>
          <img class="img" width="40" height="40" [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)' style="border-radius:20%; margin-right:10px" />
        </td> 
        <td fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">

          <button *ngIf="(activeUserService.activeUserRole() == 'Admin' && activeUserService.user.clubModelId == member.clubModelId) ||
                  activeUserService.activeUserRole() == 'SAdmin'" class="mw-editBtn-icon"
                  (click)="onViewMember(member)" matTooltip="Redigera medlem">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="(activeUserService.activeUserRole() == 'Admin' && activeUserService.user.clubModelId == member.clubModelId) ||
                  activeUserService.activeUserRole() == 'SAdmin'" class="mw-deleteBtn-icon"
                  (click)="onMemberDelete(member)" matTooltip="Ta bort medlem">
            <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
          </button>

        </td>
      </tr>

      <!--SMALL SCREEN-->
      <tr fxHide.gt-xs>
        <td fxLayout fxLayoutAlign="center center" fxLayoutGap="1rem" style="background-color:lightgray; height:4rem ">
          <img class="img" width="50" height="50" [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)'
               style="border-radius:10%; margin-right:10px"/>
          <span class="name-header-sm"> {{member.firstName}} {{member.lastName}}</span>
          <img class="mw_img" matTooltip="Gå till profil" width="60" height="50"
               [src]='dataRoom.showImage(member.picture)'
               style="border-radius:10%; margin-right:10px"
               (click)="onViewMember(member)"/>
          <!--<button class="mr-2 PrimaryBtn" color="primary" mat-raised-button (click)="onViewMember(member)">
            <i class="pi pi-user"></i><span style="margin-left:10px">Profil</span>
          </button>-->

        </td>
        <td>
          <span class="p-column-title"> Svemo ID: </span>
          {{member.svemoId}}
        </td>
        <td>
          <span class="p-column-title"> Klubb: </span>
          <!--{{dataRoom.showClubName(member.clubModelId)}}-->
          {{member.clubMembership}}
        </td>
        <td>
          <span class="p-column-title"> Motorcykel: </span>
          {{dataRoom.showBikeBrand(member.bikeBrandModelId)}}
        </td>
        <td>
          <div fxFlex fxLayoutAlign="center center" fxLayoutGap="2rem">
            <button *ngIf="(activeUserService.activeUserRole() == 'Admin' && activeUserService.user.clubModelId == member.clubModelId) ||
                  activeUserService.activeUserRole() == 'SAdmin'" class="mw-editBtn-icon"
                  (click)="onViewMember(member)" matTooltip="Redigera medlem">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="(activeUserService.activeUserRole() == 'Admin' && activeUserService.user.clubModelId == member.clubModelId) ||
                  activeUserService.activeUserRole() == 'SAdmin'" class="mw-deleteBtn-icon" (click)="onMemberDelete(member)" matTooltip="Ta bort medlem">
              <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
            </button>
          </div>
        </td>
      </tr>

    </ng-template>

  </p-table>
</div>
